import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import apiPaths from '../apiPaths';

const EscuelaDashboard = (props) => {
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'escuelaescuelaSearch',
        panelHeader: 'Escola',
        panelKey: 'escuelaescuelaSearch',
        yPosition: 0,
        xPosition: 0,
        width: 24,
        collapsible: true,
        collapsed: false,
        isTabItem: false,
        tabIndex: 0,
        tabTitle: '',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'nombre',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Nom',
          key: 'nombre',
          type: 'text',
          operand: 'like',
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'escuelaescuelaList',
        rowKey: 'id',
        panelHeader: 'Escola',
        panelKey: 'escuelaescuelaList',
        navigationParams: props.query.params.q || '',
        yPosition: 1,
        xPosition: 0,
        width: 24,
        collapsible: true,
        collapsed: false,
        isTabItem: false,
        tabTitle: '',
        isMainTable: true,
        tabIndex: 0,
        tableType: 0,
      },
      fields: [
        {
          title: 'Id',
          dataIndex: 'id',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: 60,
        },
        {
          title: 'Nom',
          dataIndex: 'nombre',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'escuelaescuelaEdit',
        panelHeader: 'Escola',
        panelKey: 'escuelaescuelaEdit',
        primaryKey: 'id',
        yPosition: 2,
        xPosition: 0,
        width: 24,
        collapsible: true,
        collapsed: false,
        isTabItem: true,
        tabIndex: 0,
        tabTitle: 'Escola',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'nombre',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 18,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Nom',
          key: 'nombre',
          type: 'text',
          unique: false,
          validationType: 'text',
          mandatory: true,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'EscuelaDashboard';

  return (
    <DashboardInitializer
      componentId={componentId}
      components={components}
      {...props}
    />
  );
};

export default EscuelaDashboard;
