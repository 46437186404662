import React from 'react';
import { Form, Tooltip, Icon, Col, Menu, Dropdown, Button } from 'antd';
import './FormStyles.css';
import moment from 'moment';
import BehaviourField from '../fields/BehaviourField';
import Fields from '../fields/Fields';
import advancedSearch from '../fields/advancedSearch';
import config from '../config';

const FormItem = Form.Item;

/**
 * This functions build a Dropdown, which shows a menu that will allow the user to switch
 * between multiple search options (>,<,>=, <=, ...)
 * This function will be launched if operand != 'like'
 * @param {Object} field to be modified
 * @param {Object} options style options
 * @return Dropdown, Menu & corresponding Menu.Items
 */
const operandSelector = (field, parentProps) => {
  const { props } = parentProps;
  const { intl, options } = props;
  const queryOptions = [
    {
      key: 4,
      description: intl.formatMessage({ id: 'search.operator.equal' }),
      operand: 'equal',
      icon: 'minus'
    },
    {
      key: 0,
      description: intl.formatMessage({ id: 'search.operator.greater' }),
      operand: 'greater',
      icon: 'right'
    },
    {
      key: 1,
      description: intl.formatMessage({ id: 'search.operator.lower' }),
      operand: 'less',
      icon: 'left'
    },
    {
      key: 2,
      description: intl.formatMessage({ id: 'search.operator.greaterequal' }),
      operand: 'greater|equal',
      icon: 'caret-right'
    },
    {
      key: 3,
      description: intl.formatMessage({ id: 'search.operator.lowerequal' }),
      operand: 'less|equal',
      icon: 'caret-left'
    }
  ];

  const menuItems = [];
  queryOptions.forEach(option => {
    menuItems.push(
      <Menu.Item
        key={option.key}
        onClick={() => switchOperatorHandle(option.operand, field, parentProps)}
      >
        <Icon type={option.icon} />
        {option.description}
      </Menu.Item>
    );
  });

  const menu = <Menu>{menuItems}</Menu>;
  if (field.operand !== 'like') {
    return (
      <Dropdown overlay={menu} className="dropdownAlign">
        <Button style={{ marginLeft: 8 }} size={options.fieldSize}>
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  }
};

/**
 * This function takes the new operand and sets the new Field in Redux state
 * This will allow to make different queries (greater/lower than ...)
 * @param {String} op New operand
 * @param {Object} field which will change the operand
 */
const switchOperatorHandle = (op, selectedField, parentProps) => {
  const { props } = parentProps;
  const { fields } = props;

  fields.forEach(field => {
    if (field.key === selectedField.key) {
      field.operand = op;
    }
  });
};

export default function fieldRender(field, parentProps) {
  const { props } = parentProps;
  const { form, intl, values, combos, params } = props;
  const { getFieldDecorator } = form;
  const { componentId, type } = params;

  const timeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS+0000';
  let value = values[field.key];

  if (
    (values.hasOwnProperty(field.key) || values[field.key] === 0) &&
    value !== null
  ) {
    switch (field.type) {
      case 'date':
        value = moment(values[field.key], field.format);
        break;
      case 'combo':
      case 'advancedSearch':
        if (
          combos[componentId] &&
          combos[componentId][field.key] &&
          combos[componentId][field.key][field.comboId] &&
          combos[componentId][field.key][field.comboId].hasOwnProperty(
            'isLoading'
          ) &&
          !combos[componentId][field.key][field.comboId].isLoading
        )
          value = values[field.key].toString();
        else value = 'Cargando...';
        break;
      case 'comboCustom':
      case 'radio':
        value = values[field.key].toString();
        break;
      case 'time':
        value = moment(values[field.key], timeFormat).utc();
        value.add(1, 'hours');
        break;
      case 'currency':
      case 'percent':
        value = parseFloat(value).toFixed(2);
        break;
      case 'checkSelect':
      case 'check':
        if (values[field.key] !== undefined) {
          value = value.toString();
        }
        break;
      case 'rangerpicker':
        value = value.split('.');
        const newValue = [];
        value.forEach(v => {
          newValue.push(moment(new Date(v)).utc());
        });
        value = newValue;
        break;
      default:
        break;
    }
  }

  if (
    (type === 'edit' ||
      field.operand === 'like' ||
      field.operand === undefined) &&
    field.type !== 'advancedSearch'
  )
    return (
      <Col lg={field.width} md={24} key={field.key}>
        <FormItem
          label={
            field.type !== 'check' &&
            (field.tooltip ? (
              <span>
                {field.hasOwnProperty('parentFieldValue') &&
                values[field.parentFieldValue]
                  ? values[field.parentFieldValue]
                  : field.title}
                <Tooltip className="fieldTooltip" title={field.tooltip}>
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            ) : field.hasOwnProperty('parentFieldValue') &&
              values[field.parentFieldValue] ? (
              values[field.parentFieldValue]
            ) : (
              field.title
            ))
          }
          colon={false}
          extra={field.extra}
        >
          {getFieldDecorator(field.key, {
            initialValue: value,
            rules: [
              {
                required: field.type !== 'checkSelect' && field.mandatory,
                message: intl.formatMessage({ id: 'update.required' })
              }
            ]
          })(
            field.behaviours !== undefined
              ? BehaviourField({
                  parentProps,
                  field,
                  hidden: false,
                  setData:
                    type === 'edit'
                      ? parentProps.props.setEditData
                      : parentProps.props.setSearchValues
                })
              : Fields({ ...parentProps, field })
          )}
        </FormItem>
      </Col>
    );
  else
    return (
      <div key={field.key.concat('addon')}>
        <Col
          md={field.width - config.COMPONENT.FORM.ADVANCED_SEARCH_SIZE}
          key={field.key}
          className="dropdownItem"
        >
          <Form.Item
            label={
              field.type !== 'check' &&
              (field.tooltip ? (
                <span>
                  {field.title}
                  <Tooltip className="fieldTooltip" title={field.tooltip}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              ) : (
                field.title
              ))
            }
            colon={false}
            extra={field.extra}
          >
            {getFieldDecorator(field.key, {
              initialValue: value,
              rules: [
                {
                  required: field.type !== 'checkSelect' && field.mandatory,
                  message: intl.formatMessage({ id: 'update.required' })
                }
              ]
            })(Fields({ field, ...parentProps }))}
          </Form.Item>
        </Col>
        <Col
          md={config.COMPONENT.FORM.ADVANCED_SEARCH_SIZE}
          key={'addon'.concat(field.key)}
          className="dropdownCustom"
        >
          {getFieldDecorator('search'.concat(field.key))(
            field.type === 'advancedSearch'
              ? advancedSearch({ parentProps, field })
              : operandSelector(field, parentProps)
          )}
        </Col>
      </div>
    );
}
