import React from 'react';
import TableRender from './TableRender';
import customDashboards from '../extensions/dashboards/customDashboards';

export default function SmartTableRender(parentProps) {
  const componentProps =
    parentProps.props.customDashboard &&
    customDashboards[parentProps.props.customDashboard] &&
    customDashboards[parentProps.props.customDashboard].table
      ? customDashboards[parentProps.props.customDashboard].table(parentProps)
      : { ...parentProps };

  return <TableRender {...componentProps} />;
}
