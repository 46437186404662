import React from 'react';
import { Button, Switch, Row, Popconfirm, Icon, Modal } from 'antd';
import { isEmpty } from 'lodash';
import * as api from '../api';

import DataExport from '../export/DataExport';
import M2MEditButton from '../m2m/M2MEditButton';
import components from '../components.js';
import SmartColumns from './SmartColumns';
import config from '../config';
import { checkBehaviour } from '../forms/behaviourUtils';
import * as customButtonsFile from '../extensions/buttons/customButtons';
import './SmartTable.css';

export default function SmartTableButtons({
  parentProps,
  dataSource,
  columns,
  loading,
}) {
  const {
    props,
    handleReloadData,
    handleChangeDrawerVisibility,
    handleSetM2MSwitch,
    handleDeleteData,
    onChangeColumnVisibility,
  } = parentProps;
  const {
    params,
    settings,
    combos,
    selectedRowKeys,
    selectedRow,
    intl,
    fields,
    queryParams,
    layoutType,
    history,
    isAdvancedSearch,
    isModalDashboard,
    userPermissions,
    values,
    isM2M,
    parentSelectedRow,
    setSelectedTab,
    dashboardId,
  } = props;
  const {
    rowKey,
    componentId,
    isMainTable,
    customButtons,
    navigationId,
  } = params;
  const { multiple, canDelete } = settings;
  let rowSelection = {
    selectedRowKeys: selectedRowKeys || [],
    onChange: parentProps.onSelectMultipleRows,
    onSelectAll: parentProps.onSelectAllRows,
    onSelect: parentProps.onSelectMultipleRows2,
  };
  const dashboardComponent = parentProps.props.components;
  const checkButtonPermissions = (button, userPermissions) => {
    let disabled;
    userPermissions.forEach((permission) => {
      button.permissions.forEach((perm) => {
        if (permission === perm.name) {
          disabled = perm.disabled;
        }
      });
    });
    return disabled;
  };

  const checkButtonBehaviours = (button, values) => {
    let disabled;
    button.behaviours.forEach((behaviour) => {
      if (checkBehaviour(behaviour, values)) disabled = !button.defaultDisabled;
    });
    if (disabled === undefined) return button.defaultDisabled;
    else return disabled;
  };

  const checkDisabledButton = (button, values, userPermissions) => {
    let disabled;

    if (typeof button === 'object') {
      button.permissions
        ? (disabled = checkButtonPermissions(button, userPermissions))
        : (disabled = checkButtonBehaviours(button, values));
      if (disabled === undefined) disabled = button.defaultDisabled;
    } else disabled = !button;

    return disabled;
  };
  
  const NavigationButton = (parentProps) => {
    const { props, handleChangeDrawerVisibility } = parentProps;
    const {
      params,
      queryParams,
      parentSelectedRow,
      currentPath,
      parentRowKey,
      parentFormHasChanged,
      navigate,
      intl,
    } = props;

    const {
      navigationRoute,
      navigationId,
      navigationExtensions,
      returnParams,
      parentRowBehaviour,
    } = params;
    let path = '';
    let returnExtension = '';
    if (queryParams.hasOwnProperty('q')) {
      const id = queryParams.q.substring(
        queryParams.q.lastIndexOf(':') + 1,
        queryParams.q.length
      );
      path = `/${navigationRoute}?q=${navigationId}:${id}`;

      if (navigationExtensions) {
        navigationExtensions.forEach((element) => {
          if (element.hasOwnProperty('value'))
            path = path + `${config.QUERY.AND}${element.key}:${element.value}`;
          else {
            path =
              path +
              `${config.QUERY.AND}${element.key}:${
                parentSelectedRow[element.key]
              }`;
            if (returnParams)
              returnExtension =
                returnExtension +
                `${config.QUERY.AND}${element.key}:${
                  parentSelectedRow[element.key]
                }`;
          }
        });
      }
    }

    let disabled;

    if (parentRowBehaviour) {
      disabled = checkButtonBehaviours(parentRowBehaviour, parentSelectedRow);
    }

    return (
      <Button
        icon="edit"
        type="primary"
        className="single-btn"
        onClick={() =>
          confirmNavigation({
            intl,
            path,
            gobackPath: `${currentPath}?q=${parentRowKey}:${parentSelectedRow[parentRowKey]}`,
            parentFormHasChanged,
            navigate,
            handleChangeDrawerVisibility,
            returnExtension,
          })
        }
        disabled={disabled || isEmpty(parentSelectedRow)}
      >
        Editar registros
      </Button>
    );
  };

  let deleteDisable = checkDisabledButton(canDelete, values, userPermissions);

  return (
    <Row>
      <Button.Group className="smartTable__upperButtons">
        <Button
          icon="reload"
          onClick={handleReloadData}
          disabled={loading}
          title={intl.formatMessage({ id: 'form.update' })}
          // loading={loading}
        />
        {config.FEEDBACK.CONFIRM.DELETE_RECORD ? (
          <Popconfirm
            disabled={isEmpty(selectedRow) || deleteDisable}
            title={intl.formatMessage({ id: 'pop.title.delete' })}
            icon={<Icon type="exclamation-circle" style={{ color: 'red' }} />}
            onConfirm={handleDeleteData}
            okText={intl.formatMessage({ id: 'pop.accept' })}
            cancelText={intl.formatMessage({ id: 'pop.cancel' })}
          >
            <Button
              type="danger"
              icon="delete"
              disabled={isEmpty(selectedRow) || deleteDisable}
              title={intl.formatMessage({ id: 'form.delete' })}
            />
          </Popconfirm>
        ) : (
          <Button
            type="danger"
            icon="delete"
            disabled={isEmpty(selectedRow) || deleteDisable}
            title={intl.formatMessage({ id: 'form.delete' })}
            onClick={handleDeleteData}
          />
        )}
        {/* {canDelete && (
          <Button
            type="primary"
            icon="delete"
            disabled={isEmpty(dataSource.content) || isEmpty(selectedRowKeys)}
            title={intl.formatMessage({ id: 'form.delete' })}
            onClick={parentProps.showDeleteConfirm}
            // loading={loading}
          />
        )} */}
      </Button.Group>
      {isAdvancedSearch && <AdvancedSearchButton {...parentProps.props} />}
      {(layoutType === 0 || layoutType === 1) && isMainTable !== false && (
        <Button
          type="primary"
          icon="edit"
          className="single-btn"
          disabled={isEmpty(selectedRow)}
          title="Editar registro"
          onClick={() =>
            setSelectedTab({
              dashboardId,
              tabIndex: '0',
              componentId: '',
            }) && handleChangeDrawerVisibility(true)
          }
          // loading={loading}
        >
          Editar
        </Button>
      )}
      {customButtons &&
        customButtons.map((customButton) => (
          <span key={customButton} style={{ marginLeft: 12 }}>
            {customButtonsFile[customButton](parentProps)}
          </span>
        ))}
      {params.hasOwnProperty('navigationRoute') && !isAdvancedSearch && (
        <NavigationButton {...parentProps} />
      )}
      {!isModalDashboard && <ReturnButton {...parentProps.props} />}
      {props.hasOwnProperty('m2m') && (
        <M2MEditButton
          parentSelectedRow={parentSelectedRow}
          {...props.m2m}
          history={history}
          parentComponentId={componentId}
          navigationId={navigationId}
          disabled={isEmpty(queryParams.q)}
          dataPath={components[componentId].path}
          foreignKey={rowKey}
          mainKeyValue={queryParams.q ? queryParams.q.split(':')[1] : null}
        />
      )}
      {isM2M && (
        <>
          <span style={{ marginLeft: 10 }}>{'Mostrar seleccionados  '}</span>
          <Switch onChange={handleSetM2MSwitch} loading={loading} />
        </>
      )}
      <SmartColumns
        columnsConfig={columns}
        onClick={onChangeColumnVisibility}
      />
      <DataExport
        componentId={componentId}
        disabled={isEmpty(dataSource.content)}
        data={dataSource.content}
        selectedRowKeys={selectedRowKeys}
        rowKey={rowKey}
        multiple={multiple && rowSelection}
        columns={fields}
        combos={combos}
        intl={intl}
      />
    </Row>
  );
}

const confirmNavigation = ({
  path,
  gobackPath,
  parentFormHasChanged,
  navigate,
  intl,
  handleChangeDrawerVisibility,
  returnExtension,
}) => {
  if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && parentFormHasChanged) {
    Modal.confirm({
      title: intl.formatMessage({ id: 'pop.title.select' }),
      content: intl.formatMessage({ id: 'pop.title.select.warn' }),
      okText: intl.formatMessage({ id: 'pop.accept' }),
      cancelText: intl.formatMessage({ id: 'pop.cancel' }),
      // icon: 'warning',
      maskClosable: true,
      onOk() {
        navigate({
          path,
          gobackPath,
          returnExtension,
        });
        handleChangeDrawerVisibility(false);
      },
      onCancel() {},
    });
  } else {
    navigate({
      path,
      gobackPath,
      returnExtension,
    });
    handleChangeDrawerVisibility(false);
  }
};

const ReturnButton = (parentProps) => {
  const {
    breadcrumbs,
    params,
    returnToParent,
    setDrawerVisibility,
  } = parentProps;
  const { tableType } = params;
  if (breadcrumbs.length > 1 && tableType === 0) {
    return (
      <Button
        className="single-btn"
        onClick={() => returnToParent({ breadcrumbs, setDrawerVisibility })}
      >
        Volver
      </Button>
    );
  }
  return null;
};

//TODO CUSTOM FUNCTION EOLO
const setAdvancedSearchValues = async (parentProps, newValues) => {
  const { isAdvancedSearch, setEditData, setFormStateFlag } = parentProps;
  const { componentId, handleShowModal, mainTableId } = isAdvancedSearch;

  let selectedValues = { ...newValues };

  setEditData({ componentId, values: selectedValues }) &&
    handleShowModal(false) &&
    setFormStateFlag({
      componentId: mainTableId,
      formHasChanged: true,
    });
};
//END TODO

const AdvancedSearchButton = (parentProps) => {
  const { selectedRow, isAdvancedSearch, params } = parentProps;
  const { rowKey } = params;
  const { values, fields } = isAdvancedSearch;

  let newValues = {};
  if (!isEmpty(selectedRow)) {
    newValues = { ...values };

    const checkFields = (fields, newValues, key) => {
      fields.forEach((field) => {
        if (field.key === key) {
          return { ...newValues, [key]: selectedRow[key] };
        }
      });
    };

    for (let key in selectedRow) {
      if (key !== rowKey) {
        newValues = { ...newValues, ...checkFields(fields, newValues, key) };
      }
    }
  }

  return (
    <Button
      type="primary"
      disabled={isEmpty(selectedRow)}
      className="selectRecord-btn"
      onClick={() => setAdvancedSearchValues(parentProps, newValues)}
      icon="select"
    >
      Seleccionar Registro
    </Button>
  );
};
