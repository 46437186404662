import { isEmpty } from 'lodash';
/**
 *
 * @param {Array} groups fields config
 * @param {Array} fieldsArray Array of fields
 * @return {Array} New array that composes, filters and sorts both arrays in a new one, which will be used to render the Edit Form
 */
export const formConstructor = (groups, fields, userPermissions, params) => {
  /*Sort and filter groups*/
  const sortedConstructor = [];
  if (!isEmpty(fields)) {
    let sortedGroups = groups
      .slice()
      .filter(elem => {
        return elem.mustRender;
      })
      .sort((prev, next) => {
        return prev.index - next.index;
      });

    const fieldsArray = checkArrayPermissions(fields, userPermissions);

    sortedGroups = checkElementPermissions(
      sortedGroups,
      userPermissions,
      params
    );
    sortedGroups.forEach(element => {
      let { subgroups, ...deconstructedGroup } = element;
      subgroups = checkElementPermissions(subgroups, userPermissions, element);
      sortedConstructor.push(
        Object.assign(deconstructedGroup, {
          subgroupRows: new Array(getRowsCount(subgroups))
            .fill()
            .map((row, i) => {
              /*Sort and filter subgroups*/
              const subgroupsPerRow = getSortedRowElements(subgroups, i);
              return subgroupsPerRow.map(subgroup => {
                const {
                  fields,
                  xPosition,
                  yPosition,
                  mustRender,
                  ...finalSubgroup
                } = subgroup;
                /*Returns subgroup without useless objects*/
                return Object.assign(finalSubgroup, {
                  fieldRow: new Array(getRowsCount(fields))
                    .fill()
                    .map((row, i) => {
                      /*Sort and filter Fields*/
                      let fieldsPerRow = getSortedRowElements(fields, i);
                      fieldsPerRow = checkFieldsPermissions(
                        fieldsPerRow,
                        finalSubgroup
                      );
                      return fieldsPerRow.map(fields => {
                        const {
                          xPosition,
                          yPosition,
                          mustRender,
                          ...finalField
                        } = fields;
                        /*Returns field without useless objects*/
                        return Object.assign(
                          finalField,
                          fillField(finalField, fieldsArray)
                        );
                      });
                    })
                });
              });
            })
        })
      );
    });
  }
  return sortedConstructor;
};

/**
 *
 * @param {Array} arrayOfElements
 * @returns {number} Number of rows that will contain the element, based on Max(yPosition)
 */
const getRowsCount = arrayOfElements => {
  return (
    Math.max(
      ...arrayOfElements
        .filter(function(element) {
          return element.mustRender;
        })
        .map(element => element.yPosition)
    ) + 1
  );
};

/**
 *
 * @param {Array} arrayOfElements
 * @param {number} rowIndex
 * @returns {Array}Array elements which cointains yPosition === rowIndex
 */
const getSortedRowElements = (arrayOfElements, rowIndex) => {
  return arrayOfElements
    .filter(element => {
      return (
        element.yPosition === rowIndex &&
        (element.mustRender === undefined || element.mustRender)
      );
    })
    .sort((prevRowElement, nextRowElement) => {
      return prevRowElement.xPosition - nextRowElement.xPosition;
    });
};

/**
 *
 * @param {Object} field Matching object
 * @param {Array} arrayFields Array of objects(fields) to compare with a concret object
 * @return {Object} returns a composed object (field + arrayField) which has the same key
 */
const fillField = (field, arrayFields) => {
  const match = arrayFields.filter(
    arrayField => arrayField.key === field.key
  )[0];
  const { key, ...composedField } = match;
  const filledField = checkBehaviours(composedField);
  return filledField;
};

const checkElementPermissions = (elements, userPermissions, parentElement) => {
  let groupPermissions = {
    disabled:
      parentElement.forceDisabled !== undefined
        ? parentElement.forceDisabled
        : undefined,
    visible:
      parentElement.forceVisible !== undefined
        ? parentElement.forceVisible
        : undefined
  };
  let elemenstArray = elements.slice();

  elemenstArray.forEach(element => {
    const { permissions } = element;
    if (permissions) {
      permissions.forEach(permission => {
        if (userPermissions.includes(permission.name)) {
          for (let key in permission) {
            if (key !== 'name') {
              if (
                groupPermissions === undefined ||
                groupPermissions.key === undefined
              )
                element[
                  'force'.concat(key.charAt(0).toUpperCase() + key.slice(1))
                ] = permission[key];
            }
          }
        }
      });
    }

    for (let key in groupPermissions) {
      if (groupPermissions[key] !== undefined)
        element['force'.concat(key.charAt(0).toUpperCase() + key.slice(1))] =
          groupPermissions[key];
    }
  });
  return elemenstArray;
};

const checkFieldsPermissions = (elements, parentElement) => {
  let groupPermissions = {
    disabled:
      parentElement.forceDisabled !== undefined
        ? parentElement.forceDisabled
        : undefined,
    visible:
      parentElement.forceVisible !== undefined
        ? parentElement.forceVisible
        : undefined
  };
  let elemenstArray = elements.slice();

  elemenstArray.forEach(element => {
    for (let key in groupPermissions) {
      if (groupPermissions[key] !== undefined) {
        element['force'.concat(key.charAt(0).toUpperCase() + key.slice(1))] =
          groupPermissions[key];
      }
    }
  });
  return elemenstArray;
};

/**
 * This function checks if the field has behaviour props and sets them to their initial behaviour prop
 * @param {Object} field
 */
const checkBehaviours = field => {
  for (let key in field) {
    switch (key) {
      case 'initialVisibility':
        field.visible = field[key];
        break;
      case 'initialDisabled':
        field.disabled = field[key];
        break;
      case 'initialMandatory':
        field.mandatory = field[key];
        break;
      default:
        break;
    }
  }
  return field;
};

const checkArrayPermissions = (fields, userPermissions) => {
  let fieldsArray = fields.slice();
  fieldsArray.forEach(field => {
    const { permissions } = field;
    if (permissions) {
      permissions.forEach(permission => {
        if (userPermissions.includes(permission.name)) {
          for (let key in permission) {
            if (key !== 'name') {
              field[
                'force'.concat(key.charAt(0).toUpperCase() + key.slice(1))
              ] = permission[key];
            }
          }
        }
      });
    }
  });
  return fieldsArray;
};

/**
 * This function returns global search field porps
 * @param {Array} fields Array of fields
 * @return {Object} New global search field with targets based on globalSearch bool option
 */
export const makeGlobalSearchField = fields => {
  const globalSearch = {
    title: 'Buscar',
    key: 'textSearch',
    type: 'text',
    fieldTargets: []
  };
  fields.forEach(field => {
    if (field.type === 'text') globalSearch.fieldTargets.push(field.key);
  });
  return globalSearch;
};

/**
 * This function evaluate how many search fields have been modified
 * @param {Array} fields Array of fields
 * @param {Object} values Object of values
 * @return {Int} Search fields moodified number
 */
export const getModifiedFieldsNumber = (fields, values) => {
  let count = 0;
  fields.forEach(field => {
    for (let keyOfValue in values) {
      if (
        field.key === keyOfValue &&
        (field.initialValue === undefined ||
          (field.initialValue !== undefined &&
            field.initialValue !== values[field.key]))
      )
        count += 1;
    }
  });
  return count;
};
