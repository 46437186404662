import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardComposer from '../dashboards/DashboardComposer';
import { setTableInitialState } from '../tables/tableActions';
import { setEditInitialState } from '../forms/edit/editActions';
import { setSearchInitialState } from '../forms/search/searchActions';

class ComponentsInitializer extends Component {
  constructor(props) {
    super(props);
    const {
      components,
      setTableInitialState,
      setEditInitialState,
      setSearchInitialState,
      tableComponents,
      searchComponents,
      editComponents
    } = props;

    components.forEach(component => {
      const { params } = component;
      const { componentId, type } = params;

      switch (type) {
        case 'search':
          if (!searchComponents[componentId]) {
            setSearchInitialState({ componentId });
          }

          break;
        case 'edit':
          if (!editComponents[componentId]) {
            setEditInitialState({ componentId });
          }
          break;
        case 'table':
          if (!tableComponents[componentId]) {
            setTableInitialState({ componentId });
          }
          break;
        default:
          break;
      }
    });
  }

  render() {
    return <DashboardComposer {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    searchComponents: state.searches,
    tableComponents: state.tables,
    editComponents: state.edits
  };
};

export default connect(
  mapStateToProps,
  { setSearchInitialState, setEditInitialState, setTableInitialState }
)(ComponentsInitializer);
