import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import apiPaths from '../apiPaths';

const AppparamsDashboard = props => {
    const components = [
{
    params: {
        type: 'search',
        componentId: 'appparamsappparamsSearch',
        panelHeader: 'Appparams Search',
        panelKey: 'appparamsappparamsSearch',
        yPosition: 0,
        xPosition: 0,
        width: 24,
        collapsible: true,
        collapsed: false,
        isTabItem: false,
        tabIndex: 0,
        tabTitle: ''
    },
    groups: [
        {
            index: 0,
            title: '',
            mustRender: true,
            subgroups: [
                {
                index: 0,
                title: '',
                mustRender: true,
                yPosition: 0,
                xPosition: 0,
                width: 24,
                fields: [
                    {
                        key: 'description',
                        yPosition: 1,
                        xPosition: 1,
                        mustRender: true,
                        width: 6 //TODO hardcoded
                    },
                    {
                        key: 'id',
                        yPosition: 1,
                        xPosition: 2,
                        mustRender: true,
                        width: 6 //TODO hardcoded
                    },
                    {
                        key: 'name',
                        yPosition: 1,
                        xPosition: 3,
                        mustRender: true,
                        width: 6 //TODO hardcoded
                    },
                    {
                        key: 'optimistickLocking',
                        yPosition: 1,
                        xPosition: 4,
                        mustRender: true,
                        width: 6 //TODO hardcoded
                    },
                    {
                        key: 'value',
                        yPosition: 2,
                        xPosition: 1,
                        mustRender: true,
                        width: 6 //TODO hardcoded
                    },
                ]
                }
            ]
        }
    ],
    fields: [
        {
            title: 'Description',
            key: 'description',
                        type: 'text',
            operand: 'like',
            min: 0,
            validationType: 'text',
            length: 0,
            mandatory: false,
            disabled: false,
        },
        {
            title: 'Id',
            key: 'id',
                        type: 'number',
            operand: 'like',
            min: 0,
            length: 0,
            mandatory: false,
            disabled: false,
        },
        {
            title: 'Name',
            key: 'name',
                        type: 'text',
            operand: 'like',
            min: 0,
            validationType: 'text',
            length: 0,
            mandatory: false,
            disabled: false,
        },
        {
            title: 'Optimistick Locking',
            key: 'optimistickLocking',
                        type: 'number',
            operand: 'like',
            min: 0,
            length: 0,
            mandatory: false,
            disabled: false,
        },
        {
            title: 'Value',
            key: 'value',
                        type: 'text',
            operand: 'like',
            min: 0,
            validationType: 'text',
            length: 0,
            mandatory: false,
            disabled: false,
        },
    ],
    settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN
    }
},
{
     params: {
         type: 'table',
         componentId: 'appparamsappparamsList',
         rowKey: 'id',
         panelHeader: 'Appparams List',
         panelKey: 'appparamsappparamsList',
         navigationParams: props.query.params.q || '',
         yPosition: 1,
         xPosition: 0,
         width: 24,
         collapsible: true,
         collapsed: false,
         isTabItem: false,
         tabTitle: '',
         isMainTable: true,
         tabIndex: 0,
         tableType: 0
     },
     fields: [
        {
             title: 'Description',
             dataIndex: 'description',
             position: 0,
             mustRender: true,
             align: 'left',
             sorter: false,
             visible: true,
        },
        {
             title: 'Id',
             dataIndex: 'id',
             position: 1,
             mustRender: true,
             align: 'left',
             sorter: false,
             visible: true,
        },
        {
             title: 'Name',
             dataIndex: 'name',
             position: 2,
             mustRender: true,
             align: 'left',
             sorter: false,
             visible: true,
        },
        {
             title: 'Optimistick Locking',
             dataIndex: 'optimistickLocking',
             position: 3,
             mustRender: true,
             align: 'left',
             sorter: false,
             visible: true,
        },
        {
             title: 'Value',
             dataIndex: 'value',
             position: 4,
             mustRender: true,
             align: 'left',
             sorter: false,
             visible: true,
        },
     ],
     settings: {
           size: config.COMPONENT.TABLE.SIZE,
           bordered: config.COMPONENT.TABLE.BORDERED,
           editable: true,
           multiple: false,
           canCreate: true,
           canDelete: false,
           scroll: { x: true }
     },
     pagination: {
         defaultPageSize: 50,
         pageSizeOptions: ['5', '10', '20', '50'],
         showQuickJumper: config.COMPONENT.SHOW_QUICK_JUMPER,
         showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
         showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
         size: config.COMPONENT.TABLE.SIZE
     }
},
{
    params: {
         type: 'edit',
         componentId: 'appparamsappparamsEdit',
         panelHeader: 'Appparams Edit',
         panelKey: 'appparamsappparamsEdit',
         primaryKey: 'id',
         yPosition: 2,
         xPosition: 0,
         width: 24,
         collapsible: true,
         collapsed: false,
         isTabItem: true,
         tabIndex: 0,
         tabTitle: 'Appparams'

    },
    groups: [
        {
            index: 0,
            title: '',
            mustRender: true,
            subgroups: [
                {
                index: 2,
                title: '',
                mustRender: true,
                yPosition: 0,
                xPosition: 0,
                width: 24,
                fields: [
                    {
                        key: 'description',
                        yPosition: 1,
                        xPosition: 1,
                        mustRender: true,
                        width: 6
                    },
                    {
                        key: 'name',
                        yPosition: 1,
                        xPosition: 2,
                        mustRender: true,
                        width: 6
                    },
                    {
                        key: 'optimistickLocking',
                        yPosition: 1,
                        xPosition: 3,
                        mustRender: true,
                        width: 6
                    },
                    {
                        key: 'value',
                        yPosition: 1,
                        xPosition: 4,
                        mustRender: true,
                        width: 6
                    },
                ]
                }
            ]
        }
    ],
    fields: [
        {
            title: 'Description',
            key: 'description',
                        type: 'text',
            unique: false,
            min: 0,
            validationType: 'text',
            length: 0,
            mandatory: false,
            disabled: false,
        },
        {
            title: 'Name',
            key: 'name',
                        type: 'text',
            unique: false,
            min: 0,
            validationType: 'text',
            length: 0,
            mandatory: false,
            disabled: false,
        },
        {
            title: 'Optimistick Locking',
            key: 'optimistickLocking',
                        type: 'number',
            unique: false,
            min: 0,
            length: 0,
            mandatory: false,
            disabled: false,
        },
        {
            title: 'Value',
            key: 'value',
                        type: 'text',
            unique: false,
            min: 0,
            validationType: 'text',
            length: 0,
            mandatory: false,
            disabled: false,
        },
    ],
    settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true
    }
},
    ];

    const componentId = 'AppparamsDashboard';

    return <DashboardInitializer componentId={componentId} components={components} {...props}/>;
};

export default AppparamsDashboard;

