import React from 'react';
import { Row, Button, Form } from 'antd';

export const buttonsContainerBottom = parentProps => {
  const { props, handleReset } = parentProps;
  const { isLoading, isM2M, m2mIsLoading, intl } = props;
  const loading = isM2M ? m2mIsLoading : isLoading;

  const handleResetCancel = () => {
    parentProps.handleChangeFilterVisibility();
    handleReset();
  };

  return (
    <Row className="form__bottomButtons">
      <Form.Item className="form__bottomButtons--floatLeft">
        <Button
          type="link"
          onClick={handleReset}
          title={intl.formatMessage({ id: 'form.clear.reg' })}
        >
          {intl.formatMessage({ id: 'form.clear' })}
        </Button>
      </Form.Item>
      <Form.Item className="form__bottomButtons--floatRight">
        <Button
          title={intl.formatMessage({ id: 'form.search.cancel' })}
          disabled={loading}
          // loading={loading}
          onClick={() => handleResetCancel()}
        >
          {intl.formatMessage({ id: 'form.search.cancel' })}
        </Button>
      </Form.Item>
      <Form.Item className="form__bottomButtons--floatRight">
        <Button
          title={intl.formatMessage({ id: 'form.search.apply' })}
          disabled={loading}
          loading={loading}
          onClick={e => parentProps.handleSubmit(e)}
        >
          {intl.formatMessage({ id: 'form.apply' })}
        </Button>
      </Form.Item>
    </Row>
  );
};
