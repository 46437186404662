const configDev = {
  WHY_DID_YOU_UPDATE_EN: false,
  LOAD_MOCK_MENU: false,
  LOAD_MOCK_PERMISSIONS: false,
  LOAD_MOCK_PARAMS: true,
  LOAD_MOCK_USER: false,
  AUTO_LOGIN: false,
  SAVE_CONFIG: false
};

export default configDev;
