import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Welcome from '../welcome/Welcome';
import NotFound from '../app/NotFound';
import config from '../config';
import AlumnoDashboard from './AlumnoDashboard';
import AlumnoClaseDashboard from './AlumnoClaseDashboard';
import AnyoLectivoDashboard from './AnyoLectivoDashboard';
import AppmenuDashboard from './AppmenuDashboard';
import AppparamsDashboard from './AppparamsDashboard';
import AreaDashboard from './AreaDashboard';
import ClaseDashboard from './ClaseDashboard';
import CodigonotaDashboard from './CodigonotaDashboard';
import CompetenciaDashboard from './CompetenciaDashboard';
import CursoDashboard from './CursoDashboard';
import DimensionDashboard from './DimensionDashboard';
import EducadorDashboard from './EducadorDashboard';
import EscuelaDashboard from './EscuelaDashboard';
import EscuelaLibroDashboard from './EscuelaLibroDashboard';
import EvaluacionDashboard from './EvaluacionDashboard';
import GradacionDashboard from './GradacionDashboard';
import IndicadorDashboard from './IndicadorDashboard';
import IndicadorCursoDashboard from './IndicadorCursoDashboard';
import LibroDashboard from './LibroDashboard';
import LicenciaDashboard from './LicenciaDashboard';
import MedianaDashboard from './MedianaDashboard';
import ModalidadDashboard from './ModalidadDashboard';
import NotaDashboard from './NotaDashboard';
import PersonalizacionGradacionDashboard from './PersonalizacionGradacionDashboard';
import PersonalizacionIndicadorDashboard from './PersonalizacionIndicadorDashboard';
import PonderacionDashboard from './PonderacionDashboard';
import RegistroDashboard from './RegistroDashboard';
import RubricapersonalizadaDashboard from './RubricapersonalizadaDashboard';
import RubricaDefault from './RubricaDefault';
import VideoGuiaDashboard from './VideoGuia';
import VideoPromocionalDashboard from './VideoPromocional';

const DashboardRouter = (props) => {
  const renderDashboard = (Component) => (renderProps) => {
    const { intl, query } = props;
    return <Component {...{ intl, query }} {...renderProps} />;
  };
  return (
    <Switch>
      {config.WELCOME.SHOW && (
        <Route path="/Home" render={renderDashboard(Welcome)} />
      )}
      <Route
        exact
        path="/alumnoview"
        render={renderDashboard(AlumnoDashboard)}
      />
      <Route
        exact
        path="/alumnoClaseview"
        render={renderDashboard(AlumnoClaseDashboard)}
      />
      <Route
        exact
        path="/anyoLectivoview"
        render={renderDashboard(AnyoLectivoDashboard)}
      />
      <Route
        exact
        path="/appmenuview"
        render={renderDashboard(AppmenuDashboard)}
      />
      <Route
        exact
        path="/appparamsview"
        render={renderDashboard(AppparamsDashboard)}
      />
      <Route exact path="/areaview" render={renderDashboard(AreaDashboard)} />
      <Route exact path="/claseview" render={renderDashboard(ClaseDashboard)} />
      <Route
        exact
        path="/codigonotaview"
        render={renderDashboard(CodigonotaDashboard)}
      />
      <Route
        exact
        path="/competenciaview"
        render={renderDashboard(CompetenciaDashboard)}
      />
      <Route exact path="/cursoview" render={renderDashboard(CursoDashboard)} />
      <Route
        exact
        path="/dimensionview"
        render={renderDashboard(DimensionDashboard)}
      />
      <Route
        exact
        path="/educadorview"
        render={renderDashboard(EducadorDashboard)}
      />
      <Route
        exact
        path="/escuelaview"
        render={renderDashboard(EscuelaDashboard)}
      />
      <Route
        exact
        path="/escuelaLibroview"
        render={renderDashboard(EscuelaLibroDashboard)}
      />
      <Route
        exact
        path="/evaluacionview"
        render={renderDashboard(EvaluacionDashboard)}
      />
      <Route
        exact
        path="/gradacionview"
        render={renderDashboard(GradacionDashboard)}
      />
      <Route
        exact
        path="/indicadorview"
        render={renderDashboard(IndicadorDashboard)}
      />
      <Route
        exact
        path="/indicadorCursoview"
        render={renderDashboard(IndicadorCursoDashboard)}
      />
      <Route exact path="/libroview" render={renderDashboard(LibroDashboard)} />
      <Route
        exact
        path="/licenciaview"
        render={renderDashboard(LicenciaDashboard)}
      />
      <Route
        exact
        path="/medianaview"
        render={renderDashboard(MedianaDashboard)}
      />
      <Route
        exact
        path="/modalidadview"
        render={renderDashboard(ModalidadDashboard)}
      />
      <Route exact path="/notaview" render={renderDashboard(NotaDashboard)} />
      <Route
        exact
        path="/personalizacionGradacionview"
        render={renderDashboard(PersonalizacionGradacionDashboard)}
      />
      <Route
        exact
        path="/personalizacionIndicadorview"
        render={renderDashboard(PersonalizacionIndicadorDashboard)}
      />
      <Route
        exact
        path="/ponderacionview"
        render={renderDashboard(PonderacionDashboard)}
      />
      <Route
        exact
        path="/registroview"
        render={renderDashboard(RegistroDashboard)}
      />
      <Route
        exact
        path="/rubricapersonalizadaview"
        render={renderDashboard(RubricapersonalizadaDashboard)}
      />
      <Route
        exact
        path="/rubricadefault"
        render={renderDashboard(RubricaDefault)}
      />
      <Route 
        exact
        path="/videoguia"
        render={renderDashboard(VideoGuiaDashboard)}
      />
      <Route 
        exact
        path="/videopromocional"
        render={renderDashboard(VideoPromocionalDashboard)}
      />
      <Route path="*" render={renderDashboard(NotFound)} />
    </Switch>
  );
};

export default DashboardRouter;
