import React, { Component } from 'react';
import { connect } from 'react-redux';
import ComponentsInitializer from './ComponentsInitializer';
import { setInitialDashboardState } from './dashboardActions';

class DashboardInitializer extends Component {
  constructor(props) {
    super(props);
    const { setInitialDashboardState, componentId, dashboards } = this.props;
    if (!dashboards[componentId]) {
      setInitialDashboardState({ componentId });
    }
  }

  render() {
    return <ComponentsInitializer {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    dashboards: state.dashboards
  };
};

export default connect(
  mapStateToProps,
  { setInitialDashboardState }
)(DashboardInitializer);
