const apiPaths = {
  AUTH: {
    LOGIN: 'oauth/token',
    LOGOUT: 'auth/logout',
  },
  ACCESS: 'access',
  COMBO: 'combodata',
  CALL: {
    ALUMNO: '/alumno',
    ALUMNOCLASE: '/alumnoclase',
    ANYOLECTIVO: '/anyolectivo',
    APPMENU: '/appmenu',
    APPPARAMS: '/appparams',
    AREA: '/area',
    CLASE: '/clase',
    CODIGONOTA: '/codigonota',
    COMPETENCIA: '/competencia',
    CURSO: '/curso',
    DIMENSION: '/dimension',
    EDUCADOR: '/educador',
    ESCUELA: '/escuela',
    ESCUELALIBRO: '/escuelalibro',
    EVALUACION: '/evaluacion',
    GRADACION: '/gradacion',
    INDICADOR: '/indicador',
    INDICADORCURSO: '/indicadorcurso',
    LIBRO: '/libro',
    LICENCIA: '/licencia',
    MEDIANA: '/mediana',
    MODALIDAD: '/modalidad',
    NOTA: '/nota',
    PERSONALIZACIONGRADACION: '/personalizaciongradacion',
    PERSONALIZACIONINDICADOR: '/personalizacionindicador',
    PONDERACION: '/ponderacion',
    REGISTRO: '/registro',
    RUBRICAPERSONALIZADA: '/rubricapersonalizada',
  },
  COMBODATA: {
    ALUMNO: 'alumno',
    CLASE: 'clase',
    MODALIDAD: 'modalidad',
    ANYO_LECTIVO: 'anyo_lectivo',
    CURSO: 'curso',
    DIMENSION: 'dimension',
    AREA: 'area',
    EDUCADOR: 'educador',
    ESCUELA: 'escuela',
    LIBRO: 'libro',
    CODIGONOTA: 'codigonota',
    INDICADOR: 'indicador',
    COMPETENCIA: 'competencia',
    EVALUACION: 'evaluacion',
    GRADACION: 'gradacion',
    REGISTRO: 'registro',
    RUBRICAPERSONALIZADA: 'rubricapersonalizada',
  },
  IMAGES: 'upload',
};

export default apiPaths;
