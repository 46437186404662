import * as types from './tableTypes';
import * as api from '../api';
import { filterComposer } from '../utils/apiUtils';
import config from '../config';
import { saveConfig, loadConfig } from '../utils/localStorage';
import configDev from '../configDev';
import { isEmpty } from 'lodash';

export const setTableInitialState = ({ componentId }) => dispatch => {
  dispatch({
    type: types.SET_INITIAL_TABLE_STATE,
    payload: { componentId }
  });
};

export const resetTableContent = ({ componentId }) => dispatch => {
  dispatch({
    type: types.TABLE_RESET_CONTENT,
    payload: { componentId }
  });
};

export const setChildSelectedRow = ({
  targetsId,
  record,
  prettierKey,
  rowKey
}) => dispatch => {
  targetsId.forEach(target => {
    dispatch({
      type: types.TABLE_SET_CHILD_ROW,
      payload: {
        componentId: target.id,
        parentSelectedRow: record,
        parentPrettierKey: prettierKey,
        parentRowKey: rowKey
      }
    });
  });
};

export const resetTableComponent = ({ componentId }) => dispatch => {
  dispatch({ type: types.TABLE_RESET_COMPONENT, payload: { componentId } });
};

export const setTableLoadData = ({ componentId }) => dispatch => {
  dispatch({
    type: types.TABLE_LOAD_DATA_TRIGGER,
    payload: { componentId: componentId }
  });
};

export const setLoadData = ({ componentId }) => dispatch => {
  dispatch({
    type: types.TABLE_SET_LOAD_DATA,
    payload: { componentId: componentId }
  });
};

export const setInitialState = ({
  componentId,
  targetsId,
  prettierKey,
  columns = [],
  queryParams = {},
  data = {},
  selectedRow = {},
  selectedRowKeys = [],
  rowKey
}) => dispatch => {
  const filters = filterComposer(columns);
  const initialColumns = columns
    .filter(c => {
      return c.mustRender;
    })
    .sort((a, b) => {
      return a.position - b.position;
    })
    .map((c, i) => {
      return (c = {
        ...c,
        position: i,
        width: c.width ? c.width : 200
      });
    });
  if (configDev.SAVE_CONFIG) {
    const userConfig = loadConfig();
    const newConfig = {
      ...userConfig,
      [config.USER.USERID]: {
        ...userConfig[config.USER.USERID],
        [componentId]: {
          ...userConfig[config.USER.USERID][componentId],
          columnsConfig: initialColumns
        }
      }
    };
    saveConfig(newConfig);
  }

  let tableParams = { ...queryParams };

  dispatch({
    type: types.TABLE_INITIAL_STATE,
    payload: {
      componentId,
      targetsId,
      columnsConfig: initialColumns,
      queryParams: tableParams,
      filters,
      prettierKey,
      data,
      selectedRow,
      selectedRowKeys,
      rowKey
    }
  });
};

export const setColumnsConfig = ({ componentId, columns = [] }) => dispatch => {
  dispatch({
    type: types.TABLE_CONFIGURED_COLUMNS,
    payload: { componentId, columns }
  });
  let userConfig = loadConfig();
  if (configDev.SAVE_CONFIG) {
    if (userConfig === undefined) {
      userConfig = {};
    }
    const newConfig = {
      ...userConfig,
      [componentId]: {
        ...userConfig[componentId],
        columnsConfig: columns
      }
    };
    saveConfig(newConfig);
  }
};

export const getTableData = ({
  dataPath,
  componentId,
  queryParams = {},
  foreignFilters
}) => async (dispatch, getState) => {
  const rowKey = getState().tables[componentId].rowKey;
  const currentParams = getState().tables[componentId].queryParams;
  const filters = getState().tables[componentId].filters;

  let nextParams = {};
  if (queryParams.q) {
    queryParams.size
      ? (nextParams = queryParams)
      : (nextParams = {
          ...queryParams, //ATENCIÓN A ESTO!!
          // q: queryParams.q,
          size: currentParams.size
        });
    if (!(queryParams.field && queryParams.sort))
      nextParams = {
        ...nextParams,
        sort: currentParams.sort,
        field: currentParams.field
      };
  } else {
    nextParams = {
      ...currentParams,
      ...queryParams
    };
  }

  dispatch({
    type: types.TABLE_FETCHING_DATA,
    payload: { componentId, queryParams: nextParams, isLoading: true }
  });

  let callParams = { ...nextParams };

  callParams.q =
    foreignFilters !== undefined
      ? foreignFilters + config.QUERY.AND + callParams.q
      : isEmpty(filters)
      ? callParams.q
      : isEmpty(callParams.q)
      ? filters
      : filters + config.QUERY.AND + callParams.q;

  try {
    const callConfig = {
      params: callParams
    };

    const response = await api.getDataCall({
      dataPath,
      callConfig
    });

    if (response.data)
      dispatch({
        type: types.TABLE_FETCHED_DATA,
        payload: { componentId, data: response.data, isLoading: false }
      });
    const status = {
      action: 'fetch',
      status: response.status,
      data: response.data
    };
    return status;
  } catch (err) {
    dispatch({
      type: types.TABLE_SEARCH_SENDING_DATA_ERROR,
      payload: { componentId, isLoading: false }
    });
    if (!err.response) return { action: 'fetch', status: {} };
    const status = {
      action: 'fetch',
      status: err.response.status,
      message: err.response.data.message
    };
    return status;
  }
};

export const createNewTableRow = ({ componentId, keys }) => dispatch => {
  dispatch({
    type: types.TABLE_CREATE_DATA,
    payload: { componentId, keys }
  });
};

export const deleteTableData = ({
  dataPath,
  componentId,
  selectedRowKeys,
  primaryKey
}) => async (dispatch, getState) => {
  const totalElements = getState().tables[componentId].data.totalElements;
  const totalPages = getState().tables[componentId].data.totalPages;
  const currentPage = getState().tables[componentId].queryParams.page;
  const navigationParams = getState().router.location.search;

  const numberOfElementsToErase = selectedRowKeys.length;
  const numberOfElementsInLastPage = totalElements % (totalPages - 1);

  dispatch({
    type: types.TABLE_DELETING_DATA,
    payload: { componentId, isLoading: true }
  });
  try {
    const registerId = selectedRowKeys.reverse()[0];
    const response = await api.deleteDataCallById({
      dataPath: componentId == 'VideoPromocionalList' ? 'videopromocional' : dataPath,
      registerId
    });

    dispatch({
      type: types.TABLE_DELETED_DATA,
      payload: { componentId, selectedRowKeys: [], isLoading: false }
    });
    dispatch(
      setSelectedRow({
        componentId,
        selectedRow: {},
        isLoading: false
      })
    );

    let getData = true;
    if (!isEmpty(navigationParams) && navigationParams.indexOf('?q=') >= 0) {
      let params = navigationParams.split(':');
      if (params[0].split('?q=')[1].toString() === primaryKey.toString())
        getData = false;
    }

    if (
      numberOfElementsInLastPage === numberOfElementsToErase &&
      totalPages > 1
    ) {
      const queryParams = {
        page: currentPage === totalPages - 1 ? totalPages - 2 : currentPage
      };
      dispatch(getTableData({ dataPath, componentId, queryParams }));
    } else {
      if (getData) dispatch(getTableData({ dataPath, componentId }));
    }

    //TO DO: Multiple delete when multiple delete call turns available
    const status = { action: 'delete', status: response.status };
    return status;
  } catch (err) {
    dispatch({
      type: types.TABLE_DELETING_DATA,
      payload: { componentId, isLoading: false }
    });
    if (!err.response) return { action: 'delete', status: {} };
    const status = {
      action: 'delete',
      status: err.response.status,
      message: err.response.data.message
    };
    return status;
  }
};

export const setInitialTableParams = ({
  sort,
  field,
  componentId
}) => dispatch => {
  dispatch({
    type: types.TABLE_SET_INITIAL_PARAMS,
    payload: { sort, field, componentId }
  });
};

export const deleteTableEmptyRow = ({
  componentId,
  data
}) => async dispatch => {
  dispatch({
    type: types.TABLE_REMOVE_EMPTY_ROW,
    payload: { componentId, data }
  });
};

export const setSelectedRows = ({
  componentId,
  selectedRowKeys = []
}) => dispatch => {
  dispatch({
    type: types.TABLE_SELECTED_ROWS,
    payload: { componentId, selectedRowKeys }
  });
};

export const setSelectedRow = ({
  componentId,
  selectedRow = {}
}) => dispatch => {
  dispatch({
    type: types.TABLE_SELECTED_ROW,
    payload: { componentId, selectedRow }
  });
};

export const setFormStateFlag = ({
  componentId,
  formHasChanged
}) => dispatch => {
  dispatch({
    type: types.TABLE_SET_FORM_STATE_FLAG,
    payload: { componentId, formHasChanged }
  });
};

export const updateTableRecord = ({ id, value, keys }) => dispatch => {
  dispatch({
    type: types.TABLE_MODIFYING_DATA,
    payload: {
      id,
      value,
      keys
    }
  });
};

export const resetTableData = ({ componentId }) => dispatch => {
  dispatch({
    type: types.RESET_TABLE_DATA,
    payload: {
      componentId,
      data: {},
      queryParams: {}
    }
  });
};

//TODO load image to Redux
export const loadImageModal = ({ imageUri }) => async dispatch => {
  dispatch({
    type: types.TABLE_FETCHING_IMAGE_MODAL,
    payload: { visible: true, isLoading: true }
  });

  try {
    const response = await api.resourceCall({
      dataPath: imageUri
    });

    // TODO dispatch de imagen a Redux

    const status = {
      action: 'fetch',
      status: response.status,
      image: response.data
    };
    return status;
  } catch (err) {
    if (!err.response) return { action: 'image', status: {} };
    const status = { action: 'image', status: err.response.data.status };
    return status;
  }
};

export const displayImageModal = (visible, isLoading) => dispatch => {
  dispatch({
    type: types.TABLE_FETCHED_IMAGE_MODAL,
    payload: { visible, isLoading }
  });
};
