import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getM2MTableData,
  addM2Mrecord,
  removeM2Mrecord,
  setGetSelected,
  setM2MTableParams
} from './m2mActions';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import './M2M.css';

class M2MDashboard extends Component {
  constructor(props) {
    super(props);
    const { components, setM2MTableParams } = this.props;
    components.forEach(component => {
      if (component.params.type === 'table') {
        component.fields.forEach(field => {
          if (field.defaultSortOrder !== undefined) {
            setM2MTableParams({
              field: field.dataIndex,
              sort: field.defaultSortOrder
            });
          }
        });
      }
    });
  }

  render() {
    const dashboardProps = this.props.customDashboard
      ? { ...this.props, customDashboard: this.props.componentId }
      : { ...this.props };
    return <DashboardInitializer {...dashboardProps} />;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {
    getM2MTableData,
    addM2Mrecord,
    removeM2Mrecord,
    setGetSelected,
    setM2MTableParams
  }
)(M2MDashboard);
