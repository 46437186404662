import qs from 'query-string';
import { push } from 'connected-react-router';
import config from '../config';
import { isEmpty } from 'lodash';

import * as queryTypes from './queryTypes';
import mockData from '../mockData';

/**
 * Switches app flag to get initial query params
 */
export const getQueryParams = () => dispatch => {
  dispatch({ type: queryTypes.QUERY_LOAD_QUERYPARAMS });
};

/**
 * Format an `Object` as a query string and append it to the current path.
 * Navigate to the new parameterized path.
 *
 * @param {object} params Parameters to transform and inject into query string.
 */
export const setQueryParams = params => (dispatch, getState) => {
  const { pathname } = getState().router.location;
  dispatch(push(`${pathname}?${qs.stringify(params)}`));
};

export const setInitialQueryParams = ({ initialParams }) => dispatch => {
  let params;
  if (initialParams.indexOf('?q=') >= 0) params = initialParams.split('?q=')[1];
  dispatch({
    type: queryTypes.QUERY_SET_INITIAL_PARAMS,
    payload: { initialParams: params }
  });
};

export const setBreadcrumbsChild = ({
  selectedRow,
  prettierKey,
  rowKey,
  dashboardId
}) => (dispatch, getState) => {
  const currentPath = getState().router.location.pathname;
  let breadcrumbs = getState().query.breadcrumbs.slice();
  breadcrumbs[breadcrumbs.length - 1].child = isEmpty(selectedRow)
    ? {}
    : {
        name:
          prettierKey !== undefined
            ? selectedRow[prettierKey]
            : selectedRow[rowKey],
        goBack: true,
        path: `${currentPath}?q=${rowKey}:${selectedRow[rowKey]}`,
        rowKey,
        value: selectedRow[rowKey],
        dashboardId
      };
  saveBreadcrumbs(breadcrumbs);
  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs }
  });
};

/**
 * This function navigates to previous dashboard and pops breadcrumbs
 * to previous dashboard selected element
 * @param {Array} breadcrumbs - Actual app breadcrumbs
 */
export const returnToParent = ({
  breadcrumbs,
  setDrawerVisibility,
  key
}) => dispatch => {
  if (key !== undefined) breadcrumbs.length = key + 1;
  else breadcrumbs.pop();
  let path = breadcrumbs[breadcrumbs.length - 1].child.path;
  if (breadcrumbs[breadcrumbs.length - 1].child.returnExtension)
    path = path + breadcrumbs[breadcrumbs.length - 1].child.returnExtension;
  const { dashboardId } = breadcrumbs[breadcrumbs.length - 1].child;
  dispatch(push(path));
  saveBreadcrumbs(breadcrumbs);
  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs }
  });

  setDrawerVisibility({ dashboardId, visible: true });
};

export const resetBreadcrumbs = ({ path, search }) => (dispatch, getState) => {
  const menu = getState().app.menu;
  let name, icon;
  menu.forEach(menuItem => {
    if (menuItem.route === path) {
      name = menuItem.nameOfMenu;
      icon = menuItem.iconOfMenu;
    }
    if (menuItem.subMenu) {
      menuItem.subMenu.forEach(subMenuItem => {
        if (subMenuItem.route === path) {
          name = subMenuItem.nameOfMenu;
          icon = subMenuItem.iconOfMenu;
        }
      });
    }
  });

  let breadcrumbs = [
    {
      path: search !== '' ? path.concat(search) : path,
      name,
      icon,
      absolutePath: path,
      child: {}
    }
  ];
  saveBreadcrumbs(breadcrumbs);
  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs }
  });
};

/**
 * This function is called before logging in. It removes all breadcrumbs and sets it to the initial route where the user is redirected to.
 *
 * @param {string} path Where the user is redirected to.
 * @param {string} name Display name for this step in the breadcrumbs component.
 * @param {string} icon AntD icon `CSS` class to display for this step in the breadcrumbs component.
 * @param {boolean} absolute In this case, absolute allows incoming breadcrumbs to construct properly.
 * @param {boolean} hasLink Allow the component to have link to its path.
 */
export const setInitialBreadcrumb = ({ path, name, icon }) => (
  dispatch,
  getState
) => {
  const actualPath = getState().router.location.pathname.concat(
    getState().router.location.search
  );
  let breadcrumbs = getState().query.breadcrumbs.slice();
  let absolutePath;
  if (
    breadcrumbs &&
    breadcrumbs.length > 0 &&
    (breadcrumbs[breadcrumbs.length - 1].path === actualPath ||
      (!isEmpty(breadcrumbs[breadcrumbs.length - 1].child) &&
        breadcrumbs[breadcrumbs.length - 1].child.path))
  )
    dispatch({
      type: queryTypes.QUERY_PUSH_BREADCRUMB,
      payload: { breadcrumbs }
    });
  else {
    const pathname = getState().router.location.pathname;
    const menu = getState().app.menu;
    // dispatch(push(path));
    breadcrumbs = [];
    menu.forEach(menuItem => {
      if (menuItem.route === pathname) {
        path = menuItem.route;
        name = menuItem.nameOfMenu;
        icon = menuItem.iconOfMenu;
        absolutePath = menuItem.route;
      }
      if (menuItem.subMenu) {
        menuItem.subMenu.forEach(subMenuItem => {
          if (subMenuItem.route === pathname) {
            path = subMenuItem.route;
            name = subMenuItem.nameOfMenu;
            icon = subMenuItem.iconOfMenu;
            absolutePath = subMenuItem.route;
          }
        });
      }
    });

    breadcrumbs.push({
      path,
      name,
      icon,
      absolutePath,
      child: {}
    });

    saveBreadcrumbs(breadcrumbs);
    dispatch({
      type: queryTypes.QUERY_PUSH_BREADCRUMB,
      payload: { breadcrumbs }
    });
  }
};

/**
 * Navigates to specified `path` leaving a breadcrumb that displays `name`
 * and `icon`.
 *
 * @param {string} path Where to navigate to.
 * @param {string} name Display name for this step in the breadcrumbs component.
 * @param {string} icon AntD icon `CSS` class to display for this step in the breadcrumbs component.
 * @param {boolean} absolute Whether this navigation should clean the breadcrumbs buffer (`true`) or not (`false`).
 */
export const navigate = ({ path, name, icon, absolute, returnExtension }) => (
  dispatch,
  getState
) => {
  const menu = getState().app.menu;
  let newName = name;
  let newIcon = icon;
  let breadcrumbs = getState().query.breadcrumbs.slice();
  const location = getState().router.location;

  if (path) {
    dispatch(push(path, location.pathname !== path));
  }

  if (absolute) {
    breadcrumbs.length = 0;
  }

  //TODO remove this (necesario para FKTHIRD & FKTHIRDTYPE)
  if (returnExtension && returnExtension !== '' && breadcrumbs.length > 1) {
    //breadcrumbs.lenght > 1 necesario
    breadcrumbs[breadcrumbs.length - 1].child.returnExtension = returnExtension;
  }

  let basicPath = path.indexOf('?q=') >= 0 ? path.split('?q=')[0] : path;
  if (newIcon === undefined || newName === undefined) {
    menu.forEach(m => {
      if (m.route === basicPath) {
        newIcon = m.iconOfMenu;
        newName = m.nameOfMenu;
      }
      if (m.subMenu) {
        m.subMenu.forEach(sub => {
          if (sub.route === basicPath) {
            newIcon = sub.iconOfMenu;
            newName = sub.nameOfMenu;
          }
        });
      }
    });
  }

  breadcrumbs.push({
    path,
    name: newName,
    icon: newIcon,
    absolutePath: basicPath,
    child: {}
  });
  saveBreadcrumbs(breadcrumbs);

  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs }
  });
};

export const setBreadcrumbs = ({ breadcrumbs, index }) => dispatch => {
  const newBreadcrumbs = breadcrumbs.slice();
  newBreadcrumbs.length = index + 1;
  newBreadcrumbs[newBreadcrumbs.length - 1].child = {};
  saveBreadcrumbs(newBreadcrumbs);

  dispatch({
    type: queryTypes.QUERY_PUSH_BREADCRUMB,
    payload: { breadcrumbs: newBreadcrumbs }
  });
};

export const editNatigationState = ({ component }) => dispatch => {
  dispatch({
    type: queryTypes.QUERY_SET_COMPONENT_NAVIGATION,
    payload: { component }
  });
};

/**
 * Navigates to a `path` existing in the current `breadcrumbs` state node. Cleans the
 * breadcrumbs state to hold the proper crumbs.
 *
 * @param {string} path Path saved in breadcrumbs.
 */
export const goBackToRoute = (path, setDrawerVisibility) => (
  dispatch,
  getState
) => {
  dispatch(push(path));

  let newBreadcrumbs = getState().query.breadcrumbs.slice();
  let dashboardId;

  const breadcrumbIndex = getState().query.breadcrumbs.findIndex(bc =>
    setDrawerVisibility ? bc.child.path === path : bc.path === path
  );

  newBreadcrumbs.length = breadcrumbIndex + 1;

  if (!setDrawerVisibility)
    newBreadcrumbs[newBreadcrumbs.length - 1].child = {};
  else
    dashboardId = newBreadcrumbs[newBreadcrumbs.length - 1].child.dashboardId;

  saveBreadcrumbs(newBreadcrumbs);

  dispatch({
    type: queryTypes.QUERY_GO_BACK,
    payload: { newBreadcrumbs }
  });

  setDrawerVisibility && setDrawerVisibility({ dashboardId, visible: true });
};

export const cleanBreadcrumb = () => (dispatch, getState) => {
  let breadcrumbs = getState().query.breadcrumbs.slice();
  if (!isEmpty(breadcrumbs[breadcrumbs.length - 1].child)) {
    dispatch(push(breadcrumbs[breadcrumbs.length - 1].path));
    breadcrumbs[breadcrumbs.length - 1].child = {};

    saveBreadcrumbs(breadcrumbs);
    dispatch({
      type: queryTypes.QUERY_PUSH_BREADCRUMB,
      payload: { breadcrumbs }
    });
  }
};

/**
 * This function dispatches a new route when creating a new record
 * and pushes this new element (which will be on selectedRow) to the breadcrumbs
 * @param {String} currentPath - Entity base path
 * @param {String} primaryKey - Entity primary key
 * @param {Array} row - New record
 * @param {String} prettierKey - Row attribute which will be displayed in the breadcrumbs
 */
export const navigateAfterCreate = ({
  currentPath,
  primaryKey,
  row
}) => dispatch => {
  let path = `${currentPath}?q=${primaryKey}:${row[primaryKey]}`;
  dispatch(push(path));
};

const saveBreadcrumbs = breadcrumbs =>
  localStorage.setItem(
    config.LOCAL_STORAGE.BREADCRUMBS,
    JSON.stringify(breadcrumbs)
  );
