import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import config from '../config';

const PrivateRoute = ({ render: Component, ...rest }) => (
  <Route
    {...rest}
    replace
    render={props =>
      config.SECURITY === 0 ||
      (config.SECURITY === 1 && rest.isAuthorized) ||
      (config.SECURITY === 2 && rest.accessToken) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const mapStateToProps = state => ({
  isAuthorized: state.auth.isAuthorized,
  accessToken: state.auth.accessToken
});

export default connect(mapStateToProps)(PrivateRoute);
