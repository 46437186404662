export const SEARCH_SET_VALUES = '[SEARCH] set values';
export const SEARCH_INITIAL_STATE = '[SEARCH] set initial state';
export const SEARCH_SET_FIELDS_CONFIG = '[SEARCH] set new fields config';
export const SET_INITIAL_SEARCH_STATE = '[SEARCH] set initial component state';
export const SEARCH_SET_INITIAL_DATA = '[SEARCH] set initial table data';
export const SEARCH_RESET_FORM = '[SEARCH] reset search form';
export const SEARCH_SET_FORM_HAS_CHANGED = '[SEARCH] set form has changed';
export const SEARCH_CLEAN_RESET_COMPONENT = '[SEARCH] reset clean component';
export const SEARCH_CHANGE_TAB = '[SEARCH] set search selected tab';
export const SEARCH_RESET_COMPONENT = '[SEARCH] reset search form component';
export const SEARCH_FILTER_VISIBLE = '[SEARCH] set filter visible';
