export const EDIT_INITIAL_STATE = '[EDIT] set initial state';
export const EDIT_SET_VALUES = '[EDIT] load data';
export const EDIT_SENDING_DATA = '[EDIT] sending data';
export const EDIT_SENT_DATA = '[EDIT] sent data';
export const EDIT_SENDING_DATA_ERROR = '[EDIT] sending data error';
export const EDIT_FETCHING_DATA = '[EDIT] fetching foreign data';
export const EDIT_FETCHED_DATA = '[EDIT] fetched foreign data';
export const EDIT_CREATING_DATA = '[EDIT] creating data';
export const EDIT_CREATED_DATA = '[EDIT] created data';
export const EDIT_SET_FIELDSCONFIG = '[EDIT] set fieldsConfig';
export const SET_INITIAL_EDIT_STATE = '[EDIT] set initial component state';
export const EDIT_CHANGE_TAB = '[EDIT] tab changed';
export const EDIT_RESET_FORM = '[EDIT] reset edit form';
export const EDIT_CLEAN_RESET_FORM_TRIGGER = '[EDIT] reset clean component';
export const EDIT_RESET_COMPONENT = '[EDIT] reset edit form component';
export const EDIT_FETCH_PARENT_DATA = '[EDIT] Fetch initial parent foreignData';
