import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { login } from './authActions';
import { Row, Col, Form, Icon, Input, Button, Progress } from 'antd';
import { feedbackController } from '../utils/feedback';
import config from '../config';
import configDev from '../configDev';
import mockData from '../mockData';
import ForgotPassword from './ForgotPassword';
import './Login.css';

const FormItem = Form.Item;

class Login extends Component {
  async componentDidMount() {
    if (configDev.AUTO_LOGIN) {
      const { login, feedback } = this.props;
      try {
        const statusSuccess = await login(mockData.LOGIN_VALUES);
        const { message } = statusSuccess;

        feedback({ ...statusSuccess, message });
      } catch (err) {
        console.error('Login:handleSubmit -> ' + err);
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, login, feedback, visible } = this.props;
    if (visible) return;
    form.validateFields((err, values) => {
      if (!err) {
        login(values)
          .then((statusSuccess) => feedback(statusSuccess))
          .catch((err) => {
            console.error('Login:handleSubmit -> ' + err);
          });
      }
    });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { isAuthorized, accessToken, isLoading, form, intl } = this.props;
    const { getFieldDecorator } = form;

    if ((isAuthorized || accessToken) && from.pathname !== '/') {
      return <Redirect to={from} />;
    }

    const loginLogo = config.LOGIN.LOGO_SHOW && (
      <div>
        <div className="logo" />
        <div className="enviroment text--white">{config.APP.ENVIROMENT}</div>
      </div>
    );
    const loginLogoResposive = config.LOGIN.LOGO_SHOW && (
      <div>
        <div className="logoMovile" />
        <div className="enviromentMovile">{config.APP.ENVIROMENT}</div>
      </div>
    );

    return (
      <Row className="login">
        <Col span={14} className="login__leftContent">
          {/* <span className="text--white">
            {intl.formatMessage({ id: 'login.create' })}
          </span> */}
          <div className="text">
            <span className="text--white">
              {intl.formatMessage({ id: 'login.welcome' })}
            </span>
          </div>
          {loginLogo}
        </Col>

        <Row>
          {loginLogoResposive}

          <Col span={10} className="login__rightContent">
            <Progress
              percent={100}
              status={isLoading ? 'active' : 'normal'}
              showInfo={false}
              strokeColor={{
                from: '#5C4D9B',
                to: '#5C4D9B',
                // to: '#32284a'
              }}
              className="progressBar"
            />
            <Form onSubmit={this.handleSubmit} hideRequiredMark>
              <label>{intl.formatMessage({ id: 'login.msg' })}</label>
              <FormItem colon={false}>
                {getFieldDecorator('username', {
                  rules: [
                    {
                      mandatory: true,
                      message: intl.formatMessage({ id: 'login.msn.user' }),
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="user" />}
                    placeholder={intl.formatMessage({ id: 'login.pc.user' })}
                  />
                )}
              </FormItem>
              <FormItem colon={false}>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      mandatory: true,
                      message: intl.formatMessage({ id: 'login.msn.pass' }),
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" />}
                    type="password"
                    placeholder={intl.formatMessage({ id: 'login.pc.pass' })}
                  />
                )}
              </FormItem>
              <ForgotPassword />
              <Button
                className="buttonLogin"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {intl.formatMessage({ id: 'login.submit' })}
              </Button>
            </Form>
          </Col>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthorized: state.auth.isAuthorized,
    isLoading: state.auth.isLoading,
    accessToken: state.auth.accessToken,
    visible: state.auth.visible,
  };
};

Login = injectIntl(Login);
Login = Form.create()(Login);
export default connect(mapStateToProps, {
  login,
  feedback: feedbackController,
})(Login);
