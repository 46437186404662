import React, { Component } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import * as api from '../api';
import store from '../store';
import config from '../config';
import components from '../components';
import { feedback } from '../utils/feedback';

const dataType = {
  CSV: {
    format: 'csv',
    type: 'text/csv;charset=utf-8'
  },
  XLS: {
    format: 'xls',
    type: 'data:application/vnd.ms-excel'
  }
};

class DataExport extends Component {
  dataToXLS = (data, columns) => {
    if (isEmpty(data)) {
      return null;
    }
    //const keys = Object.keys(data[0]);
    let result = '<table border="2px">';

    // HEADER
    result += '<tr>';
    columns
      .filter(column => column.mustRender)
      .sort((prev, next) => {
        return prev.position - next.position;
      })
      .forEach(column => {
        if (column !== columns[columns.length - 1])
          result += '<td bgcolor="#DFDFDF">' + column.title + '</td>';
        else result += '<td bgcolor="#DFDFDF">' + column.title + '</tr>';
      });

    // BODY

    const { combos, intl, componentId } = this.props;

    data.forEach((item, i) => {
      result += '<tr>';
      columns
        .filter(column => column.mustRender)
        .sort((prev, next) => {
          return prev.position - next.position;
        })
        .forEach(column => {
          let newValue = '';
          if (column && column.dataIndex && item) {
            const nestedObjArray = column.dataIndex.toString().split('.');
            if (
              nestedObjArray.length > 1 &&
              item[nestedObjArray[0]] &&
              item[nestedObjArray[0]][nestedObjArray[1]]
            ) {
              if (
                column.render === 'comboCustom' &&
                combos[column.comboId] &&
                combos[column.comboId].data
              ) {
                //Caso combo anidado
                combos[column.comboId].data.map(o => {
                  if (
                    o.value ===
                    item[nestedObjArray[0]][nestedObjArray[1]].toString()
                  ) {
                    newValue = o.description;
                  }
                  return o;
                });
              } else if (column.render === 'combo' && combos[componentId]) {
                const { dataIndex, comboId } = column;
                if (
                  combos[componentId][dataIndex] &&
                  combos[componentId][dataIndex][comboId] &&
                  combos[componentId][dataIndex][comboId].data &&
                  !isEmpty(combos[componentId][dataIndex][comboId].data)
                )
                  combos[componentId][dataIndex][comboId].data.map(o => {
                    if (
                      o.value ===
                      item[nestedObjArray[0]][nestedObjArray[1]].toString()
                    )
                      newValue = o.description;
                    return o;
                  });
              } else {
                let field = item[nestedObjArray[0]][
                  nestedObjArray[1]
                ].toString();
                newValue = field;
              }
              //Caso combo que no tiene 'render: combo'. No deberian existir.
              // } else if (
              //   item[nestedObjArray[0]] &&
              //   nestedObjArray[1] &&
              //   item[nestedObjArray[0]][nestedObjArray[1]]
              // ) {
              //   newValue = item[nestedObjArray[0]][nestedObjArray[1]];
            } else if (nestedObjArray.length === 1) {
              let field = item[column.dataIndex];
              if (field === undefined || field === null) newValue = '';
              else {
                field = item[column.dataIndex].toString();
                if (
                  column.render === 'comboCustom' &&
                  combos[column.comboId] &&
                  combos[column.comboId].data &&
                  !isEmpty(combos[column.comboId].data)
                ) {
                  //Caso combo no anidado
                  combos[column.comboId].data.map(o => {
                    if (o.value === field) newValue = o.description;
                    return o;
                  });
                } else if (column.render === 'combo' && combos[componentId]) {
                  const { dataIndex, comboId } = column;
                  if (
                    combos[componentId][dataIndex] &&
                    combos[componentId][dataIndex][comboId] &&
                    combos[componentId][dataIndex][comboId].data &&
                    !isEmpty(combos[componentId][dataIndex][comboId].data)
                  )
                    combos[componentId][dataIndex][comboId].data.map(o => {
                      if (o.value === field) newValue = o.description;
                      return o;
                    });
                } else if (column.render === 'check') {
                  field === 'true' ? (newValue = 'Sí') : (newValue = 'No');
                } else if (field && column.render === 'date') {
                  newValue = intl.formatDate(field, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  });
                } else newValue = field;
              }
            }
          }
          result += '<td>' + newValue + '</td>';
        });
      result += '</tr>';
    });
    result += '</table>';
    return result;
  };

  dataToCSV = (data, columns) => {
    if (isEmpty(data)) {
      return null;
    }
    //const keys = Object.keys(data[0]);
    let result = '';
    // result += keys.join(config.EXPORT.COLUMN_DELIMITER);
    // result += config.EXPORT.LINE_DELIMITER;

    const newColumns = columns.filter(column => column.mustRender);

    newColumns.forEach(column => {
      if (column !== newColumns[newColumns.length - 1])
        result += column.title + config.EXPORT.COLUMN_DELIMITER;
      else result += column.title + config.EXPORT.LINE_DELIMITER;
    });

    const { combos, intl, componentId } = this.props;
    let ctrl;
    data.forEach(item => {
      ctrl = false;
      columns
        .filter(column => column.mustRender)
        .forEach(column => {
          if (ctrl) result += config.EXPORT.COLUMN_DELIMITER;
          let newValue = '';
          if (column && column.dataIndex && item) {
            const nestedObjArray = column.dataIndex.toString().split('.');
            if (
              nestedObjArray.length > 1 &&
              item[nestedObjArray[0]] &&
              item[nestedObjArray[0]][nestedObjArray[1]]
            ) {
              if (
                column.render === 'comboCustom' &&
                combos[column.comboId] &&
                combos[column.comboId].data
              ) {
                //Caso combo anidado
                combos[column.comboId].data.map(o => {
                  if (
                    o.value ===
                    item[nestedObjArray[0]][nestedObjArray[1]].toString()
                  ) {
                    newValue = o.description;
                  }
                  return o;
                });
              } else if (column.render === 'combo' && combos[componentId]) {
                const { dataIndex, comboId } = column;
                if (
                  combos[componentId][dataIndex] &&
                  combos[componentId][dataIndex][comboId] &&
                  combos[componentId][dataIndex][comboId].data &&
                  !isEmpty(combos[componentId][dataIndex][comboId].data)
                )
                  combos[componentId][dataIndex][comboId].data.map(o => {
                    if (
                      o.value ===
                      item[nestedObjArray[0]][nestedObjArray[1]].toString()
                    )
                      newValue = o.description;
                    return o;
                  });
              } else {
                let field = item[nestedObjArray[0]][
                  nestedObjArray[1]
                ].toString();
                newValue = field;
              }
              //Caso combo que no tiene 'render: combo'. No deberian existir.
              // } else if (
              //   item[nestedObjArray[0]] &&
              //   nestedObjArray[1] &&
              //   item[nestedObjArray[0]][nestedObjArray[1]]
              // ) {
              //   newValue = item[nestedObjArray[0]][nestedObjArray[1]];
            } else if (nestedObjArray.length === 1) {
              let field = item[column.dataIndex];
              if (field === undefined || field === null) newValue = '';
              else {
                field = item[column.dataIndex]
                  .toString()
                  .split(config.EXPORT.COLUMN_DELIMITER);
                if (field.length > 1) field = field.join(',');
                else field = field[0];
                if (
                  column.render === 'comboCustom' &&
                  combos[column.comboId] &&
                  combos[column.comboId].data &&
                  !isEmpty(combos[column.comboId].data)
                ) {
                  //Caso combo no anidado
                  combos[column.comboId].data.map(o => {
                    if (o.value === field) newValue = o.description;
                    return o;
                  });
                } else if (column.render === 'combo' && combos[componentId]) {
                  const { dataIndex, comboId } = column;
                  if (
                    combos[componentId][dataIndex] &&
                    combos[componentId][dataIndex][comboId] &&
                    combos[componentId][dataIndex][comboId].data &&
                    !isEmpty(combos[componentId][dataIndex][comboId].data)
                  )
                    combos[componentId][dataIndex][comboId].data.map(o => {
                      if (o.value === field) newValue = o.description;
                      return o;
                    });
                } else if (column.render === 'check') {
                  field === 'true' ? (newValue = 'Sí') : (newValue = 'No');
                } else if (field && column.render === 'date') {
                  newValue = intl.formatDate(field, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  });
                } else newValue = field;
              }
            }
          }
          result += newValue;
          ctrl = true;
        });
      result += config.EXPORT.LINE_DELIMITER;
    });
    return result;
  };

  processData = (
    componentId,
    data,
    columns,
    format,
    selectedRowKeys = null,
    rowKey = null
  ) => {
    if (selectedRowKeys && rowKey) {
      data = data.filter(row => {
        return selectedRowKeys.includes(row[rowKey]);
      });
    }

    let text, type;
    if (format === dataType.XLS.format) {
      text = this.dataToXLS(data, columns);
      type = dataType.XLS.type;
    } else if (format === dataType.CSV.format) {
      text = this.dataToCSV(data, columns);
      type = dataType.CSV.type;
    }

    if (!text) return;

    let fileName = (componentId || 'data') + '.' + format;
    let blob = new Blob([text], { type: type });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  getAllData = async () => {
    const { componentId } = this.props;
    const currentParams = store.getState().tables[componentId].queryParams;
    const filters = store.getState().tables[componentId].filters;

    let callParams = { ...currentParams };
    callParams.q = isEmpty(filters)
      ? callParams.q
      : isEmpty(callParams.q)
      ? filters
      : filters + config.QUERY.AND + callParams.q;
    callParams.size = '10000';
    const response = await api.getDataCall({
      dataPath: components[componentId].path,
      callConfig: { params: callParams }
    });
    return response;
  };

  onClick = async ({ key }) => {
    const response = await this.getAllData();
    if (response.status === 200 && response.data) {
      const data = response.data.content;
      const { componentId, selectedRowKeys, rowKey, columns } = this.props;
      switch (key) {
        case 'AC':
          this.processData(componentId, data, columns, 'csv');
          break;
        case 'AX':
          this.processData(componentId, data, columns, 'xls');
          break;
        case 'SC':
          this.processData(
            componentId,
            data,
            columns,
            'csv',
            selectedRowKeys,
            rowKey
          );
          break;
        case 'SX':
          this.processData(
            componentId,
            data,
            columns,
            'xls',
            selectedRowKeys,
            rowKey
          );
          break;
        default:
          break;
      }
    } else
      feedback({
        type: 'notification',
        method: 'info',
        message: 'No se ha podido realizar la acción'
      });
  };

  render() {
    const { disabled, intl } = this.props;

    const menu = (
      <Menu onClick={this.onClick}>
        <Menu.Item key="AC">.csv</Menu.Item>
        <Menu.Item key="AX">.xls</Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']} disabled={disabled}>
        <Button
          className="single-btn"
          style={{ float: 'right', marginRight: 10 }}
          icon="export"
        >
          {intl.formatMessage({ id: 'export.title' })}
        </Button>
      </Dropdown>
    );
  }
}

export default injectIntl(DataExport);
