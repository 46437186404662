import * as types from './searchTypes';

export const setSearchInitialState = ({ componentId }) => dispatch => {
  dispatch({
    type: types.SET_INITIAL_SEARCH_STATE,
    payload: { componentId }
  });
};

/**
 * @param {String} componentId - Component's name which contains the modified field
 * @param {Object} newFieldsConfig - New fieldsConfig which will be sent to Redux
 */
export const setFieldsConfig = (componentId, newFieldsConfig) => dispatch => {
  dispatch({
    type: types.SEARCH_SET_FIELDS_CONFIG,
    payload: { componentId, newFieldsConfig, resetFormTrigger: true }
  });
};

export const setInitialState = ({
  componentId,
  targetId,
  fieldsConfig,
  globalSearchField,
  values,
  resetFormTrigger,
  formHasChanged
}) => dispatch => {
  dispatch({
    type: types.SEARCH_INITIAL_STATE,
    payload: {
      componentId,
      targetId,
      fieldsConfig,
      globalSearchField,
      values,
      resetFormTrigger,
      formHasChanged
    }
  });
};

export const setSearchValues = ({ componentId, values }) => dispatch => {
  dispatch({
    type: types.SEARCH_SET_VALUES,
    payload: { componentId, values }
  });
};

export const resetSearchForm = ({
  componentId,
  fieldsConfig,
  values
}) => dispatch => {
  dispatch({
    type: types.SEARCH_RESET_FORM,
    payload: { componentId, values, fieldsConfig, resetFormTrigger: true }
  });
};

export const setResetFormTrigger = ({ componentId }) => dispatch => {
  dispatch({
    type: types.SEARCH_CLEAN_RESET_COMPONENT,
    payload: { componentId, resetFormTrigger: false }
  });
};

export const resetSearchComponent = ({ componentId }) => dispatch => {
  dispatch({ type: types.SEARCH_RESET_COMPONENT, payload: { componentId } });
};
export const setTriggerGetTableData = ({ componentId, value }) => dispatch => {
  dispatch({
    type: types.SEARCH_SET_INITIAL_DATA,
    payload: { componentId, value }
  });
};

export const setFormHasChanged = ({
  componentId,
  formHasChanged
}) => dispatch => {
  dispatch({
    type: types.SEARCH_SET_FORM_HAS_CHANGED,
    payload: { componentId, formHasChanged }
  });
};

export const setSelectedTabSearch = ({
  componentId,
  selectedTab
}) => dispatch => {
  dispatch({
    type: types.SEARCH_CHANGE_TAB,
    payload: { componentId, selectedTab }
  });
};

export const setFilterVisibility = ({
  componentId,
  filterVisible
}) => dispatch => {
  dispatch({
    type: types.SEARCH_FILTER_VISIBLE,
    payload: { componentId, filterVisible }
  });
};
