import * as types from './comboTypes';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.COMBO_INITIALIZE_COMPONENT: {
      return {
        ...state,
        [action.payload.componentId]: {}
      };
    }
    case types.COMBO_INITIALIZE_COMBO: {
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          [action.payload.fieldKey]: {}
        }
      };
    }
    case types.COMBO_FETCHING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          [action.payload.fieldKey]: {
            ...state[action.payload.componentId][action.payload.fieldKey],
            [action.payload.comboId]: {
              ...state[action.payload.componentId][action.payload.fieldKey][
                action.payload.comboId
              ],
              isLoading: action.payload.isLoading
            }
          }
        }
      };
    case types.COMBO_FETCHED_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          [action.payload.fieldKey]: {
            ...state[action.payload.componentId][action.payload.fieldKey],
            [action.payload.comboId]: {
              ...state[action.payload.componentId][action.payload.fieldKey][
                action.payload.comboId
              ],
              data: action.payload.data,
              isLoading: action.payload.isLoading
            }
          }
        }
      };
    case types.COMBO_ADD_CUSTOM:
      return {
        ...state,
        ...action.payload.data
      };
    default:
      return state;
  }
}
