import * as api from '../api';
import React from 'react';
import { Icon, Col, Button } from 'antd';

export default function DownloadButton({ disabled, folder, docName }) {
  const handleDownloadFile = async ({ folder, docName }) => {
    let format;
    //TODO fkFolder y docName son válidos solo para la aplicacion de EOLO
    const callConfig = { params: { fkFolder: folder, docName } };

    const response = await api.getDataCall({ dataPath: '/docs', callConfig });

    if (response.status === 200) {
      if (docName.indexOf('.') >= 0) {
        format = docName.split('.')[1];

        let mimeType;
        switch (format) {
          case 'pdf':
            mimeType = 'application/';
            break;
          case 'jpg':
          case 'jpeg:':
          case 'png':
            mimeType = 'image/';
            break;
          default:
            mimeType = 'application/';
            break;
        }
        if (mimeType) mimeType += format.toString();

        const link = document.createElement('a');
        link.setAttribute(
          'href',
          `data:${mimeType};base64,${response.data.file}`
        );
        link.setAttribute('download', docName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <Col md={4}>
      <Button
        disabled={disabled}
        onClick={() => handleDownloadFile({ folder, docName })}
        type="primary"
        className="downloadButton"
      >
        <Icon type="download" />
      </Button>
    </Col>
  );
}
