import React from 'react';
import { connect, Provider } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import config from '../config';

import store from '../store';

import { setLanguage } from './appActions';

import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/en-gb';

import antES from 'antd/lib/locale-provider/es_ES';
import antEn from 'antd/lib/locale-provider/en_GB';
import antCa from 'antd/lib/locale-provider/ca_ES';

import en_US from '../locale/static/en_US';
import es_ES from '../locale/static/es_ES';
import ca_ES from '../locale/static/ca_ES';

import var_en_US from '../locale/var/var_en_US';
import var_es_ES from '../locale/var/var_es_ES';
import var_ca_ES from '../locale/var/var_ca_ES';

import localeEs from 'react-intl/locale-data/es';
import localeEn from 'react-intl/locale-data/en';
import localeCa from 'react-intl/locale-data/ca';

export default ({ children }) => (
  <ReduxContext>
    <LocaleContext>{children}</LocaleContext>
  </ReduxContext>
);

const ReduxContext = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

addLocaleData([...localeEn, ...localeEs, ...localeCa]);

const antdMessages = {
  'es-ES': antES,
  'en-US': antEn,
  'ca-ES': antCa
};

const messages = {
  'es-ES': {
    ...es_ES,
    ...var_es_ES
  },
  'en-US': {
    ...en_US,
    ...var_en_US
  },
  'ca-ES': {
    ...ca_ES,
    ...var_ca_ES
  }
};

const mapStateToProps = state => ({
  locale: state.app.locale
});

const LocaleContext = connect(
  mapStateToProps,
  { setLanguage }
)(
  class extends React.Component {
    constructor(props) {
      super(props);
      const { setLanguage } = this.props;
      const language = config.LOCALE.FIXED
        ? config.LOCALE.DEFAULT
        : (navigator.languages && navigator.languages[0]) ||
          navigator.language ||
          navigator.userLanguage;
      setLanguage(language);
    }

    render() {
      const { children } = this.props;
      const { locale } = store.getState().app;

      moment.locale(locale);
      return (
        <ConfigProvider locale={antdMessages[locale]}>
          <IntlProvider locale={locale} messages={messages[locale]}>
            {children}
          </IntlProvider>
        </ConfigProvider>
      );
    }
  }
);
