import * as types from './m2mTypes';
import * as api from '../api';
import config from '../config';

const unflatten = require('flat').unflatten;

// No se está utilizando!
export const setInitialState = ({
  searchFields = [],
  tableFields = [],
  queryParams,
  parentQueryParams
}) => dispatch => {
  const sortedTableFields = tableFields
    .filter(c => {
      return c.mustRender;
    })
    .sort((a, b) => {
      return a.position - b.position;
    })
    .map((c, i) => {
      // Se elimina la función de intercambio de columnas dentro de
      // la cabecera de la tabla porque da problemas y no se necesita
      delete c.onHeaderCell;
      return (c = {
        ...c,
        position: i
      });
    });

  const mainKey = parentQueryParams.q.split(':')[0];
  const mainKeyValue = Number(parentQueryParams.q.split(':')[1]);

  dispatch({
    type: types.M2M_SET_INITIAL_STATE,
    payload: {
      searchFields,
      tableFields: sortedTableFields,
      queryParams,
      mainKey,
      mainKeyValue
    }
  });
};

export const setGetSelected = ({ getSelected }) => dispatch => {
  dispatch({ type: types.M2M_GET_SELECTED_ROWS, payload: { getSelected } });
};

export const setSelectedM2MDashboard = ({ activeDashboard }) => dispatch => {
  dispatch({
    type: types.M2M_SET_ACTIVE_DASHBOARD,
    payload: { activeDashboard }
  });
};

export const addM2Mrecord = ({
  dataPath,
  foreignKey,
  record,
  mainKeyValue,
  joinForeignKey,
  joinKey
}) => async dispatch => {
  dispatch({
    type: types.M2M_ADDING_RECORD,
    payload: { isLoading: true }
  });

  try {
    const data = unflatten({
      [joinKey]: mainKeyValue,
      [joinForeignKey]: record[foreignKey]
    });

    const response = await api.postDataCall({
      dataPath,
      data
    });

    if (response.data) {
      dispatch({
        type: types.M2M_ADDED_RECORD,
        payload: { isLoading: false }
      });
    }
    const status = { action: 'create', status: response.status };
    return status;
  } catch (err) {
    if (!err.response) return { action: 'create', status: {} };
    const status = {
      action: 'create',
      status: err.response.status,
      message: err.response.data.message
    };
    return status;
  }
};

export const removeM2Mrecord = ({
  dataPath,
  record,
  m2mPrimaryKey
}) => async dispatch => {
  dispatch({
    type: types.M2M_REMOVING_RECORD,
    payload: { isLoading: true }
  });

  try {
    const response = await api.deleteDataCall({
      dataPath,
      data: { [m2mPrimaryKey]: record[m2mPrimaryKey] }
    });

    dispatch({
      type: types.M2M_REMOVED_RECORD,
      payload: { isLoading: false }
    });

    const status = { action: 'delete', status: response.status };
    return status;
  } catch (err) {
    if (!err.response) return { action: 'delete', status: {} };
    const status = {
      action: 'delete',
      status: err.response.status,
      message: err.response.data.message
    };
    return status;
  }
};

export const setM2MTableParams = ({ field, sort }) => dispatch => {
  dispatch({ type: types.M2M_SET_TABLE_PARAMS, payload: { field, sort } });
};

export const getM2MTableData = ({
  dataPath,
  m2mDataPath,
  queryParams,
  primaryKey,
  joinKey,
  mainKeyValue,
  foreignKey,
  getSelected,
  navigationId
}) => async (dispatch, getState) => {
  let nextParams = {};
  let filteredRecordsArray = [];
  const currentParams = getState().m2m.queryParams;
  let data;
  let selectedRecordsId = [];

  if (queryParams.q) {
    queryParams.size
      ? (nextParams = queryParams)
      : (nextParams = { q: queryParams.q, size: currentParams.size });
    if (!(queryParams.field && queryParams.sort))
      nextParams = {
        ...nextParams,
        sort: currentParams.sort,
        field: currentParams.field
      };
  } else {
    nextParams = {
      ...currentParams,
      ...queryParams
    };
  }

  dispatch({
    type: types.M2M_FETCHING_RECORDS,
    payload: { queryParams: { ...nextParams }, isLoading: true }
  });

  if (getSelected) {
    if (nextParams.q)
      nextParams.q += config.QUERY.AND + navigationId + ':' + mainKeyValue;
    else nextParams.q = navigationId + ':' + mainKeyValue;
  }

  if (getState().m2m.getSelected !== getSelected) nextParams.page = 0;

  try {
    const callConfig = {
      params: nextParams
    };
    //1. Get outher DATA
    const outherResponse = await api.getDataCall({
      dataPath: dataPath,
      callConfig
    });
    let outherContent = outherResponse.data.content;

    try {
      const callConfig = {
        params: {
          q: `${joinKey}:${mainKeyValue}`,
          size: 500
        }
      };
      //2. Get M2M DATA
      const m2mResponse = await api.getDataCall({
        dataPath: m2mDataPath,
        callConfig: callConfig
      });
      const m2mContent = m2mResponse.data.content;

      if (m2mResponse.data && !getSelected) {
        outherContent.forEach(entityRecord => {
          let entity = { ...entityRecord };
          m2mContent.forEach(m2mRecord => {
            if (
              m2mRecord[foreignKey][foreignKey] === entityRecord[foreignKey]
            ) {
              entity = {
                ...entityRecord,
                [primaryKey]: m2mRecord[primaryKey]
              };
              selectedRecordsId.push(entityRecord[foreignKey]);
            }
          });
          filteredRecordsArray.push({ ...entity });
        });

        data = {
          ...outherResponse.data,
          content: filteredRecordsArray
        };

        dispatch({
          type: types.M2M_FETCHED_RECORDS,
          payload: {
            data,
            selectedRecordsId: selectedRecordsId,
            isLoading: false
          }
        });
      } else if (m2mResponse.data && getSelected) {
        outherContent.forEach(entityRecord => {
          m2mContent.forEach(m2mRecord => {
            if (m2mRecord[foreignKey][foreignKey] === entityRecord[foreignKey])
              entityRecord[primaryKey] = m2mRecord[primaryKey];
          });
          selectedRecordsId.push(entityRecord[foreignKey]);
        });

        data = {
          ...outherResponse.data,
          content: outherContent
        };

        dispatch({
          type: types.M2M_FETCHED_RECORDS,
          payload: {
            data,
            selectedRecordsId,
            isLoading: false
          }
        });
      }

      const status = { action: 'fetch', status: m2mResponse.status };
      return status;
    } catch (err) {
      if (!err.m2mResponse) return { action: 'fetch', status: {} };
      const status = {
        action: 'fetch',
        status: err.m2mResponse.status,
        message: err.m2mResponse.data.message
      };
      return status;
    }
  } catch (err) {
    if (!err.outherResponse) return { action: 'fetch', status: {} };
    const status = {
      action: 'fetch',
      status: err.outherResponse.status,
      message: err.outherResponse.data.message
    };
    return status;
  }
};

export const setM2MModalVisibility = isVisible => dispatch => {
  dispatch({
    type: types.M2M_SET_MODAL_VISIBILITY,
    payload: { visible: isVisible }
  });
};

export const resetM2M = () => dispatch => {
  dispatch({
    type: types.M2M_RESET
  });
};
