import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './Login.css';
import { Modal, Input, Form, Button } from 'antd';
import config from '../config';
import { passwordRecovery, setModal } from './authActions';
import './ForgotPassword.css';

const ButtonGroup = Button.Group;
const FormItem = Form.Item;

class ForgotModal extends Component {
  handleSubmitModal = (e) => {
    e.preventDefault();
    const { form, passwordRecovery } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        passwordRecovery(values);
        this.handleCancel();
      }
    });
  };

  showModal = () => {
    const { setModal } = this.props;
    setModal({
      visible: true,
    });
  };

  handleCancel = () => {
    const { setModal } = this.props;
    setModal({
      visible: false,
    });
  };

  render() {
    const { intl, form, visible } = this.props;
    const { getFieldDecorator } = form;

    const forgotPassword = config.LOGIN.FORGOT_PASSWORD && (
      <Button
        className="buttonForgotPassword"
        type="link"
        onClick={this.showModal}
      >
        {intl.formatMessage({ id: 'login.forgot' })}
      </Button>
    );
    return (
      <div>
        {forgotPassword}
        <Modal
          className="forgotPassword"
          visible={visible}
          footer={false}
          onCancel={this.handleCancel}
          title={intl.formatMessage({ id: 'password.recovery' })}
        >
          <p>{intl.formatMessage({ id: 'password.email.message' })}</p>
          <Form onSubmit={this.handleSubmitModal}>
            <FormItem>
              {getFieldDecorator('mailLogin', {
                rules: [
                  {
                    type: 'email',
                    message: intl.formatMessage({ id: 'password.email' }),
                  },
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'password.email.null' }),
                  },
                ],
              })(<Input />)}
            </FormItem>
            <ButtonGroup
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <Button onClick={this.handleCancel}>
                {intl.formatMessage({ id: 'pop.cancel' })}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginLeft: '10px' }}
              >
                {intl.formatMessage({ id: 'pop.accept' })}
              </Button>
            </ButtonGroup>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    visible: state.auth.visible,
  };
};

ForgotModal = Form.create()(ForgotModal);
ForgotModal = injectIntl(ForgotModal);

export default connect(mapStateToProps, { passwordRecovery, setModal })(
  ForgotModal
);
