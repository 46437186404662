import * as tableTypes from './tableTypes';

let initialState = {};

initialState.imageModal = { visible: false, isLoading: false };

export default function(state = initialState, action) {
  switch (action.type) {
    case tableTypes.SET_INITIAL_TABLE_STATE:
      return {
        ...state,
        [action.payload.componentId]: {}
      };
    case tableTypes.TABLE_INITIAL_STATE:
      const { componentId, ...payload } = action.payload;
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          ...payload
        }
      };
    case tableTypes.TABLE_RESET_CONTENT: {
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          data: {},
          selectedRow: {},
          selectedRowKeys: [],
          queryParams: {}
        }
      };
    }
    case tableTypes.TABLE_SET_INITIAL_PARAMS:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          queryParams: {
            ...state[action.payload.componentId].queryParams,
            sort: action.payload.sort,
            field: action.payload.field
          }
        }
      };
    case tableTypes.TABLE_RESET_COMPONENT:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          queryParams: {},
          data: {},
          selectedRow: {},
          selectedRowKeys: [],
          parentSelectedRow: {},
          formHasChanged: false
        }
      };
    case tableTypes.TABLE_LOAD_DATA_TRIGGER:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          loadData: false,
          isLoading: true
        }
      };
    case tableTypes.TABLE_SET_LOAD_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          loadData: true
        }
      };
    case tableTypes.TABLE_CONFIGURED_COLUMNS:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          columnsConfig: action.payload.columns
        }
      };
    case tableTypes.TABLE_FETCHING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          queryParams: action.payload.queryParams,
          isLoading: action.payload.isLoading
        }
      };
    case tableTypes.TABLE_FETCHED_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          data: action.payload.data,
          isLoading: action.payload.isLoading
        }
      };
    case tableTypes.TABLE_SELECTED_ROWS:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedRowKeys: action.payload.selectedRowKeys
        }
      };
    case tableTypes.TABLE_SET_CHILD_ROW: {
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          parentSelectedRow: action.payload.parentSelectedRow,
          parentPrettierKey: action.payload.parentPrettierKey,
          parentRowKey: action.payload.parentRowKey
        }
      };
    }
    case tableTypes.TABLE_SELECTED_ROW:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedRow: action.payload.selectedRow
        }
      };

    case tableTypes.TABLE_CREATE_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          data: {
            ...state[action.payload.componentId].data,
            content: [
              ...state[action.payload.componentId].data.content,
              action.payload.keys
            ]
          }
        }
      };

    case tableTypes.TABLE_DELETING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case tableTypes.TABLE_DELETED_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedRowKeys: action.payload.selectedRowKeys,
          isLoading: action.payload.isLoading
        }
      };
    case tableTypes.TABLE_REMOVE_EMPTY_ROW:
      return {
        ...state,
        data: {
          ...state[action.payload.componentId].data,
          content: state[action.payload.componentId].data
        }
      };
    case tableTypes.TABLE_SET_FORM_STATE_FLAG:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          formHasChanged: action.payload.formHasChanged
        }
      };
    case tableTypes.TABLE_FETCHING_IMAGE_MODAL:
      return {
        ...state,
        imageModal: {
          visible: action.payload.visible,
          isLoading: action.payload.isLoading
        }
      };
    case tableTypes.TABLE_FETCHED_IMAGE_MODAL:
      return {
        ...state,
        imageModal: {
          visible: action.payload.visible,
          isLoading: action.payload.isLoading
        }
      };

    case tableTypes.TABLE_SEARCH_SENDING_DATA_ERROR:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: false
        }
      };
    case tableTypes.TABLE_MODIFYING_DATA:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          data: {
            ...state[action.payload.id].data,
            content: [action.payload.value]
          }
        }
      };
    case tableTypes.RESET_TABLE_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          data: action.payload.data,
          queryParams: action.payload.queryParams,
          parentSelectedRow: {}
        }
      };
    default:
      return state;
  }
}
