import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import configDev from '../../configDev';
import config from '../../config';
import { Form } from 'antd';
import {
  getTableData,
  setTableLoadData,
  setSelectedRow
} from '../../tables/tableActions';
import {
  setDrawerVisibility,
  setSelectedTab
} from '../../dashboards/dashboardActions';
import {
  setFieldsConfig,
  setInitialState,
  setSearchValues,
  setTriggerGetTableData,
  resetSearchForm,
  setFormHasChanged,
  setResetFormTrigger,
  setFilterVisibility,
  setSelectedTabSearch
} from './searchActions';
import { getComboData } from '../../combos/comboActions';
import { editNatigationState, cleanBreadcrumb } from '../../app/queryActions';
import { qComposer } from '../../utils/apiUtils';
import components from '../../components.js';
import { feedbackController } from '../../utils/feedback';
import { logout } from '../../auth/authActions';
import apiPaths from '../../apiPaths';
import { formConstructor, makeGlobalSearchField } from '../formConstructor';
import SearchFormRender from './SearchFormRender';
import { loadConfig, saveConfig } from '../../utils/localStorage';
import '../FormStyles.css';
const flatten = require('flat').flatten;

const isTableTarget = targetId =>
  targetId !== '' && components[targetId].type === 'table';

class SearchForm extends Component {
  constructor(props) {
    super(props);
    const {
      params,
      fields,
      groups,
      fieldsConfig,
      setInitialState,
      setTableLoadData,
      setTriggerGetTableData,
      queryParams,
      userPermissions
    } = this.props;
    const { q } = queryParams;
    const { componentId, visible } = params;
    let userConfig = loadConfig();
    let sortedFieldsConfig = [];
    let globalSearchField = {};

    if (!fieldsConfig) {
      if (
        configDev.SAVE_CONFIG &&
        userConfig !== undefined &&
        userConfig[config.USER.USERID] &&
        userConfig[config.USER.USERID][componentId] &&
        userConfig[config.USER.USERID][componentId]['searchFormConfig']
      ) {
        sortedFieldsConfig =
          userConfig[config.USER.USERID][componentId]['searchFormConfig'];
        globalSearchField =
          userConfig[config.USER.USERID][componentId]['searchFormConfig'][
            'globalSearchField'
          ];
      } else {
        globalSearchField = makeGlobalSearchField(fields);
        sortedFieldsConfig = formConstructor(
          groups,
          fields,
          userPermissions,
          params
        );

        if (configDev.SAVE_CONFIG) {
          if (userConfig === undefined) {
            userConfig = {};
          }

          if (!userConfig[config.USER.USERID]) {
            userConfig = { ...userConfig, [config.USER.USERID]: {} };
          }

          const newConfig = {
            ...userConfig,
            [config.USER.USERID]: {
              ...userConfig[config.USER.USERID],
              [componentId]: {
                ...userConfig[config.USER.USERID][componentId],
                searchFormConfig: { sortedFieldsConfig, globalSearchField }
              }
            }
          };
          saveConfig(newConfig);
        }
      }

      let values = this.setInitialValues(sortedFieldsConfig);
      if (!isEmpty(q)) {
        q.split(config.QUERY.AND).forEach(element => {
          const key = element.split(':')[0];
          const value = element.split(':')[1];
          fields.forEach(field => {
            if (field.key === key && value)
              values = { ...values, [key]: value.toString() };
          });
          if (value) values = { ...values, [key]: value.toString() };
        });
      }
      setInitialState({
        componentId,
        targetId: components[componentId].targetId,
        fieldsConfig: sortedFieldsConfig,
        globalSearchField,
        values,
        resetFormTrigger: false,
        formHasChanged: false
      });

      if (!isEmpty(values) && visible !== false) {
        setTableLoadData({ componentId: components[componentId].targetId });
        setTriggerGetTableData({ componentId, value: true });
      }
    }
  }

  componentDidMount() {
    const {
      getDataTrigger,
      queryParams,
      setTriggerGetTableData,
      values,
      targetId,
      params,
      setSearchValues,
      fields,
      isM2M,
      isModalDashboard
    } = this.props;
    const { q } = queryParams;
    const { componentId } = params;
    let initialValues = { ...values };

    fields.forEach(field => {
      if (field.hasOwnProperty('initialValue')) {
        if (
          !config.COMPONENT.RESET_ON_UNMOUNT &&
          values &&
          field.initialValue !== values[field.key]
        ) {
        } else initialValues[field.key] = field.initialValue;
      }
    });

    if (!isEmpty(q) && !isM2M) {
      q.split(config.QUERY.AND).forEach(element => {
        const key = element.split(':')[0];
        const value = element.split(':')[1];
        fields.forEach(field => {
          if (field.key === key && value)
            initialValues = { ...initialValues, [key]: value.toString() };
        });
      });
    }

    setSearchValues({ componentId, values: initialValues });

    if (getDataTrigger) {
      this.getData(initialValues, targetId);
      setTriggerGetTableData({ componentId, value: false });
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      queryParams,
      values,
      params,
      fieldsConfig,
      setFieldsConfig,
      hasNavigated,
      editNatigationState,
      getDataTrigger,
      setTriggerGetTableData,
      targetId,
      form,
      resetFormTrigger,
      setResetFormTrigger,
      fields,
      setSearchValues,
      isModalDashboard
    } = this.props;

    const { componentId } = params;
    const { q } = queryParams;
    let control = false;
    let initialValues = { ...values };
    let query;

    if (q === undefined && prevProps.queryParams.q !== undefined) {
      let resetValues = {};
      fields.forEach(field => {
        if (
          field.hasOwnProperty('initialValue') &&
          field.mustRender !== false
        ) {
          resetValues = { ...resetValues, [field.key]: field.initialValue };
        }
      });

      setSearchValues({ componentId, values: resetValues });
      setTriggerGetTableData({ componentId, value: true });
      form.resetFields();
    } else {
      if (!isEmpty(q)) {
        q.split(config.QUERY.AND).forEach(element => {
          const key = element.split(':')[0];
          const value = element.split(':')[1];
          fields.forEach(field => {
            if (field.key === key && value)
              initialValues = { ...initialValues, [key]: value.toString() };
          });
          if (value) {
            query = { ...query, [key]: value };
          }
        });
      }

      if (getDataTrigger) {
        const fieldsConfigBehaviourSelector = field => {
          if (
            field.hasOwnProperty('initialValue') &&
            field.initialValue !== values[field.key]
          ) {
            initialValues[field.key] = field.initialValue;
          }
        };

        this.changeFieldsConfig(fieldsConfig, fieldsConfigBehaviourSelector);
        this.getData(initialValues, targetId);
        setTriggerGetTableData({ componentId, value: false });
      } else if (hasNavigated) {
        fields.forEach(field => {
          if (
            field.hasOwnProperty('initialValue') &&
            initialValues[field.key] !== undefined
          ) {
            initialValues = {
              ...initialValues,
              [field.key]: field.initialValue
            };
          }
        });
        if (isModalDashboard && isModalDashboard.initialParams)
          initialValues = {
            ...initialValues,
            [isModalDashboard.initialParams.key]:
              isModalDashboard.initialParams.value
          };

        if (!isEmpty(query)) {
          const fieldsConfigBehaviourSelector = field => {
            for (let key in query) {
              if (field.key === key && !field.disabled) {
                field.disabled = true;
                control = true;
              }
            }
            return field;
          };

          const newFieldsConfig = this.changeFieldsConfig(
            fieldsConfig,
            fieldsConfigBehaviourSelector
          );

          if (control) setFieldsConfig(componentId, newFieldsConfig);
        }

        setSearchValues({ componentId, values: initialValues });
        this.getData(initialValues, targetId);
        setTriggerGetTableData({ componentId, value: false });
        editNatigationState({ component: 'searchFormNavigation' });
      } else if (!isEmpty(query)) {
        const fieldsConfigBehaviourSelector = field => {
          for (let key in query) {
            if (
              field.key === key &&
              !field.disabled &&
              !field.hasOwnProperty('parentValue')
            ) {
              field.disabled = true;
              control = true;
            }
          }
          return field;
        };

        const newFieldsConfig = this.changeFieldsConfig(
          fieldsConfig,
          fieldsConfigBehaviourSelector
        );
        if (control) setFieldsConfig(componentId, newFieldsConfig);
      } else if (isEmpty(q)) {
        const fieldsConfigBehaviourSelector = field => {
          if (
            field.hasOwnProperty('disabled') &&
            field.type === 'combo' &&
            field.disabled !== field.initialDisabled &&
            !field.hasOwnProperty('parentValue')
          ) {
            field.disabled = field.initialDisabled;
            control = true;
          }
          return field;
        };

        const newFieldsConfig = this.changeFieldsConfig(
          fieldsConfig,
          fieldsConfigBehaviourSelector
        );

        if (control) setFieldsConfig(componentId, newFieldsConfig);
      }
      if (resetFormTrigger) {
        await setResetFormTrigger({ componentId });
        form.resetFields();
      }
    }
  }

  getData = values => {
    const {
      fields,
      filters,
      getTableData,
      targetId,
      isM2M,
      getM2MTableData,
      path,
      dataPath,
      primaryKey,
      mainKeyValue,
      joinKey,
      foreignKey,
      getSelected,
      navigationId,
      selectedRow,
      breadcrumbs,
      isModalDashboard
    } = this.props;

    let submitValues = { ...values };
    for (let key in submitValues) {
      if (
        submitValues[key] === 'null' ||
        submitValues[key] === undefined ||
        parseInt(submitValues[key]) < 0
      ) {
        delete submitValues[key];
      }
    }
    const composition = qComposer(fields, submitValues);

    let q = composition
      ? (filters ? filters + config.QUERY.AND : '') + composition
      : filters;

    if (isModalDashboard && isModalDashboard.initialParams) {
      if (q !== '') q += config.QUERY.AND;
      q += `${isModalDashboard.initialParams.key}:${isModalDashboard.initialParams.value}`;
    }

    if (isM2M)
      getM2MTableData({
        m2mDataPath: path,
        dataPath,
        queryParams: { q },
        primaryKey,
        mainKeyValue,
        joinKey,
        foreignKey,
        getSelected,
        navigationId
      });
    else {
      const dataPath = isModalDashboard
        ? `${components[targetId].path}`
        : (!selectedRow || isEmpty(selectedRow)) &&
          !isEmpty(breadcrumbs[breadcrumbs.length - 1].child)
        ? `${components[targetId].path}/Page/${
            breadcrumbs[breadcrumbs.length - 1].child.value
          }`
        : `${components[targetId].path}`;

      getTableData({
        dataPath,
        componentId: targetId,
        queryParams: { q }
      });
    }
  };

  setInitialValues = fieldsConfig => {
    let values = {};

    const fieldsConfigBehaviourSelector = field => {
      if (field.initialValue !== undefined) {
        values = {
          ...values,
          [field.key]: field.initialValue
        };
      }
    };
    this.changeFieldsConfig(fieldsConfig, fieldsConfigBehaviourSelector);

    return values;
  };

  /**
   * This function makes a call to the API in order to get requested data.
   * First of all, the function validates the fields and then it composes a query which will be used in the api call
   */
  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const {
      targetId,
      values,
      form,
      getTableData,
      filters,
      fields,
      params,
      isM2M,
      getM2MTableData,
      path,
      dataPath,
      primaryKey,
      mainKeyValue,
      joinKey,
      foreignKey,
      getSelected,
      navigationId
    } = this.props;
    form.validateFields(async (err, formValues) => {
      let submitValues = { ...values, ...formValues };

      for (let key in submitValues) {
        if (submitValues[key] === 'null' || submitValues[key] === undefined)
          delete submitValues[key];
        else if (typeof submitValues[key] === 'object') {
          for (let k in submitValues[key]) {
            if (
              submitValues[key][k] === 'null' ||
              submitValues[key][k] === undefined
            )
              delete submitValues[key][k];
          }
          if (isEmpty(submitValues[key])) delete submitValues[key];
        }
      }

      if (!err && isTableTarget(targetId)) {
        fields.forEach(f => {
          if (f.type === 'time' && submitValues[f.key]) {
            let newTime = submitValues[f.key].split(':');
            newTime = new Date(
              Date.UTC('1970', '01', '01', newTime[0], newTime[1], newTime[2])
            );
            const hours = newTime.getHours().toString();
            const minutes = newTime.getMinutes().toString();
            const seconds = newTime.getSeconds().toString();
            submitValues[f.key] = hours.concat(':', minutes, ':', seconds);
          } else if (f.type === 'rangerpicker' && submitValues[f.key]) {
            const firstDate = submitValues[f.key][0];
            const secondDate = submitValues[f.key][1];
            submitValues[f.key] = `${firstDate.year()}-${firstDate.month() +
              1}-${firstDate.date()}.${secondDate.year()}-${secondDate.month() +
              1}-${secondDate.date()}`;
          }
        });

        for (let key in submitValues) {
          if (
            submitValues[key] === 'null' ||
            submitValues[key] === undefined ||
            parseInt(submitValues[key]) < 0
          ) {
            delete submitValues[key];
          }
        }

        const composition = qComposer(fields, submitValues);
        let q = composition
          ? (filters ? filters + config.QUERY.AND : '') + composition
          : filters;

        if (params.navigationParams && !isM2M) {
          q +=
            config.QUERY.AND +
            params.navigationParams +
            ':' +
            params.navigationParams;
        }
        if (this.props.filterVisible)
          this.props.setFilterVisibility({
            componentId: this.props.params.componentId,
            filterVisible: false
          });
        isM2M
          ? await getM2MTableData({
              m2mDataPath: path,
              dataPath,
              queryParams: { q },
              primaryKey,
              mainKeyValue,
              joinKey,
              foreignKey,
              getSelected,
              navigationId
            })
          : await getTableData({
              dataPath: components[targetId].path,
              componentId: targetId,
              queryParams: { q }
            });
      }
    });
  };

  /**
   * This function resets the Search form values
   * If the form has boolean inputs, also resets its states in Redux.
   */
  handleReset = async () => {
    const {
      params,
      values,
      resetSearchForm,
      fields,
      queryParams,
      fieldsConfig,
      formHasChanged,
      setFormHasChanged,
      isModalDashboard
    } = this.props;
    const { componentId } = params;
    const { q } = queryParams;
    let boolVals = {};
    for (let key in values) {
      if (typeof values[key] === 'boolean') boolVals[key] = values[key];
    }
    fields.forEach(field => {
      if (field.hasOwnProperty('initialValue')) {
        boolVals[field.key] = field.initialValue;
      }
    });

    if (!isEmpty(q)) {
      q.split(config.QUERY.AND).forEach(element => {
        const key = element.split(':')[0];
        const value = element.split(':')[1];
        fields.forEach(field => {
          if (field.key === key && value)
            boolVals = { ...boolVals, [key]: value.toString() };
        });
      });
    }

    setSearchValues({ componentId, values: { ...boolVals } });

    const fieldsConfigBehaviourSelector = field => {
      let control = true;
      if (field.hasOwnProperty('parentValue') && field.parentValue !== '') {
        field.parentValue = '';
      }
      if (!field.hasOwnProperty('initialValue')) {
        if (field.hasOwnProperty('behaviours')) {
          field.behaviours.forEach(behaviour => {
            fields.forEach(f => {
              if (
                f.key === behaviour.key &&
                !f.hasOwnProperty('initialValue')
              ) {
                control = false;
              }
            });
          });
        }
        if (!control) {
          if (field.hasOwnProperty('initialVisibility')) {
            field.visible = field.initialVisibility;
          }
          if (field.hasOwnProperty('initialDisabled')) {
            field.disabled = field.initialDisabled;
          }
          if (field.hasOwnProperty('initialMandatory')) {
            field.mandatory = field.initialMandatory;
          }
        }
      }
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector
    );

    await resetSearchForm({
      componentId,
      fieldsConfig: newFieldsConfig,
      values: { ...boolVals }
    });

    if (formHasChanged) {
      setFormHasChanged({
        componentId,
        formHasChanged: false
      });
    }
  };

  handleChangeTab = activeKey => {
    const { setSelectedTabSearch, params } = this.props;
    const { componentId } = params;
    setSelectedTabSearch({
      componentId,
      selectedTab: activeKey
    });
  };

  /**
   * Updates the value of a field
   * @param {string} type - Field type
   * @param {int} id - Field identifier
   * @param {*} value - New field value
   * @param {string} format - Date format for types: date, datetime, time
   */
  handleChangeField = async ({
    type,
    id,
    value,
    format = 'YYYY-MM-DDTHH:mm:ss.SSS+0000'
  }) => {
    const { params, values, setSearchValues, setFormHasChanged } = this.props;
    const { componentId } = params;

    if (value === null || isEmpty(value)) delete values[id];
    else
      switch (type) {
        case 'date':
        case 'datetime':
        case 'time': {
          values[id] =
            format === 'timestamp' ? value.unix() : value.format(format);
          break;
        }
        case 'rangerpicker': {
          const newVal = [];
          value.forEach(v => {
            newVal.push(
              id.format === 'timestamp' ? v.unix() : v.format(format)
            );
          });
          values[id] = newVal.join('.');
          break;
        }
        default: {
          values[id] = value;
        }
      }

    setFormHasChanged({
      componentId,
      formHasChanged: true
    });
    setSearchValues({ componentId, values });
  };

  handleAddData = () => {
    const {
      setSelectedRow,
      setDrawerVisibility,
      setSelectedTab,
      dashboardId,
      params,
      setFilterVisibility,
      cleanBreadcrumb
    } = this.props;
    const { componentId } = params;

    setSelectedTab({
      dashboardId,
      componentId: undefined,
      tabIndex: '0'
    });
    setSelectedRow({
      componentId: components[componentId].targetId,
      selectedRow: {}
    });

    cleanBreadcrumb();
    setFilterVisibility({
      componentId,
      filterVisible: false
    });
    setDrawerVisibility({ dashboardId, visible: true });
  };

  handleChangeFilterVisibility = e => {
    const { params, setFilterVisibility, filterVisible } = this.props;
    const { componentId } = params;
    setFilterVisibility({
      componentId,
      filterVisible: !filterVisible
    });
  };

  handleGoogleInput = (value, targets) => {
    const { params, values, setSearchValues } = this.props;
    const { componentId } = params;

    let newValues = { ...values };

    targets.forEach(target => {
      newValues = {
        ...newValues,
        [target]: value
      };
    });
    setSearchValues({ componentId, values: newValues });
  };

  /**
   * Updates the options of a child combo depending on parent selected item.
   * @param comboId {Object} - object with the information of the child.
   * @param id {int} - the identifier of the selected item in parent combo.
   */
  updateChildCombo = (comboId, id, key) => {
    const { getComboData, params } = this.props;
    const { componentId } = params;
    let queryParams = { id: comboId };
    if (id) queryParams = { ...queryParams, param: id };
    getComboData({
      dataPath: apiPaths.COMBO,
      comboId,
      fieldKey: key,
      componentId,
      queryParams
    });
  };

  /**
   * This function is used to set new parents value on a combo child.
   * @param {String} key Field key from the combo child.
   * @param {String} value New combo parent value
   */
  setParentValue = (key, value) => {
    const { setFieldsConfig, params, fieldsConfig } = this.props;
    const { componentId } = params;

    const fieldsConfigBehaviourSelector = field => {
      if (field.key === key) {
        field.parentValue = value;
      }
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector
    );
    setFieldsConfig(componentId, newFieldsConfig);
  };

  handleChildBehaviours = (
    targetKey,
    behavioursDisplayed,
    forceVisible,
    forceDisabled
  ) => {
    const { setFieldsConfig, params, fieldsConfig } = this.props;
    const { componentId } = params;

    const fieldsConfigBehaviourSelector = field => {
      if (field.key === targetKey) {
        if (forceVisible !== null && forceVisible !== undefined)
          field.visible = forceVisible;
        else if (behavioursDisplayed.hasOwnProperty('visible'))
          field.visible = behavioursDisplayed['visible'];

        if (forceDisabled !== null && forceDisabled !== undefined)
          field.disabled = forceDisabled;
        else if (behavioursDisplayed.hasOwnProperty('disabled'))
          field.disabled = behavioursDisplayed['disabled'];

        if (behavioursDisplayed.hasOwnProperty('mandatory'))
          field.mandatory = behavioursDisplayed['mandatory'];
      }
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector
    );

    setFieldsConfig(componentId, newFieldsConfig);
  };

  /**
   * This function will look into each field, and modify its params or not depending on the condition we are introducing
   * @param {Array} fieldsConfig - Array of groups, subgroups and fields which would be renderer on the Component
   * @param {function} fieldsConfigBehaviourSelector - Condition which will change or not current field
   * @return {Array} - New fieldsConfig
   */
  changeFieldsConfig = (fieldsConfig, fieldsConfigBehaviourSelector) => {
    fieldsConfig.forEach(field => {
      field.subgroupRows.forEach(subgroupRow => {
        subgroupRow.forEach(subgroup => {
          subgroup.fieldRow.forEach(fieldRow => {
            fieldRow.forEach(field => {
              if (fieldsConfigBehaviourSelector(field))
                field = fieldsConfigBehaviourSelector(field);
            });
          });
        });
      });
    });
    return fieldsConfig;
  };

  render() {
    const { fieldsConfig, globalSearchField } = this.props;
    if (!isEmpty(fieldsConfig))
      return (
        <SearchFormRender {...this} globalSearchField={globalSearchField} />
      );
    return null;
  }
}

SearchForm.propTypes = {
  targetId: PropTypes.string,
  fieldsConfig: PropTypes.array,
  values: PropTypes.object,
  filters: PropTypes.string,
  combos: PropTypes.object,
  params: PropTypes.object,
  fields: PropTypes.array,
  options: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    filterVisible: state.searches[ownProps.params.componentId].filterVisible,
    targetId: state.searches[ownProps.params.componentId].targetId,
    getDataTrigger: state.searches[ownProps.params.componentId].getDataTrigger,
    resetFormTrigger:
      state.searches[ownProps.params.componentId].resetFormTrigger,
    hasNavigated: state.query.searchFormNavigation,
    queryParams: state.query.params,
    getSelected: state.m2m.getSelected,
    formHasChanged: state.searches[ownProps.params.componentId].formHasChanged,
    routerKey: state.router.location.key,
    fieldsConfig: state.searches[ownProps.params.componentId].fieldsConfig,
    globalSearchField:
      state.searches[ownProps.params.componentId].globalSearchField,
    values: state.searches[ownProps.params.componentId].values,
    m2mIsLoading: state.m2m.isLoading,
    selectedRow:
      state.tables[components[ownProps.params.componentId].targetId]
        .selectedRow,
    filters:
      state.tables[components[ownProps.params.componentId].targetId].filters,
    combos: state.combos,
    isLoading:
      state.tables[components[ownProps.params.componentId].targetId].isLoading,
    errors: state.app.errors,
    userPermissions: state.app.permissions,
    breadcrumbs: state.query.breadcrumbs
  };
};

SearchForm = injectIntl(SearchForm);
const WrappedSearchForm = Form.create()(SearchForm);

export default connect(mapStateToProps, {
  setTableLoadData,
  setFieldsConfig,
  setInitialState,
  setSearchValues,
  getTableData,
  setSelectedRow,
  getComboData,
  feedback: feedbackController,
  logout,
  editNatigationState,
  setTriggerGetTableData,
  resetSearchForm,
  setFormHasChanged,
  setDrawerVisibility,
  setResetFormTrigger,
  setFilterVisibility,
  setSelectedTabSearch,
  setSelectedTab,
  cleanBreadcrumb
})(WrappedSearchForm);
