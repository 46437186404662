import qs from 'query-string';

import * as types from './authTypes';
import config from '../config';
import * as api from '../api';
import apiPaths from '../apiPaths';
import { saveState } from '../utils/localStorage';
import { feedback } from '../utils/feedback';
import * as appTypes from '../app/appTypes';

export const login = data => async dispatch => {
  dispatch({
    type: types.ENABLE_LOADING
  });
  if (config.SECURITY === 0) {
    dispatch({
      type: types.AUTH_USER
    });
  } else if (config.SECURITY === 1 && data) {
    dispatch({
      type: types.AUTH_USER
    });
    const status = { action: 'fetch', status: 200 }; // fake response
    return status;
  } else if (config.SECURITY === 2 && data) {
    data.grant_type = 'password';
    data = qs.stringify(data);
    try {
      const response = await api.loginCall({
        dataPath: apiPaths.AUTH.LOGIN,
        data
      });
      const refreshToken = response.data.refresh_token;
      const accessToken = 'Bearer ' + response.data.access_token;
      saveState({
        auth: {
          isAuthorized: true,
          accessToken,
          refreshToken
        }
      });
      dispatch({
        type: types.SET_TOKENS,
        payload: { refreshToken, accessToken }
      });
      dispatch({
        type: types.AUTH_USER
      });
      dispatch({
        type: types.DISABLE_LOADING
      });
      const status = { action: 'login', status: response.status };
      return status;
    } catch (err) {
      dispatch({
        type: types.DISABLE_LOADING
      });
      if (!err.response) return { action: 'login', status: {} };
      const status = {
        action: 'login',
        status: err.response.status,
        message: err.response.data.error_description
      };
      return status;
    }
  }
};

export const logout = () => async (dispatch, getState) => {
  switch (config.SECURITY) {
    case 0:
      break;
    case 1:
      dispatch({ type: types.UNAUTH_USER });
      break;
    case 2:
      dispatch({ type: types.REMOVE_TOKEN });
      dispatch({ type: appTypes.APP_REMOVE_STORE });

      //TODO - Se deberia hacer una llamada a la api Logout()?

      saveState({
        auth: {
          isAuthorized: false,
          accessToken: '',
          refreshToken: ''
        }
      });
      break;
    default:
      break;
  }
};

export const passwordRecovery = data => async dispatch => {
  try {
    const response = await api.recoveryPasswordCall({
      dataPath: apiPaths.AUTH.RECOVERY,
      data: data
    });
    return feedback({
      type: 'modal',
      action: 'recovery',
      status: response.status,
      title: 'recovery.confirm',
      message: 'recovery.msg'
    });
  } catch (err) {
    return feedback({
      type: 'notification',
      action: 'recovery',
      method: 'error',
      status: err.response.status,
      title: 'error.title',
      message: 'recovery.error'
    });
  }
};

export const passwordUpdate = data => async dispatch => {
  try {
    const response = await api.updatePasswordCall({
      dataPath: apiPaths.AUTH.UPDATE,
      data: data
    });
    dispatch(setModal({ visible: true }));
    return response;
  } catch (err) {
    dispatch(setModal({ visible: false }));
    return feedback({
      type: 'notification',
      action: 'update.password',
      method: 'error',
      status: err.response.status,
      title: 'error.title',
      message: 'update.error.token'
    });
  }
};

export const setModal = ({ visible }) => dispatch => {
  dispatch({
    type: types.SUCCESS_MODAL,
    payload: { visible }
  });
};
