export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export const QUERY_SET_PARAMS = '[QUERY] Set query parameters';
export const QUERY_PUSH_BREADCRUMB = '[QUERY] Push a new breadcrumb';
export const QUERY_GO_BACK = '[QUERY] Going back to a previous breadcrumb';
export const QUERY_SAVE_BREADCRUMBS =
  '[QUERY] Save breadcrumbs to local storage';
export const QUERY_SET_NAVIGATION = '[QUERY] Set navigation params';
export const QUERY_SET_COMPONENT_NAVIGATION =
  '[QUERY] Set component navigation';
export const QUERY_SET_INITIAL_PARAMS = '[QUERY] Set initial query params';
export const QUERY_LOAD_QUERYPARAMS = '[QUERY] Load initial query params';
