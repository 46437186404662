import * as types from './editTypes';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_INITIAL_EDIT_STATE:
      return {
        ...state,
        [action.payload.componentId]: {}
      };
    case types.EDIT_INITIAL_STATE:
      return {
        ...state,
        [action.payload.componentId]: {
          targetId: action.payload.targetId,
          fieldsConfig: action.payload.fieldsConfig,
          values: action.payload.values,
          selectedTab: action.payload.selectedTab,
          resetFormTrigger: action.payload.resetFormTrigger,
          parentData: {}
        }
      };
    case types.EDIT_RESET_COMPONENT:
      return {
        ...state,
        [action.payload.componentId]: {}
      };
    case types.EDIT_SET_VALUES:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          values: action.payload.values
        }
      };
    case types.EDIT_CLEAN_RESET_FORM_TRIGGER:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case types.EDIT_RESET_FORM:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          values: action.payload.values,
          fieldsConfig: action.payload.fieldsConfig,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case types.EDIT_FETCHING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case types.EDIT_FETCHED_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case types.EDIT_SENDING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case types.EDIT_SENT_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case types.EDIT_CREATING_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case types.EDIT_CREATED_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: action.payload.isLoading
        }
      };
    case types.EDIT_CHANGE_TAB:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedTab: action.payload.selectedTab
        }
      };
    case types.EDIT_SENDING_DATA_ERROR:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          isLoading: false
        }
      };

    case types.EDIT_SET_FIELDSCONFIG:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          fieldsConfig: action.payload.newFieldsConfig,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case types.EDIT_FETCH_PARENT_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          parentData: action.payload.data
        }
      };

    default:
      return state;
  }
}
