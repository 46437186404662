import * as types from './comboTypes';
import * as api from '../api';

export const initializeComponentCombos = ({ componentId }) => dispatch => {
  dispatch({
    type: types.COMBO_INITIALIZE_COMPONENT,
    payload: { componentId }
  });
};

export const getComboData = ({
  dataPath,
  componentId,
  fieldKey,
  queryParams = {},
  comboId
}) => async dispatch => {
  dispatch({
    type: types.COMBO_INITIALIZE_COMBO,
    payload: { componentId, fieldKey }
  });
  dispatch({
    type: types.COMBO_FETCHING_DATA,
    payload: { componentId, fieldKey, comboId, isLoading: true }
  });
  const callConfig = {
    params: queryParams
  };
  try {
    const response = await api.getDataCall({ dataPath, callConfig });
    if (response.data)
      dispatch({
        type: types.COMBO_FETCHED_DATA,
        payload: {
          componentId,
          fieldKey,
          comboId,
          data: response.data,
          isLoading: false
        }
      });
    const status = { action: 'fetch', status: response.status };
    return status;
  } catch (err) {
    if (!err.response) return { action: 'fetch', status: {} };
    const status = { action: 'fetch', status: err.response.status };
    return status;
  }
};

export const addCustomCombos = data => dispatch => {
  dispatch({
    type: types.COMBO_ADD_CUSTOM,
    payload: { data }
  });
};
