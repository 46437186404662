import React from 'react';

export default function defaultIcon() {
  return (
    <>
      <svg viewBox="0 0 64 64">
        <path d="M38 2v8m16 8h-8m-8-4v4h4"></path>
        <path d="M16 2h-4v4m0 4v8m0 4v8m0 4v8m0 4v8m42-18v8m0-20v8m0 16v8m-42 2v4h4m4 0h8m4 0h8m4 0h10v-2m0-40v-2l-4-4m-2.7-2.7l-5-5m-2.8-2.8L38 2h-6M20 2h8"></path>
      </svg>
    </>
  );
}
