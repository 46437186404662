const combosCustom = {
	  status: [
    {
      description: 'Todos',
      value: null
    },
    {
      description: 'Activo',
      value: 'true'
    },
    {
      description: 'Inactivo',
      value: 'false'
    }
  ],
  status_b: [
    {
      description: 'Activo',
      value: 'true'
    },
    {
      description: 'Inactivo',
      value: 'false'
    }
  ],
  corporate: [
    {
      description: 'Sí',
      value: 'true'
    },
    {
      description: 'No',
      value: 'false'
    }
  ]
};

export default combosCustom;
