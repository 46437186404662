import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import { setM2MModalVisibility, resetM2M } from './m2mActions';
import { getTableData } from '../tables/tableActions';
import M2MDashboard from './M2MDashboard';

class M2MEditButton extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { resetM2M, isM2MModalVisible } = this.props;
    if (prevProps.isM2MModalVisible !== isM2MModalVisible && !isM2MModalVisible)
      resetM2M();
  }

  handleChangeM2MModalVisibility = isVisible => {
    const {
      getTableData,
      setM2MModalVisibility,
      parentComponentId,
      dataPath
    } = this.props;
    setM2MModalVisibility(isVisible);
    getTableData({ dataPath, componentId: parentComponentId, queryParams: {} });
  };

  render() {
    const m2mModal = (
      <Modal
        visible={
          this.props.isM2MModalVisible &&
          this.props.selectedM2MDashboard === this.props.componentId
        }
        className="m2mModal"
        onCancel={() => this.handleChangeM2MModalVisibility(false)}
        style={{ paddingTop: '43px' }}
        closable
        destroyOnClose
        centered
        footer={null}
        width={1000}
      >
        <M2MDashboard {...this.props} isM2M={true} />
      </Modal>
    );

    return (
      <React.Fragment>
        <Button
          icon="folder-add"
          type="dashed"
          style={{ marginLeft: '10px' }}
          onClick={() => this.handleChangeM2MModalVisibility(true)}
          disabled={this.props.disabled}
        >
          Asociar
        </Button>
        {m2mModal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isM2MModalVisible: state.m2m.visible,
    selectedM2MDashboard: state.m2m.activeDashboard
  };
};

export default connect(
  mapStateToProps,
  {
    setM2MModalVisibility,
    resetM2M,
    getTableData
  }
)(M2MEditButton);
