import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Form, Input, Col, Button, Row, Result, Modal } from 'antd';
import { connect } from 'react-redux';
import { passwordUpdate } from '../auth/authActions';
import './UpdatePassword.css';

const FormItem = Form.Item;

class UpdatePassword extends Component {
  state = {
    confirmValue: false
  };

  compareToFirstPassword = (rule, values, callback) => {
    const { form, intl } = this.props;
    if (values && values !== form.getFieldValue('password')) {
      callback(intl.formatMessage({ id: 'password.inconsistent' }));
    } else {
      callback();
    }
  };

  handleValidate = (rule, values, callback) => {
    const { form } = this.props;
    if (values && this.state.confirmValue) {
      form.validateFields(['confirm'], { force: true });
    } else {
      callback();
    }
  };

  handleConfirmBlur = values => {
    this.setState({ confirmValue: this.state.confirmValue || !!values });
  };

  handleConfirmPassword = () => {
    const { form, passwordUpdate, queryParams } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const token = queryParams;
        const password = values;
        const data = {
          ...token,
          ...password
        };
        if (values.hasOwnProperty('confirm')) delete values.confirm;
        passwordUpdate(data);
      }
    });
  };

  render() {
    const { intl, form, visible, queryParams } = this.props;
    const { getFieldDecorator } = form;

    const modalConfirm = (
      <Modal
        visible={visible}
        footer={null}
        closable={null}
        centered
        className="modalConfirm"
      >
        <Result
          status="success"
          className="boxSuccess"
          title={intl.formatMessage({ id: 'password.update' })}
          extra={[
            <Button type="primary" href="/login">
              {intl.formatMessage({ id: 'login.msg' })}
            </Button>
          ]}
        />
      </Modal>
    );

    return !queryParams.token ? (
      <Redirect to={{ pathname: '/login' }} />
    ) : (
      <Row span={10} className="updatePassword">
        <Col>
          <div className="updatePassword__logo" />
          <Col className="updatePassword__boxContent">
            <Form>
              <label className="label--text">
                {intl.formatMessage({ id: 'password.recovery' })}
              </label>
              <FormItem
                label={intl.formatMessage({ id: 'password.password' })}
                colon={false}
                hasFeedback
              >
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'password.confirm'
                      }),
                      validator: this.handleValidate
                    }
                  ]
                })(<Input.Password />)}
              </FormItem>
              <FormItem
                label={intl.formatMessage({ id: 'password.confirm' })}
                hasFeedback
              >
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'password.confirm.error'
                      })
                    },
                    {
                      validator: this.compareToFirstPassword
                    }
                  ]
                })(<Input.Password />)}
              </FormItem>
              <Button type="primary" onClick={this.handleConfirmPassword}>
                {intl.formatMessage({ id: 'password.submit' })}
              </Button>
              {modalConfirm}
            </Form>
          </Col>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    visible: state.auth.visible,
    queryParams: state.query.params
  };
};

UpdatePassword = Form.create()(UpdatePassword);
UpdatePassword = injectIntl(UpdatePassword);

export default connect(
  mapStateToProps,
  { passwordUpdate }
)(UpdatePassword);
