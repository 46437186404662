import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import appReducer from './app/appReducer';
import authReducer from './auth/authReducer';
import searchReducer from './forms/search/searchReducer';
import editReducer from './forms/edit/editReducer';
import tableReducer from './tables/tableReducer';
import m2mReducer from './m2m/m2mReducer';
import comboReducer from './combos/comboReducer';
import dashboardReducer from './dashboards/dashboardReducer';
import queryReducer from './app/queryReducer';

export default history =>
  combineReducers({
    app: appReducer,
    auth: authReducer,
    searches: searchReducer,
    tables: tableReducer,
    edits: editReducer,
    m2m: m2mReducer,
    combos: comboReducer,
    dashboards: dashboardReducer,
    router: connectRouter(history),
    query: queryReducer
  });
