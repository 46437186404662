import React from 'react';
import {
  Input,
  InputNumber,
  DatePicker,
  Select,
  TimePicker,
  Checkbox,
  Switch,
  Radio
} from 'antd';

import DocumentUploader from '../fields/DocumentUploader';
import Autocomplete from '../fields/Autocomplete';
import config from '../config';
import './FieldStyles.css';

const comboRender = (field, combos, componentId) => {
  const { type, key, comboId } = field;
  if (type === 'comboCustom') {
    return combos[comboId] && combos[comboId].data
      ? combos[comboId].data.map(option => {
          return (
            <Select.Option key={option.value} value={option.value}>
              {option.description}
            </Select.Option>
          );
        })
      : false;
  } else {
    return combos[componentId] &&
      combos[componentId][key] &&
      combos[componentId][key][comboId] &&
      combos[componentId][key][comboId].data
      ? combos[componentId][key][comboId].data.map(option => {
          return (
            <Select.Option key={option.value} value={option.value}>
              {option.description}
            </Select.Option>
          );
        })
      : false;
  }
};

const radioRender = (field, combos, componentId) => {
  const { key, comboId } = field;
  return combos[componentId] &&
    combos[componentId][key] &&
    combos[componentId][key][comboId] &&
    combos[componentId][key][comboId].data
    ? combos[componentId][key][comboId].data.map(option => {
        return { label: option.description, value: option.value };
      })
    : null;
};

export default function Fields(parentProps) {
  const {
    props,
    handleChangeField,
    handleResourceError,
    handleGoogleInput,
    field
  } = parentProps;
  const {
    intl,
    options,
    values,
    combos,
    params,
    selectedRow,
    form,
    userPermissions,
    setEditData,
    targetId,
    setFormStateFlag
  } = props;
  const { componentId, entity } = params;
  const {
    forceDisabled,
    isParentDisabled,
    forceBehaviours,
    paramsDisabled
  } = field;
  let disabled;
  let isFieldDisabled =
    isParentDisabled !== undefined
      ? isParentDisabled
      : field.disabled !== undefined
      ? field.disabled
      : undefined;
  if (forceBehaviours) {
    if (forceDisabled && isFieldDisabled) disabled = true;
    else disabled = false;
  } else
    disabled =
      forceDisabled !== undefined
        ? forceDisabled
        : isFieldDisabled !== undefined
        ? isFieldDisabled
        : undefined;

  //check combo dependiente
  if (field.type === 'combo' && field.hasOwnProperty('parentValue')) {
    if (field.disabled === true) disabled = true;
  }

  if (field.alwaysDisabled) disabled = field.alwaysDisabled;
  //TODO add capa custom disabled behaviours
  else if (
    field.isPermissionMandatory &&
    userPermissions.includes(field.permissions[0].name)
  )
    disabled = field.permissions[0].disabled;

  if (paramsDisabled) {
    if (
      params.type === 'search' ||
      (params.type === 'edit' &&
        config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD)
    )
      disabled = paramsDisabled;
  }

  switch (field.type) {
    case 'advancedSearch':
      return Autocomplete({ ...parentProps, comboRender });
    case 'text':
    case 'email':
      return (
        <Input
          type={field.type}
          title={field.title}
          size={options.fieldSize}
          disabled={disabled}
          maxLength={field.length}
          onBlur={e =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value
            })
          }
        />
      );
    case 'textarea':
      console.log(field)
      return (
        <Input.TextArea
          title={field.title}
          disabled={disabled}
          style={{ paddingTop: '3px' }}
          autosize={{
            minRows: 5,
            maxRows: 6
          }}
          maxLength={field.length}
          onBlur={e =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value
            })
          }
        />
      );
    case 'number':
      return (
        <InputNumber
          // placeholder={field.title}
          title={field.title}
          size={options.fieldSize}
          precision={field.precision || 0}
          step={field.step}
          min={field.min}
          disabled={disabled}
          onBlur={e =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value
            })
          }
        />
      );
    case 'currency':
      return (
        <Input
          // placeholder={field.title}
          type="number"
          addonAfter="€"
          title={field.title}
          size={options.fieldSize}
          step={field.step || 0.01}
          precision={field.precision || 2}
          min={field.min}
          disabled={disabled}
          onBlur={e =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value
            })
          }
        />
      );
    case 'percent':
      return (
        <Input
          type="number"
          // placeholder={field.title}
          addonAfter="%"
          title={field.title}
          size={options.fieldSize}
          step={field.step || 0.01}
          max={100}
          precision={field.precision || 2}
          min={field.min}
          disabled={disabled}
          onBlur={e =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value
            })
          }
        />
      );
    case 'comboCustom':
    case 'combo':
      return (
        <Select
          showSearch
          optionFilterProp="children"
          // allowClear={!field.hasOwnProperty('initialValue')}
          dropdownMatchSelectWidth={false}
          allowClear
          size={options.fieldSize}
          disabled={field.type === 'advancedSearch' ? true : disabled}
          loading={
            field.comboId === 'status' ||
            field.comboId === 'status_b' ||
            field.comboId === 'corporate'
              ? combos[field.comboId] && combos[field.comboId].isLoading
              : combos[componentId] &&
                combos[componentId][field.key] &&
                combos[componentId][field.key][field.comboId] &&
                combos[componentId][field.key][field.comboId].isLoading
          }
          placeholder={intl.formatMessage({ id: 'checkbox.all' })}
          notFoundContent={intl.formatMessage({
            id: 'combo.data.notfound'
          })}
          filterOption={(input, option) => {
            if (option.props.children !== null) {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }
          }}
          onChange={(value, data) => {
            handleChangeField({
              type: field.type,
              id: field.key,
              path: field.path,
              value: value >= 0 || typeof value === 'string' ? data.key : null
            });
          }}
        >
          {comboRender(field, combos, componentId)}
        </Select>
      );
    case 'radio':
      return (
        <Radio.Group
          disabled={disabled}
          options={radioRender(field, combos, componentId)}
          onChange={e => {
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value
            });
          }}
        />
      );
    case 'time':
      return (
        <TimePicker
          title={field.title}
          // placeholder={field.title}
          format={field.displayFormat}
          size={options.fieldSize}
          disabled={disabled}
          onChange={date =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: date,
              format: field.format
            })
          }
        />
      );
    case 'date':
    case 'datetime':
      return (
        <DatePicker
          showTime={field.type === 'datetime'}
          title={field.title}
          // placeholder={field.title}
          //TODO eolo custom format
          // format={field.displayFormat}
          format="DD/MM/YYYY"
          size={options.fieldSize}
          disabled={disabled}
          onChange={date =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: date,
              format: field.format
            })
          }
        />
      );
    case 'rangerpicker':
      return (
        <DatePicker.RangePicker
          title={field.title}
          format={field.displayFormat}
          size={options.fieldSize}
          disabled={disabled}
          onChange={date => {
            handleChangeField({
              type: field.type,
              id: field.key,
              value: date,
              format: field.format
            });
          }}
        />
      );
    case 'check':
      return (
        <Checkbox
          className="form__checkbox"
          title={field.title}
          checked={values[field.key] || false}
          disabled={disabled}
          onChange={e => {
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.checked
            });
          }}
        >
          <span className="form__checkLabel">{field.title}</span>
        </Checkbox>
      );
    case 'checkSelect':
      return (
        <Select
          optionFilterProp="children"
          size={options.fieldSize}
          disabled={disabled}
          onChange={value => handleChangeField({ id: field.key, value })}
          placeholder={intl.formatMessage({ id: 'checkbox.all' })}
          allowClear={!field.hasOwnProperty('initialValue')}
        >
          <Select.Option key={field.key} value="true">
            {intl.formatMessage({ id: 'checkbox.true' })}
          </Select.Option>
          <Select.Option key={field.key} value="false">
            {intl.formatMessage({ id: 'checkbox.false' })}
          </Select.Option>
        </Select>
      );
    case 'switch':
      return (
        <Switch
          defaultChecked={field.checked}
          disabled={disabled}
          onChange={checked =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: checked
            })
          }
        />
      );
    // case 'imageUpload':
    //   return (
    //     <ImageUploader
    //       disabled={disabled}
    //       handleChangeField={handleChangeField}
    //       token={props.accessToken}
    //       componentId={field.key}
    //       // handleError={handleResourceError}
    //       handleError={() => console.error('No se ha podido cargar la imagen')}
    //       editable={field.editable}
    //       // ref={instance => uploadComponents.push(instance)}
    //     />
    //   );
    case 'imageUpload':
    case 'documentUpload':
      return DocumentUploader({
        disabled,
        field,
        values,
        handleChangeField,
        token: props.accessToken,
        handleResourceError,
        editable: field.editable,
        entity,
        intl,
        selectedRow,
        componentId,
        form,
        setEditData,
        targetId,
        setFormStateFlag
      });
    /*       case 'autocomplete':
        return (
          <Autocomplete
            handleChangeField={handleChangeField}
            componentId={field.key}
            path={field.autocompleteConfig.path}
            field={field}
            token={parentProps.props.accessToken}
            options={options}
            reset={parentProps.state.reset}
            parentValue={parentProps.state.autocompleteValue}
          />
        ); */
    case 'googleInput':
      const newType = 'search';
      return (
        <Input
          type={newType}
          disabled={disabled}
          title={field.title}
          size={options.fieldSize}
          onBlur={e =>
            handleGoogleInput({
              type: field.type,
              value: e.target.value,
              targets: field.targets
            })
          }
        />
      );
    default:
      return (
        <Input
          type={field.type || 'text'}
          title={field.title}
          size={options.fieldSize}
          disabled={disabled}
          maxLength={field.length}
          onBlur={e =>
            handleChangeField({
              type: field.type,
              id: field.key,
              value: e.target.value
            })
          }
        />
      );
  }
}
