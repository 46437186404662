import React, { useState } from 'react';
import { Button, Modal, Input, Form, Row } from 'antd';
import { isEmpty } from 'lodash';
import ModalRubricDefault from '../modalCreateEditRubricDefault';


export default function editRubricDefault(parentProps) {
  const [editVisible, setEditVisible] = useState(false);
  const createModal = (
    <Modal 
      visible={editVisible}
      onCancel={()=> setEditVisible(false)}
      closable
      centered
      footer={null}
      width={800}
    >
      <ModalRubricDefault 
        setCreateVisible={setEditVisible}
        edit={true}
        selectedRow={parentProps.props.selectedRow}
      />
    </Modal>
  );

  return (
    <div>
      <Button
        icon="file-add"
        type="primary"        
        onClick={() => setEditVisible(true)}
        disabled={isEmpty(parentProps.props.selectedRow)}
      >
        Editar rúbrica
      </Button>
      {createModal}
    </div>
  )

}
