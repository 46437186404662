import React from 'react';
import { Icon, Row, Col } from 'antd';

import './NotFound.css';

const NotFound = props => {
  const { intl } = props;
  return (
    <div className="notFound">
      <Row>
        <Col md={24}>
          <Icon className="notFound__icon" type="frown-o" />
          <div className="notFound__text">
            {intl.formatMessage({ id: 'page.notFound' })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NotFound;
