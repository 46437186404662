const components = {
  alumnoalumnoSearch: {
    type: 'search',
    path: '/alumno',
    targetId: 'alumnoalumnoList',
  },
  alumnoalumnoList: {
    type: 'table',
    path: '/alumno',
    targetsId: [],
  },
  alumnoalumnoEdit: {
    type: 'edit',
    path: '/alumno',
    targetId: 'alumnoalumnoList',
  },
  alumnoClasealumnoClaseSearch: {
    type: 'search',
    path: '/alumnoclase',
    targetId: 'alumnoClasealumnoClaseList',
  },
  alumnoClasealumnoClaseList: {
    type: 'table',
    path: '/alumnoclase',
    targetsId: [],
  },
  alumnoClasealumnoClaseEdit: {
    type: 'edit',
    path: '/alumnoclase',
    targetId: 'alumnoClasealumnoClaseList',
  },
  anyoLectivoanyoLectivoSearch: {
    type: 'search',
    path: '/anyolectivo',
    targetId: 'anyoLectivoanyoLectivoList',
  },
  anyoLectivoanyoLectivoList: {
    type: 'table',
    path: '/anyolectivo',
    targetsId: [],
  },
  anyoLectivoanyoLectivoEdit: {
    type: 'edit',
    path: '/anyolectivo',
    targetId: 'anyoLectivoanyoLectivoList',
  },
  appmenuappmenuSearch: {
    type: 'search',
    path: '/appmenu',
    targetId: 'appmenuappmenuList',
  },
  appmenuappmenuList: {
    type: 'table',
    path: '/appmenu',
    targetsId: [],
  },
  appmenuappmenuEdit: {
    type: 'edit',
    path: '/appmenu',
    targetId: 'appmenuappmenuList',
  },
  appparamsappparamsSearch: {
    type: 'search',
    path: '/appparams',
    targetId: 'appparamsappparamsList',
  },
  appparamsappparamsList: {
    type: 'table',
    path: '/appparams',
    targetsId: [],
  },
  appparamsappparamsEdit: {
    type: 'edit',
    path: '/appparams',
    targetId: 'appparamsappparamsList',
  },
  areaareaSearch: {
    type: 'search',
    path: '/area',
    targetId: 'areaareaList',
  },
  areaareaList: {
    type: 'table',
    path: '/area',
    targetsId: [{ id: 'areadimensionList', path: 'fkArea.id' }],
  },
  areaareaEdit: {
    type: 'edit',
    path: '/area',
    targetId: 'areaareaList',
  },
  areadimensionList: {
    type: 'table',
    path: '/dimension',
    targetsId: [],
  },
  claseclaseSearch: {
    type: 'search',
    path: '/clase',
    targetId: 'claseclaseList',
  },
  claseclaseList: {
    type: 'table',
    path: '/clase',
    targetsId: [],
  },
  claseclaseEdit: {
    type: 'edit',
    path: '/clase',
    targetId: 'claseclaseList',
  },
  codigonotacodigonotaSearch: {
    type: 'search',
    path: '/codigonota',
    targetId: 'codigonotacodigonotaList',
  },
  codigonotacodigonotaList: {
    type: 'table',
    path: '/codigonota',
    targetsId: [],
  },
  codigonotacodigonotaEdit: {
    type: 'edit',
    path: '/codigonota',
    targetId: 'codigonotacodigonotaList',
  },
  competenciacompetenciaSearch: {
    type: 'search',
    path: '/competencia',
    targetId: 'competenciacompetenciaList',
  },
  competenciacompetenciaList: {
    type: 'table',
    path: '/competencia',
    targetsId: [],
  },
  competenciacompetenciaEdit: {
    type: 'edit',
    path: '/competencia',
    targetId: 'competenciacompetenciaList',
  },
  cursocursoSearch: {
    type: 'search',
    path: '/curso',
    targetId: 'cursocursoList',
  },
  cursocursoList: {
    type: 'table',
    path: '/curso',
    targetsId: [],
  },
  cursocursoEdit: {
    type: 'edit',
    path: '/curso',
    targetId: 'cursocursoList',
  },
  dimensiondimensionSearch: {
    type: 'search',
    path: '/dimension',
    targetId: 'dimensiondimensionList',
  },
  dimensiondimensionList: {
    type: 'table',
    path: '/dimension',
    targetsId: [{ id: 'dimensioncompetenciaList', path: 'fkDimension.id' }],
  },
  dimensiondimensionEdit: {
    type: 'edit',
    path: '/dimension',
    targetId: 'dimensiondimensionList',
  },
  dimensioncompetenciaList: {
    type: 'table',
    path: '/competencia',
    targetsId: [],
  },
  educadoreducadorSearch: {
    type: 'search',
    path: '/educador',
    targetId: 'educadoreducadorList',
  },
  educadoreducadorList: {
    type: 'table',
    path: '/educador',
    targetsId: [{ id: 'educadorescuelaList', path: 'fkEducador.id' }],
  },
  educadoreducadorEdit: {
    type: 'edit',
    path: '/educador',
    targetId: 'educadoreducadorList',
  },
  educadorescuelaList: {
    type: 'table',
    path: '/escuela',
    targetsId: [],
  },
  escuelaescuelaSearch: {
    type: 'search',
    path: '/escuela',
    targetId: 'escuelaescuelaList',
  },
  escuelaescuelaList: {
    type: 'table',
    path: '/escuela',
    targetsId: [],
  },
  escuelaescuelaEdit: {
    type: 'edit',
    path: '/escuela',
    targetId: 'escuelaescuelaList',
  },
  escuelaLibroescuelaLibroSearch: {
    type: 'search',
    path: '/escuelalibro',
    targetId: 'escuelaLibroescuelaLibroList',
  },
  escuelaLibroescuelaLibroList: {
    type: 'table',
    path: '/escuelalibro',
    targetsId: [],
  },
  escuelaLibroescuelaLibroEdit: {
    type: 'edit',
    path: '/escuelalibro',
    targetId: 'escuelaLibroescuelaLibroList',
  },
  evaluacionevaluacionSearch: {
    type: 'search',
    path: '/evaluacion',
    targetId: 'evaluacionevaluacionList',
  },
  evaluacionevaluacionList: {
    type: 'table',
    path: '/evaluacion',
    targetsId: [{ id: 'evaluacionregistroList', path: 'fkEvaluacion.id' }],
  },
  evaluacionevaluacionEdit: {
    type: 'edit',
    path: '/evaluacion',
    targetId: 'evaluacionevaluacionList',
  },
  evaluacionregistroList: {
    type: 'table',
    path: '/registro',
    targetsId: [],
  },
  gradaciongradacionSearch: {
    type: 'search',
    path: '/gradacion',
    targetId: 'gradaciongradacionList',
  },
  gradaciongradacionList: {
    type: 'table',
    path: '/gradacion',
    targetsId: [],
  },
  gradaciongradacionEdit: {
    type: 'edit',
    path: '/gradacion',
    targetId: 'gradaciongradacionList',
  },
  indicadorindicadorSearch: {
    type: 'search',
    path: '/indicador',
    targetId: 'indicadorindicadorList',
  },
  indicadorindicadorList: {
    type: 'table',
    path: '/indicador',
    targetsId: [],
  },
  indicadorindicadorEdit: {
    type: 'edit',
    path: '/indicador',
    targetId: 'indicadorindicadorList',
  },
  indicadorCursoindicadorCursoSearch: {
    type: 'search',
    path: '/indicadorcurso',
    targetId: 'indicadorCursoindicadorCursoList',
  },
  indicadorCursoindicadorCursoList: {
    type: 'table',
    path: '/indicadorcurso',
    targetsId: [],
  },
  indicadorCursoindicadorCursoEdit: {
    type: 'edit',
    path: '/indicadorcurso',
    targetId: 'indicadorCursoindicadorCursoList',
  },
  librolibroSearch: {
    type: 'search',
    path: '/libro',
    targetId: 'librolibroList',
  },
  librolibroList: {
    type: 'table',
    path: '/libro',
    targetsId: [],
  },
  librolibroEdit: {
    type: 'edit',
    path: '/libro',
    targetId: 'librolibroList',
  },
  licencialicenciaSearch: {
    type: 'search',
    path: '/licencia',
    targetId: 'licencialicenciaList',
  },
  licencialicenciaList: {
    type: 'table',
    path: '/licencia',
    targetsId: [],
  },
  licencialicenciaEdit: {
    type: 'edit',
    path: '/licencia',
    targetId: 'licencialicenciaList',
  },
  medianamedianaSearch: {
    type: 'search',
    path: '/mediana',
    targetId: 'medianamedianaList',
  },
  medianamedianaList: {
    type: 'table',
    path: '/mediana',
    targetsId: [],
  },
  medianamedianaEdit: {
    type: 'edit',
    path: '/mediana',
    targetId: 'medianamedianaList',
  },
  modalidadmodalidadSearch: {
    type: 'search',
    path: '/modalidad',
    targetId: 'modalidadmodalidadList',
  },
  modalidadmodalidadList: {
    type: 'table',
    path: '/modalidad',
    targetsId: [
      { id: 'modalidadcursoList', path: 'fkModalidad.id' },
      { id: 'modalidadareaList', path: 'fkModalidad.id' },
    ],
  },
  modalidadmodalidadEdit: {
    type: 'edit',
    path: '/modalidad',
    targetId: 'modalidadmodalidadList',
  },
  modalidadcursoList: {
    type: 'table',
    path: '/curso',
    targetsId: [],
  },
  modalidadareaList: {
    type: 'table',
    path: '/area',
    targetsId: [],
  },
  notanotaSearch: {
    type: 'search',
    path: '/nota',
    targetId: 'notanotaList',
  },
  notanotaList: {
    type: 'table',
    path: '/nota',
    targetsId: [],
  },
  notanotaEdit: {
    type: 'edit',
    path: '/nota',
    targetId: 'notanotaList',
  },
  personalizacionGradacionpersonalizacionGradacionSearch: {
    type: 'search',
    path: '/personalizaciongradacion',
    targetId: 'personalizacionGradacionpersonalizacionGradacionList',
  },
  personalizacionGradacionpersonalizacionGradacionList: {
    type: 'table',
    path: '/personalizaciongradacion',
    targetsId: [],
  },
  personalizacionGradacionpersonalizacionGradacionEdit: {
    type: 'edit',
    path: '/personalizaciongradacion',
    targetId: 'personalizacionGradacionpersonalizacionGradacionList',
  },
  personalizacionIndicadorpersonalizacionIndicadorSearch: {
    type: 'search',
    path: '/personalizacionindicador',
    targetId: 'personalizacionIndicadorpersonalizacionIndicadorList',
  },
  personalizacionIndicadorpersonalizacionIndicadorList: {
    type: 'table',
    path: '/personalizacionindicador',
    targetsId: [],
  },
  personalizacionIndicadorpersonalizacionIndicadorEdit: {
    type: 'edit',
    path: '/personalizacionindicador',
    targetId: 'personalizacionIndicadorpersonalizacionIndicadorList',
  },
  ponderacionponderacionSearch: {
    type: 'search',
    path: '/ponderacion',
    targetId: 'ponderacionponderacionList',
  },
  ponderacionponderacionList: {
    type: 'table',
    path: '/ponderacion',
    targetsId: [],
  },
  ponderacionponderacionEdit: {
    type: 'edit',
    path: '/ponderacion',
    targetId: 'ponderacionponderacionList',
  },
  registroregistroSearch: {
    type: 'search',
    path: '/registro',
    targetId: 'registroregistroList',
  },
  registroregistroList: {
    type: 'table',
    path: '/registro',
    targetsId: [],
  },
  registroregistroEdit: {
    type: 'edit',
    path: '/registro',
    targetId: 'registroregistroList',
  },
  rubricapersonalizadarubricapersonalizadaSearch: {
    type: 'search',
    path: '/rubricapersonalizada',
    targetId: 'rubricapersonalizadarubricapersonalizadaList',
  },
  rubricapersonalizadarubricapersonalizadaList: {
    type: 'table',
    path: '/rubricapersonalizada',
    targetsId: [],
  },
  rubricapersonalizadarubricapersonalizadaEdit: {
    type: 'edit',
    path: '/rubricapersonalizada',
    targetId: 'rubricapersonalizadarubricapersonalizadaList',
  },
  rubricadefaultrubricadefaultSearch: {
    type: 'search',
    path: '/archivorubrica',
    targetId: 'rubricadefaultrubricadefaultList',
  },
  rubricadefaultrubricadefaultList: {
    type: 'table',
    path: '/archivorubrica',
    targetsId: [],
  },
  VideoGuiaVideoGuiaSearch: {
      type: 'search',
      path: 'videoguia',
      targetId: 'VideoGuiaVideoGuiaList'
  },
  VideoGuiaVideoGuiaList: {
    type: 'table',
    path: 'videoguia',
    targetId: []
  },
  VideoGuiaVideoGuiaEdit: {
    type: 'edit',
    path: 'videoguia',
    targetId: 'VideoGuiaVideoGuiaList'
  },
  VideoPromocionalSearch: {
    type: 'search',
    path: 'videopromocional/get',
    targetId: 'VideoPromocionalList'
  },
  VideoPromocionalList: {
    type: 'table',
    path: 'videopromocional/get',
    targetId: []
  },
  VideoPromocionalEdit: {
    type: 'edit',
    path: 'videopromocional',
    targetId: 'VideoPromocionalList'
  }
};

export default components;
