import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Dropdown, Menu, Avatar, Layout } from 'antd';
import { injectIntl } from 'react-intl';

import config from '../config';
import { logout } from '../auth/authActions';
import { feedback, T_MODAL, M_CONFIRM } from '../utils/feedback';
import './HeaderContent.css';

class HeaderContent extends Component {
  handleLogout = () => {
    const { logout } = this.props;
    const modalProps = {
      onOk: logout
    };
    feedback({
      type: T_MODAL,
      title: 'logout.confirm.title',
      content: 'logout.confirm.message',
      method: M_CONFIRM,
      modalProps
    });
  };

  render() {
    const { intl, userData } = this.props;
    const userMenuOptions = (
      <Menu>
        <Menu.Item onClick={this.handleLogout}>
          <Icon type="logout" />
          <span>{intl.formatMessage({ id: 'logout.title' })}</span>
        </Menu.Item>
      </Menu>
    );

    const firstLetterName = userData.name && { ...userData.name }[0].toUpperCase();
    const firstLetterSurname =
      userData.surname && { ...userData.surname }[0].toUpperCase();

    const avatar = config.HEADER.AVATAR_SHOW && (
      <Avatar size="default" className="configUser__avatar">
        {firstLetterName}
        {firstLetterSurname}
      </Avatar>
    );

    const userElements = config.SECURITY !== 0 && (
      <Dropdown
        overlay={userMenuOptions}
        placement="bottomRight"
        trigger={['click']}
      >
        <div className="configUser">
          {avatar}
          <span className="configUser__user">
            {userData.name} {userData.surname}
          </span>
          <Icon type="down" />
        </div>
      </Dropdown>
    );

    return (
      <Layout.Header className="header">
        <div className="header__menuRight">{userElements}</div>
      </Layout.Header>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userData: state.app.user
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    { logout }
  )(HeaderContent)
);
