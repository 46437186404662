import * as appTypes from './appTypes';
import config from '../config';

const initialState = {
  locale: config.LOCALE.DEFAULT,
  menu: null,
  menuState: 2,
  permissions: null,
  appParams: null,
  user: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case appTypes.APP_SET_LANGUAGE:
      return {
        ...state,
        locale: action.payload
      };
    case appTypes.APP_ACCESS_DATA:
      return {
        ...state,
        ...action.payload
      };
    case appTypes.APP_MENU_STATE:
      return {
        ...state,
        menuState: action.payload.menuState
      };
    case appTypes.APP_REMOVE_STORE:
      return {
        ...state,
        permissions: null,
        appParams: null,
        user: null,
        menu: null
      };
    case appTypes.ROUTER_LOCATION_CHANGE:
      const { pathname } = action.payload.location;
      let { menuState } = state;

      if (pathname === '/Home') {
        menuState = 0;
      } else if (menuState === 0) {
        menuState = 1;
      }

      return {
        ...state,
        menuState
      };
    default:
      return state;
  }
}
