import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty, clone } from 'lodash';
import SmartTableRender from './SmartTableRender';
import './SmartTable.css';

import config from '../config';
import * as api from '../api';
// import { DragableHeaderCell } from './DragableColumn';
import {
  setInitialState,
  setColumnsConfig,
  getTableData,
  deleteTableData,
  setSelectedRows,
  updateTableRecord,
  setFormStateFlag,
  displayImageModal,
  loadImageModal,
  resetTableData,
  setChildSelectedRow,
  setLoadData,
  setInitialTableParams,
  resetTableContent
} from './tableActions';
import { setEditData } from '../forms/edit/editActions';
import {
  navigate,
  returnToParent,
  setBreadcrumbsChild
} from '../app/queryActions';
import { setDrawerVisibility } from '../dashboards/dashboardActions';
import { logout } from '../auth/authActions';
import { feedback, feedbackController } from '../utils/feedback';
import components from '../components.js';
import { loadConfig, saveConfig } from '../utils/localStorage';
import configDev from '../configDev';
import './SmartTable.css';

let imageModal, imageModalTitle;

class SmartTable extends Component {
  constructor(props) {
    super(props);
    const { params, fields, setInitialTableParams } = this.props;
    const { componentId } = params;
    let tableParams = {};

    fields.forEach(col => {
      if (
        col.hasOwnProperty('defaultSortOrder') &&
        !isEmpty(col.defaultSortOrder)
      ) {
        tableParams = {
          sort: col.defaultSortOrder === 'descend' ? 'desc' : 'asc',
          field: col.dataIndex
        };
      }
    });
    setInitialTableParams({
      componentId,
      sort: tableParams.sort,
      field: tableParams.field
    });
  }

  componentDidMount() {
    const {
      params,
      fields,
      pagination,
      columnsConfig,
      queryParams,
      getTableData,
      filters,
      setInitialState,
      loadData,
      setLoadData,
      isM2M,
      getM2MTableData,
      getSelected,
      path,
      dataPath,
      primaryKey,
      joinKey,
      mainKeyValue,
      foreignKey,
      navigationId,
      qParams,
      selectedRow,
      breadcrumbs,
      data
    } = this.props;
    const { componentId, isMainTable, prettierKey, rowKey } = params;

    let userConfig = loadConfig();
    let query = { q: {} };

    query = { ...queryParams };
    query = { ...qParams };
    fields.forEach(col => {
      if (
        col.hasOwnProperty('defaultSortOrder') &&
        !isEmpty(col.defaultSortOrder)
      ) {
        query = {
          ...query,
          sort: col.defaultSortOrder === 'descend' ? 'desc' : 'asc',
          field: col.dataIndex
        };
      }
    });

    let tableParams = { size: pagination.defaultPageSize };
    tableParams =
      isMainTable === false
        ? { ...queryParams }
        : isM2M
        ? tableParams
        : { ...query, ...tableParams };
    const tableData = isMainTable ? {} : data && !isEmpty(data) ? data : {};
    if (!columnsConfig) {
      const initialState = {
        componentId,
        targetsId: components[componentId].targetsId,
        queryParams: tableParams,
        navigationParams: qParams,
        rowKey,
        prettierKey: prettierKey,
        data: tableData,
        selectedRow: {},
        selectedRowKeys: []
      };

      if (
        configDev.SAVE_CONFIG &&
        userConfig !== undefined &&
        userConfig[config.USER.USERID] &&
        userConfig[config.USER.USERID][componentId] &&
        userConfig[config.USER.USERID][componentId]['columnsConfig']
      ) {
        setInitialState({
          ...initialState,
          columns: userConfig[config.USER.USERID][componentId]['columnsConfig']
        });
      } else {
        setInitialState({
          ...initialState,
          columns: fields
        });
        if (configDev.SAVE_CONFIG) {
          if (userConfig === undefined) userConfig = {};
          const newConfig = {
            ...userConfig,
            [config.USER.USERID]: {
              ...userConfig[config.USER.USERID],
              [componentId]: {
                ...userConfig[config.USER.USERID][componentId],
                columnsConfig: fields
              }
            }
          };
          saveConfig(newConfig);
        }
      }
    }

    if ((isMainTable === undefined || isMainTable) && loadData !== false) {
      //TODO FIX getTableData -->
      if (isM2M)
        getM2MTableData({
          m2mDataPath: path,
          dataPath,
          queryParams: tableParams,
          primaryKey,
          joinKey,
          mainKeyValue,
          foreignKey,
          getSelected,
          navigationId
        });
      else {
        const dataPath =
          (!selectedRow || isEmpty(selectedRow)) &&
          !isEmpty(breadcrumbs[breadcrumbs.length - 1].child)
            ? `${components[componentId].path}/Page/${
                breadcrumbs[breadcrumbs.length - 1].child.value
              }`
            : `${components[componentId].path}`;
        getTableData({
          dataPath,
          componentId,
          queryParams:
            qParams.q && !isEmpty(qParams.q)
              ? {
                  ...qParams,
                  q: filters
                    ? filters + config.QUERY.AND + qParams.q
                    : qParams.q
                }
              : qParams
        });
      }
    }
    if (!loadData) setLoadData({ componentId });
  }

  componentDidUpdate(prevProps) {
    const {
      selectedRow,
      resetTableData,
      targetsId,
      qParams,
      params,
      data,
      setSelectedRow,
      setChildSelectedRow,
      resetTableContent,
      routerKey,
      setBreadcrumbsChild,
      breadcrumbs,
      dashboardId,
      isM2M,
      isAdvancedSearch
    } = this.props;
    const { q } = qParams;
    const { rowKey, prettierKey, isMainTable, componentId, tableType } = params;
    if (
      q === undefined &&
      isMainTable !== false &&
      prevProps.qParams.q !== undefined
    ) {
      resetTableContent({ componentId });
    } else if (q === prevProps.qParams.q && routerKey !== prevProps.routerKey) {
      setSelectedRow({
        componentId,
        selectedRow: {}
      });
    }

    if (prevProps.selectedRow !== selectedRow && isEmpty(selectedRow)) {
      if (!isEmpty(targetsId)) {
        targetsId.forEach(targetId => {
          resetTableData({
            componentId: targetId.id
          });
        });
      }
    }

    if (isMainTable !== false && !isM2M && !isAdvancedSearch) {
      if (
        isEmpty(selectedRow) &&
        !isEmpty(breadcrumbs[breadcrumbs.length - 1].child)
      ) {
        if (isEmpty(data)) {
          //delete child
          //redirect al path del padre
        } else {
          data.content.forEach(row => {
            if (
              row[rowKey].toString() ===
              breadcrumbs[breadcrumbs.length - 1].child.value.toString()
            ) {
              setSelectedRow({ componentId, selectedRow: row });

              if (!isEmpty(targetsId)) {
                this.updateTargetTables({
                  targetsId,
                  selectedRow,
                  record: row,
                  rowKey
                });
                setChildSelectedRow({
                  targetsId,
                  record: row,
                  prettierKey,
                  rowKey
                });
              }
            }
          });
        }
      }
      //SET BREADCRUMBS child
      if (
        tableType === 0 &&
        prevProps.selectedRow !== selectedRow &&
        prevProps.selectedRow !== undefined
      )
        setBreadcrumbsChild({
          selectedRow,
          prettierKey,
          rowKey,
          dashboardId
        });
    }
  }

  onCellChange = (key, dataIndex) => value => {
    this.props.updateTableRecord({ id: key, field: dataIndex, value });
  };

  onSelectRow = record => {
    const {
      targetsId,
      params,
      selectedRow,
      setSelectedRow,
      setChildSelectedRow,
      isM2M,
      isAdvancedSearch,
      dashboardId,
      setBreadcrumbsChild
    } = this.props;
    const { componentId, rowKey, prettierKey } = params;

    if (!isM2M) {
      setSelectedRow({
        componentId,
        selectedRow: record[rowKey] === selectedRow[rowKey] ? {} : record
      });
      if (!isAdvancedSearch) {
        setBreadcrumbsChild({
          selectedRow: record[rowKey] === selectedRow[rowKey] ? {} : record,
          prettierKey,
          rowKey,
          dashboardId
        });
        if (!isEmpty(targetsId)) {
          this.updateTargetTables({ targetsId, selectedRow, record, rowKey });
          setChildSelectedRow({
            targetsId,
            record: record[rowKey] === selectedRow[rowKey] ? {} : record,
            prettierKey,
            rowKey
          });
        }
      }
    }
  };

  /**
   * Updates the data of the child tables with the id of the selectedRow
   * @param  {Object[]} targetsId - array of the child tables id
   * @param {Object} selectedRow - data of the selected row in main table
   * @param {Object} record - the clicked row of the main table that will be selected
   * @param {String} rowKey - the key of the rows of the main table
   */
  updateTargetTables = async ({ targetsId, record, rowKey, selectedRow }) => {
    const { getTableData, resetTableData, selectedComponent } = this.props;
    targetsId.forEach(async targetId => {
      if (components[targetId.id].type === 'table') {
        if (
          selectedComponent === targetId.id &&
          (!selectedRow || record[rowKey] !== selectedRow[rowKey])
        ) {
          let navigationExtensions;
          const secundaryComponents = this.props.components;
          secundaryComponents.forEach(component => {
            if (component.params.componentId === targetId.id) {
              navigationExtensions = component.params.navigationExtensions;
            }
          });

          let queryParams = { q: '' };

          if (navigationExtensions)
            navigationExtensions.forEach(element => {
              if (element.hasOwnProperty('value'))
                queryParams.q += `${element.key}:${element.value},`;
              else queryParams.q += `${element.key}:${record[element.key]},`;
            });

          queryParams.q += `${targetId.path}:${record[rowKey]}`;

          await getTableData({
            dataPath: components[targetId.id].path,
            componentId: targetId.id,
            queryParams
          });
        } else {
          resetTableData({
            componentId: targetId.id
          });
        }
      }
    });
  };

  onSelectMultipleRows = selectedRowKeys => {
    const { setSelectedRows, params } = this.props;
    const { componentId } = params;
    setSelectedRows({ componentId, selectedRowKeys });
  };

  handleSetM2MSwitch = async checked => {
    const {
      path,
      primaryKey,
      getM2MTableData,
      dataPath,
      mainKeyValue,
      foreignKey,
      joinKey,
      setGetSelected,
      navigationId
    } = this.props;

    await getM2MTableData({
      m2mDataPath: path,
      dataPath,
      queryParams: {},
      primaryKey,
      mainKeyValue,
      joinKey,
      foreignKey,
      getSelected: checked,
      navigationId
    });
    setGetSelected({ getSelected: checked });
  };

  onSelectMultipleRows2 = async (record, selected, selectedRows) => {
    const {
      isM2M,
      addM2Mrecord,
      removeM2Mrecord,
      path,
      primaryKey,
      getM2MTableData,
      dataPath,
      mainKeyValue,
      foreignKey,
      joinForeignKey,
      joinKey,
      getSelected,
      navigationId
    } = this.props;
    if (isM2M) {
      if (selected) {
        await addM2Mrecord({
          dataPath: path,
          foreignKey,
          record,
          mainKeyValue,
          joinForeignKey,
          joinKey
        });
        await getM2MTableData({
          m2mDataPath: path,
          dataPath,
          queryParams: {},
          primaryKey,
          mainKeyValue,
          joinKey,
          foreignKey,
          getSelected,
          navigationId
        });
      } else {
        await removeM2Mrecord({
          dataPath: path,
          record,
          m2mPrimaryKey: primaryKey
        });
        await getM2MTableData({
          m2mDataPath: path,
          dataPath,
          queryParams: {},
          primaryKey,
          mainKeyValue,
          joinKey,
          foreignKey,
          getSelected,
          navigationId
        });
      }
    }
  };

  onSelectAllRows = (selected, selectedRows) => {
    const { isM2M, params, setSelectedRows } = this.props;
    if (!selected && !isM2M) {
      const { componentId } = params;
      setSelectedRows({ componentId, selectedRows });
    }
  };

  handleTableChange = async (pagination, filters, sorter) => {
    const {
      isM2M,
      params,
      getTableData,
      columnsConfig,
      setColumnsConfig,
      joinKey,
      foreignKey,
      mainKeyValue,
      primaryKey,
      dataPath,
      path,
      getM2MTableData,
      getSelected,
      navigationId
    } = this.props;
    const { componentId } = params;
    const page = isEmpty(pagination) ? null : pagination.current - 1;
    const size = isEmpty(pagination) ? null : pagination.pageSize;
    let sort;
    if (!isEmpty(sorter)) {
      if (sorter.order === 'ascend') sort = 'asc';
      else if (sorter.order === 'descend') sort = 'desc';
      else sort = sorter.order;
    } else sort = null;

    const field = isEmpty(sorter) ? null : sorter.columnKey;
    let newColumnsConfig = columnsConfig.slice();
    newColumnsConfig.forEach(col => {
      if (col.hasOwnProperty('defaultSortOrder') && col.dataIndex !== field)
        delete col['defaultSortOrder'];
      if (col.dataIndex === field) col.defaultSortOrder = sorter.order;
    });
    setColumnsConfig({ componentId, columns: newColumnsConfig });
    const queryParams = {
      page,
      size,
      sort,
      field
    };

    isM2M
      ? await getM2MTableData({
          m2mDataPath: path,
          dataPath,
          queryParams,
          primaryKey,
          mainKeyValue,
          joinKey,
          foreignKey,
          getSelected,
          navigationId
        })
      : await getTableData({
          dataPath: components[componentId].path,
          componentId,
          queryParams
        });
  };

  handleReloadData = async () => {
    const {
      isM2M,
      params,
      getTableData,
      getM2MTableData,
      isMainTable
    } = this.props;
    const m2mProps = {
      m2mDataPath: this.props.path,
      dataPath: this.props.dataPath,
      queryParams: {},
      primaryKey: this.props.primaryKey,
      mainKeyValue: this.props.mainKeyValue,
      joinKey: this.props.joinKey,
      foreignKey: this.props.foreignKey,
      getSelected: this.props.getSelected,
      navigationId: this.props.navigationId
    };
    const { componentId } = params;
    isM2M
      ? await getM2MTableData({
          ...m2mProps
        })
      : await getTableData({
          dataPath: components[componentId].path,
          componentId,
          foreignFilters: undefined
        });
  };

  handleAddData = () => {
    const { params, setSelectedRow } = this.props;
    const { componentId } = params;
    setSelectedRow({
      componentId,
      selectedRow: {}
    });
  };

  // handleDeleteData = async () => {
  //   const { selectedRowKeys, deleteTableData, params } = this.props;
  //   const { componentId } = params;
  //   feedbackController({ action: 'delete', status: 'loading' });

  //   await deleteTableData({
  //     dataPath: components[componentId].path,
  //     componentId,
  //     selectedRowKeys
  //   });
  // };

  handleDeleteData = async () => {
    const { params, selectedRow, deleteTableData } = this.props;
    const { rowKey, componentId } = params;
    feedback({
      type: 'message',
      method: 'loading',
      message: 'generic.loading'
    });
    console.log(componentId,components);
    const result = await deleteTableData({
      dataPath: components[componentId].path,
      componentId,
      selectedRowKeys: [selectedRow[rowKey]],
      values: selectedRow,
      primaryKey: rowKey
    });
  };

  handleChangeDrawerVisibility = async visible => {
    const { dashboardId, setDrawerVisibility } = this.props;
    await setDrawerVisibility({ dashboardId, visible });
    return true;
  };

  handleOpenImage = async (value, title) => {
    const { displayImageModal, loadImageModal } = this.props;
    const imageUri = config.API.ROOT_URL + value;
    try {
      const res = await loadImageModal({ imageUri });
      imageModal = 'data:image/png;base64,' + res.image;
      displayImageModal(true, false);

      if (res.status !== 200) {
        throw Error('A problem with the image has been found');
      }
    } catch (err) {
      console.error('SmartTable:handleOpenImage -> ' + err);
      displayImageModal(false, false);
    }

    imageModalTitle = title;
  };

  handleCloseImage = () => {
    const { displayImageModal } = this.props;
    displayImageModal(false, false);
    imageModal = null;
  };

  handleOpenFile = async ({ fkFolder, docName }) => {
    let format;
    //TODO fkFolder y docName son válidos solo para la aplicacion de EOLO
    const callConfig = { params: { fkFolder, docName } };

    const response = await api.getDataCall({ dataPath: '/docs', callConfig });

    if (response.status === 200) {
      if (docName.indexOf('.') >= 0) {
        format = docName.split('.')[1];

        let mimeType;
        switch (format) {
          case 'pdf':
            mimeType = 'application/';
            break;
          case 'jpg':
          case 'jpeg:':
          case 'png':
            mimeType = 'image/';
            break;
          default:
            mimeType = 'application/';
            break;
        }
        if (mimeType) mimeType += format.toString();

        const link = document.createElement('a');
        link.setAttribute(
          'href',
          `data:${mimeType};base64,${response.data.file}`
        );
        link.setAttribute('download', docName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  showDeleteConfirm = () => {
    const { selectedRowKeys, intl } = this.props;
    Modal.confirm({
      title: intl.formatMessage({ id: 'pop.title.deleteMult' }),
      content: selectedRowKeys.map(k => {
        return `id: ${k}  `;
      }),
      okText: intl.formatMessage({ id: 'pop.accept' }),
      okType: 'danger',
      cancelText: intl.formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      confirmLoading: true,
      onOk: () => {
        this.handleDeleteData();
      },
      onCancel() {}
    });
  };

  moveColumn = (dragIndex, hoverIndex) => {
    let { columnsConfig, params, setColumnsConfig } = this.props;
    const { componentId } = params;
    const temp = columnsConfig[dragIndex];
    columnsConfig.splice(dragIndex, 1);
    columnsConfig.splice(hoverIndex, 0, temp);
    columnsConfig = clone(columnsConfig);
    columnsConfig.forEach((c, i) => {
      c.position = i;
    });
    setColumnsConfig({
      componentId,
      columns: columnsConfig
    });
  };

  onDragEnd = (fromIndex, toIndex) => {
    const { columnsConfig, params, setColumnsConfig } = this.props;
    const { componentId } = params;
    const columnsCopy = columnsConfig.slice();
    const item = columnsCopy.splice(fromIndex, 1)[0];
    columnsCopy.splice(toIndex, 0, item);
    setColumnsConfig({
      componentId,
      columns: columnsCopy
    });
  };

  handleResize = index => (e, { size }) => {
    const { columnsConfig, params, setColumnsConfig } = this.props;
    const { componentId } = params;
    const nextColumns = [...columnsConfig];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width
    };
    setColumnsConfig({
      componentId,
      columns: nextColumns
    });
  };

  onChangeColumnVisibility = e => {
    const { params, columnsConfig, setColumnsConfig } = this.props;
    const { componentId } = params;
    let columnsCopy = columnsConfig.map(c => {
      if (e.key === c.dataIndex) {
        c.visible = !c.visible;
      }
      return c;
    });
    setColumnsConfig({
      componentId,
      columns: columnsCopy
    });
  };

  tableTotalWidth = columns => {
    let totalWidth = 0;
    columns.forEach(column => {
      if (column.visible) totalWidth += column.width;
    });
    return totalWidth;
  };

  render() {
    const {
      data,
      selectedRowKeys,
      isM2M,
      m2mData,
      m2mIsLoading,
      m2mSelectedRowKeys
    } = this.props;
    let { isLoading } = this.props;

    let rowSelection = {
      selectedRowKeys: selectedRowKeys || [],
      onChange: this.onSelectMultipleRows,
      onSelectAll: this.onSelectAllRows,
      onSelect: this.onSelectMultipleRows2
    };

    let dataSource;

    if (isM2M) {
      dataSource = m2mData;
      rowSelection.selectedRowKeys = m2mSelectedRowKeys;
      isLoading = m2mIsLoading;
    } else {
      dataSource = data;
    }

    if (dataSource) {
      return (
        <SmartTableRender
          {...this}
          dataSource={dataSource}
          isLoading={isLoading}
        />
      );
    } else {
      return null;
    }
  }
}

SmartTable.propTypes = {
  params: PropTypes.object,
  fields: PropTypes.array,
  pagination: PropTypes.object,
  settings: PropTypes.object,
  data: PropTypes.object,
  selectedRowKeys: PropTypes.array,
  selectedRow: PropTypes.object,
  callParams: PropTypes.object,
  columnsConfig: PropTypes.array,
  filters: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    targetsId: state.tables[ownProps.params.componentId].targetsId,
    loadData: state.tables[ownProps.params.componentId].loadData,
    columnsConfig: state.tables[ownProps.params.componentId].columnsConfig,
    filters: state.tables[ownProps.params.componentId].filters,
    data: state.tables[ownProps.params.componentId].data,
    m2mData: state.m2m.data,
    m2mSelectedRowKeys: state.m2m.selectedRecordsId,
    m2mIsLoading: state.m2m.isLoading,
    getSelected: state.m2m.getSelected,
    selectedRowKeys: state.tables[ownProps.params.componentId].selectedRowKeys,
    selectedRow: state.tables[ownProps.params.componentId].selectedRow,
    parentSelectedRow:
      state.tables[ownProps.params.componentId].parentSelectedRow,
    parentPrettierKey:
      state.tables[ownProps.params.componentId].parentPrettierKey,
    parentRowKey: state.tables[ownProps.params.componentId].parentRowKey,
    callParams: state.tables[ownProps.params.componentId].callParams,
    formHasChanged: state.tables[ownProps.params.componentId].formHasChanged,
    isLoading: state.tables[ownProps.params.componentId].isLoading,
    queryParams: state.tables[ownProps.params.componentId].queryParams,
    visible: state.tables.imageModal.visible,
    layoutType: state.dashboards[ownProps.componentId].layoutType,
    imageIsLoading: state.tables.imageModal.isLoading,
    combos: state.combos,
    token: state.auth.accessToken,
    dashboards: state.dashboards,
    qParams: state.query.params,
    breadcrumbs: state.query.breadcrumbs,
    currentPath: state.router.location.pathname,
    selectedComponent: state.dashboards[ownProps.componentId].selectedComponent,
    routerKey: state.router.location.key
  };
};

SmartTable = injectIntl(SmartTable);
export default connect(mapStateToProps, {
  setInitialState,
  setColumnsConfig,
  getTableData,
  deleteTableData,
  setSelectedRows,
  updateTableRecord,
  setFormStateFlag,
  feedback: feedbackController,
  resetTableData,
  displayImageModal,
  loadImageModal,
  logout,
  setDrawerVisibility,
  navigate,
  setChildSelectedRow,
  setLoadData,
  setInitialTableParams,
  resetTableContent,
  returnToParent,
  setEditData,
  setBreadcrumbsChild
})(SmartTable);
