import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, Icon } from 'antd';
import { isEmpty } from 'lodash';
import { goBackToRoute, returnToParent, navigate } from '../app/queryActions';
import { setDrawerVisibility } from '../dashboards/dashboardActions';
import './SmartBreadcrumb.css';

export const BreadcrumbLabel = props => {
  const { name } = props;

  return <label>{name}</label>;
};

/**
 * It's the Breadcrumb component, it will save the elements on the menu we are navigating
 * and will only print the name with a link to the page.
 */
export class SmartBreadcrumb extends Component {
  render() {
    const { breadcrumbs, setDrawerVisibility } = this.props;
    let composedBreadcrumbs = [];

    breadcrumbs.forEach((bc, key) => {
      composedBreadcrumbs.push(
        <Breadcrumb.Item key={key}>
          <span
            className="breadcrumbs-link breadcrumbs-link__main-element"
            onClick={() => {
              this.props.goBackToRoute(bc.path, false);
            }}
          >
            {bc.name}
          </span>
        </Breadcrumb.Item>
      );

      if (!isEmpty(bc.child))
        composedBreadcrumbs.push(
          <Breadcrumb.Item key={bc.child.name}>
            <span
              className="breadcrumbs-link"
              onClick={() => {
                if (key < breadcrumbs.length - 1)
                  this.props.returnToParent({
                    breadcrumbs,
                    setDrawerVisibility,
                    key
                  });
              }}
            >
              {bc.child.name}
            </span>
          </Breadcrumb.Item>
        );
    });

    return (
      <div className="breadcrumb">
        <span className="breadcrumb__main--bold">
          {breadcrumbs[breadcrumbs.length - 1].name}
          <span className="breadcrumb__main--normal">|</span>
        </span>
        <span>
          <Icon
            type="home"
            className="breadcrumb__homeIcon"
            onClick={() => this.props.navigate({ path: '/' })}
          />
          <span style={{ padding: '0 6px 0 6px' }}>></span>
        </span>
        <Breadcrumb className="breadcrumb__chain" separator=">">
          {composedBreadcrumbs}
        </Breadcrumb>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { breadcrumbs } = state.query;

  return { breadcrumbs };
};

export default connect(mapStateToProps, {
  goBackToRoute,
  setDrawerVisibility,
  returnToParent,
  navigate
})(SmartBreadcrumb);
