import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import { Menu, Spin } from 'antd';
import IconMenu from '../iconMenu';

import './SideMenu.css';

const { Item, SubMenu } = Menu;

export class SideMenu extends React.Component {
  renderMenu = menu =>
    menu
      .filter(menuItem => menuItem.mustRender)
      .map(({ id, route, nameOfMenu, iconOfMenu, subMenu, disabled }) =>
        isEmpty(subMenu) ? (
          <Item
            key={route || nameOfMenu}
            disabled={disabled}
            onClick={() =>
              this.props.navigate({
                path: route,
                name: nameOfMenu,
                icon: iconOfMenu,
                absolute: true
              })
            }
          >
            <>
              <div className="anticon iconSideMenu">{IconMenu({ id })}</div>
              <span>{nameOfMenu}</span>
            </>
          </Item>
        ) : (
          <SubMenu
            key={route || nameOfMenu}
            disabled={disabled}
            title={
              <Fragment>
                <div className="anticon iconSideMenu">{IconMenu({ id })}</div>
                <span>{nameOfMenu}</span>
              </Fragment>
            }
          >
            {this.renderMenu(subMenu)}
          </SubMenu>
        )
      );

  render() {
    return (
      <Menu
        mode="inline"
        theme="dark"
        selectedKeys={[this.props.pathname]}
        defaultSelectedKeys={[this.props.pathname]}
      >
        {this.props.menu ? this.renderMenu(this.props.menu) : <Spin />}
        <div style={{ height: 45 }} />
      </Menu>
    );
  }
}

export default SideMenu;
