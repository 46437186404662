import { isEmpty } from 'lodash';
import config from '../config';

/**
 *
 * It allow go into inside the fields and replace the key
 * @param {*} fields
 * @param {*} values
 */
export const qComposer = (fields, values) => {
  if (!isEmpty(values)) {
    let qArray = [];
    let operator;
    let newValues = { ...values };
    // let rangerOperator = '';
    fields.forEach(f => {
      /**
       * in case rangepicker go through the key and if there more of one send a operator less|equal
       * so show the results
       */
      if (f.type === 'rangerpicker') {
        for (let key in newValues) {
          /*Go into loop*/
          if (key === f.key) {
            const index = newValues[key].indexOf('.');
            const firstDate = newValues[key].slice(0, index);
            const secondDate = newValues[key].slice(index + 1);
            qArray.push(key + '*' + firstDate);
            qArray.push(key + '$' + secondDate);
          }
        }
      } else if (
        !isEmpty(f.operand) &&
        values[f.key] !== null &&
        values[f.key] !== undefined &&
        values[f.key] !== ''
      ) {
        /*This section checks if there are multiple fields pointing to the same key (multiple API select)*/

        // operands
        switch (f.operand) {
          case 'like':
            operator = ':';
            break;
          case 'equal':
            operator = '::';
            break;
          case 'notequal': // TODO saber operador
            operator = '!';
            break;
          case 'greater':
            operator = '@';
            break;
          case 'less':
            operator = '-';
            break;
          case 'greater|equal': // TODO saber operador
            operator = '*';
            break;
          case 'less|equal': // TODO saber operador
            operator = '$';
            break;
          default:
            operator = ':';
        }
        qArray.push(f.key + operator + values[f.key]);
      }
    });
    if (values['textSearch'])
      qArray.push('textSearch' + ':' + values['textSearch']);
    return qArray.join(config.QUERY.AND);
  }
};

export const switchMenuState = (pathname, menuState) => {
  if (pathname === '/Home') {
    return 0;
  } else {
    switch (menuState) {
      case 0:
        return 1;
      case 1:
        return window.innerWidth < config.BREAKPOINTS.SM ? 0 : 2;
      case 2:
        return 1;
      default:
        return 1;
    }
  }
};

export const qInputComposer = (autocompleteConfig, value) => {
  const { fieldTargets } = autocompleteConfig;
  if (!isEmpty(value)) {
    let qArray = [];
    let operator;
    fieldTargets.forEach(target => {
      if (
        !isEmpty(autocompleteConfig.operand) &&
        value !== null &&
        value !== undefined &&
        value !== ''
      ) {
        // operands
        switch (autocompleteConfig.operand) {
          case 'like':
            operator = ':';
            break;
          case 'equal':
            operator = '::';
            break;
          case 'notequal': // TODO saber operador
            operator = '!';
            break;
          case 'greater':
            operator = '@';
            break;
          case 'less':
            operator = '-';
            break;
          case 'greater|equal': // TODO saber operador
            operator = '&';
            break;
          case 'less|equal': // TODO saber operador
            operator = '$';
            break;
          default:
            operator = ':';
        }
        qArray.push(target + operator + value);
      }
    });
    return qArray.join(config.QUERY.OR);
  }
};

export const filterComposer = columns => {
  return columns
    .filter(function(c) {
      return !isEmpty(c.filter);
    })
    .map(c => {
      return c.dataIndex + ':' + c.filter;
    })
    .join();
};

/**
 * Parse a JS File Object to a Base64 String representation
 * @param {File} file - File object.
 * @returns A promise that contains the String representation of the file or an error.
 */
export const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};
