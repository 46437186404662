import axios from 'axios';
import qs from 'query-string';
import config from './config';
import { loadState, saveState } from './utils/localStorage';
import apiPaths from './apiPaths';
import { feedback } from './utils/feedback';
import store from './store';
import * as authTypes from './auth/authTypes';
import * as appTypes from './app/appTypes';

axios.defaults.baseURL = config.API.ROOT_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const secureAxios = axios.create(axios.defaults);

const forceLogout = () => {
  store.dispatch({ type: authTypes.REMOVE_TOKEN });
  store.dispatch({ type: appTypes.APP_REMOVE_STORE });
  saveState({
    auth: {
      isAuthorized: false,
      accessToken: '',
      refreshToken: ''
    }
  });
};

const forceCheckWarningRequest = config => {
  const dataResponse = JSON.parse(config.data);
  if (dataResponse.hasOwnProperty('swCheckWarning')) {
    const newConfig = {
      ...config,
      data: { ...dataResponse, swCheckWarning: false }
    };
    return secureAxios({
      ...newConfig
    });
  }
};

const getRequestHeaders = accessToken =>
  config.SECURITY !== 2 || !accessToken
    ? {}
    : {
        common: {
          Authorization:
            accessToken.indexOf('Bearer') < 0
              ? `Bearer ${accessToken}`
              : accessToken,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

secureAxios.interceptors.request.use(req => {
  const requestType = req.method;
  if (requestType !== 'get')
    feedback(
      (req.feedback && req.feedback.loading) || {
        type: 'message',
        method: 'loading',
        message: 'generic.loading'
      }
    );
  const accessToken =
    config.SECURITY !== 2 ? undefined : loadState().auth.accessToken;
  return {
    ...req,
    headers: getRequestHeaders(accessToken)
  };
});

let refreshTokenPool = {
  requestCount: 0
};

secureAxios.interceptors.response.use(
  response => {
    const { config } = response;
    const requestType = config.method;
    if (requestType !== 'get')
      feedback(
        (config.feedback && config.feedback.success) || {
          type: 'message',
          method: 'success',
          message: 'generic.success',
          duration: 0.5
        }
      );
    return response;
  },
  async error => {
    const { status, data, config } = error.response;
    const isCheckWarning = status === 418 && data.code.toString()[0] === '2';
    if (isCheckWarning) {
      if (window.confirm(data.description)) {
        return forceCheckWarningRequest(config);
      } else {
        throw error;
      }
    } else if (status === 400) {
      if (
        error.response.data.error &&
        error.response.data.error !== 'invalid_grant'
      )
        feedback({
          type: 'notification',
          method: 'warning',
          title: 'error.title',
          message: data.description,
          duration: 10
        });
      throw error;
    } else if (status === 401) {
    } else if (status !== 401 || error.config.retry) {
      feedback({
        type: 'notification',
        method: data.message ? 'error' : 'warning',
        title: data.message ? 'error.title' : null,
        message: data.description || data.message,
        duration: 10
      });
      throw error;
    }
    try {
      if (!refreshTokenPool.promise) {
        refreshTokenPool.promise = refreshCall().then(response => {
          const { access_token, refresh_token } = response.data;
          const auth = {
            accessToken: access_token,
            refreshToken: refresh_token
          };
          saveState({ auth });
          return response;
        });
        refreshTokenPool.requestCount = 1;
      } else {
        refreshTokenPool.requestCount++;
      }
      const response = await refreshTokenPool.promise;
      const { access_token, refresh_token } = response.data;
      refreshTokenPool.requestCount--;
      if (refreshTokenPool.requestCount === 0) {
        refreshTokenPool.promise = null;
        store.dispatch({
          type: authTypes.SET_TOKENS,
          payload: { access_token, refresh_token }
        });
      }
      return secureAxios({
        ...error.response.config,
        retry: true,
        headers: getRequestHeaders(access_token)
      });
    } catch (e) {
      if (
        e.response.status &&
        e.response.status === 400 &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error === 'invalid_grant'
      ) {
        feedback({
          type: 'notification',
          method: 'error',
          title: 'error.title',
          message: 'server.session.expired',
          duration: 10
        });
        forceLogout(); // sustituir autologout por popup de infromación al usuario + botón de logout
        refreshTokenPool.requestCount = 0;
        refreshTokenPool.promise = null;
      }
      throw e;
    }
  }
);

export const accessCall = ({
  dataPath = apiPaths.ACCESS,
  user,
  callConfig = {}
}) => secureAxios.get(`${dataPath}?user=admin`, callConfig);//TODO remove 'admin' hardcode

export const loginCall = ({ dataPath = apiPaths.AUTH.LOGIN, data } = {}) =>
  axios.post(dataPath, data, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    auth: { username: config.CLIENT.USERNAME, password: config.CLIENT.PASSWORD }
  });

export const refreshCall = ({ dataPath = apiPaths.AUTH.LOGIN } = {}) => {
  let { refreshToken } = loadState().auth;
  const data = qs.stringify({
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  });
  return secureAxios.post(dataPath, data, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    auth: {
      username: config.CLIENT.USERNAME,
      password: config.CLIENT.PASSWORD
    }
  });
};
export const recoveryPasswordCall = ({
  dataPath,
  data,
  callConfig = {}
} = {}) => axios.post(dataPath, data, callConfig);

export const updatePasswordCall = ({ dataPath, data, callConfig = {} } = {}) =>
  axios.put(dataPath, data, callConfig);

export const logoutCall = ({ dataPath, callConfig = {} } = {}) =>
  secureAxios.get(dataPath, callConfig);

export const getDataCall = ({ dataPath, callConfig = {} } = {}) =>
  secureAxios.get(dataPath, callConfig);

export const getDataCallById = ({ dataPath, registerId, callConfig = {} }) =>
  secureAxios.get(`${dataPath}/${registerId}`, callConfig);

export const deleteDataCall = ({ dataPath, data, callConfig = {} }) =>
  secureAxios.delete(`${dataPath}`, { data }, callConfig);

export const deleteDataCallById = ({ dataPath, registerId, callConfig = {} }) =>
  secureAxios.delete(`${dataPath}/${registerId}`, callConfig);

export const putDataCall = ({ dataPath, data, callConfig = {} }) =>
  secureAxios.put(dataPath, data, callConfig);

export const postDataCall = ({ dataPath, data, callConfig = {} }) =>
  secureAxios.post(dataPath, data, callConfig);

export const restorePasswordCall = ({ dataPath, data, callConfig = {} } = {}) =>
  secureAxios.post(dataPath, data, callConfig);

export const resourceCall = ({ dataPath, callConfig = {} }) =>
  secureAxios.get(dataPath, callConfig);

export const openFileCall = async fileName => {
  const { STATIC_URL } = config.API;

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  anchor.target = '_blank';
  anchor.href = `${STATIC_URL}/uploads/${fileName}`;

  anchor.click();

  anchor.remove();
};
