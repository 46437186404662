export const TABLE_INITIAL_STATE = '[TABLE] set initial state';
export const TABLE_CONFIGURED_COLUMNS = '[TABLE] set configuration';
export const TABLE_FETCHING_DATA = '[TABLE] fetching data';
export const TABLE_FETCHED_DATA = '[TABLE] fetched data';
export const TABLE_CREATE_DATA = '[TABLE] create data';
export const TABLE_MODIFYING_DATA = '[TABLE] modifying the cell data';
export const TABLE_DELETING_DATA = '[TABLE] deleting data';
export const TABLE_REMOVE_EMPTY_ROW = '[TABLE] remove row';
export const TABLE_DELETED_DATA = '[TABLE] deleted data';
export const TABLE_SELECTED_ROWS = '[TABLE] row (multi) selected';
export const TABLE_SELECTED_ROW = '[TABLE] row (single) selected';
export const TABLE_SET_FORM_STATE_FLAG = '[TABLE] edit form has changed';
export const TABLE_FETCHED_IMAGE_MODAL = '[TABLE] Show/hide image modal';
export const TABLE_FETCHING_IMAGE_MODAL = '[TABLE] loading image modal';
export const TABLE_SEARCH_SENDING_DATA_ERROR =
  '[TABLE] seach sending data error';
export const SET_INITIAL_TABLE_STATE = '[TABLE] set initial component state';
export const RESET_TABLE_DATA = '[TABLE] reset component data';
export const TABLE_SET_CHILD_ROW = '[TABLE] Set child selectedRow';
export const TABLE_LOAD_DATA_TRIGGER = '[TABLE] Set loadData trigger';
export const TABLE_SET_LOAD_DATA = '[TABLE] Edit loadData';
export const TABLE_RESET_COMPONENT = '[TABLE] Reset table component';
export const TABLE_SET_INITIAL_PARAMS = '[TABLE] set initial table params';
export const TABLE_RESET_CONTENT = '[TABLE] Reset table content';
