import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import apiPaths from '../apiPaths';

const ClaseDashboard = (props) => {
  const components = [
    {
      params: {
        type: 'search',
        componentId: 'claseclaseSearch',
        panelHeader: 'Classe',
        panelKey: 'claseclaseSearch',
        yPosition: 0,
        xPosition: 0,
        width: 24,
        collapsible: true,
        collapsed: false,
        isTabItem: false,
        tabIndex: 0,
        tabTitle: '',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'fkAny.id',
                  yPosition: 2,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
                {
                  key: 'fkCurso.id',
                  yPosition: 2,
                  xPosition: 2,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Any',
          key: 'fkAny.id',
          type: 'combo',
          operand: 'like',
          comboId: 'anyo_lectivo',
          mandatory: false,
          disabled: false,
        },
        {
          title: 'Curs',
          key: 'fkCurso.id',
          type: 'combo',
          operand: 'like',
          comboId: 'curso',
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'claseclaseList',
        rowKey: 'id',
        panelHeader: 'Classe',
        panelKey: 'claseclaseList',
        navigationParams: props.query.params.q || '',
        yPosition: 1,
        xPosition: 0,
        width: 24,
        collapsible: true,
        collapsed: false,
        isTabItem: false,
        tabTitle: '',
        isMainTable: true,
        tabIndex: 0,
        tableType: 0,
      },
      fields: [
        {
          title: 'Id',
          dataIndex: 'id',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: 60,
        },
        {
          title: 'Nom',
          dataIndex: 'nombre',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
        },
        {
          title: 'Curs',
          dataIndex: 'fkCurso.nombre',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
        },
        {
          title: 'Escola',
          dataIndex: 'fkEscuela.nombre',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
        },
        {
          title: 'Any',
          dataIndex: 'fkAny.nombre',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'claseclaseEdit',
        panelHeader: 'Classe',
        panelKey: 'claseclaseEdit',
        primaryKey: 'id',
        yPosition: 2,
        xPosition: 0,
        width: 24,
        collapsible: true,
        collapsed: false,
        isTabItem: true,
        tabIndex: 0,
        tabTitle: 'Classe',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'nombre',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'fkCurso.id',
                  yPosition: 1,
                  xPosition: 2,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'fkAny.id',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 8,
                },
                {
                  key: 'fkEscuela.id',
                  yPosition: 1,
                  xPosition: 3,
                  mustRender: true,
                  width: 8,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: 'Nom',
          key: 'nombre',
          type: 'text',
          unique: false,
          validationType: 'text',
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Curs',
          key: 'fkCurso.id',
          type: 'combo',
          unique: false,
          comboId: 'curso',
          mandatory: true,
          disabled: false,
        },
        {
          title: 'Escola',
          key: 'fkEscuela.id',
          type: 'combo',
          unique: false,
          comboId: 'escuela',
          mandatory: false,
          disabled: false,
        },
        {
          title: 'Any',
          key: 'fkAny.id',
          type: 'combo',
          unique: false,
          comboId: 'anyo_lectivo',
          mandatory: true,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: false,
      },
    },
  ];

  const componentId = 'ClaseDashboard';

  return (
    <DashboardInitializer
      componentId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ClaseDashboard;
