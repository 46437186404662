import React, { useState } from 'react';
import { Resizable } from "react-resizable";

const ResizeableColumn = props => {
  const { onResize, width, onClick, ...restProps } = props;

  const [allowClick, setAllowClick] = useState(true);

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      onMouseDown={e => {
        setAllowClick(true);
      }}
      onResizeStart={e => {
        setAllowClick(false);
      }}
      onClick={e => allowClick && typeof onClick === 'function' && onClick(e)}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizeableColumn