import React, { Component } from 'react';
import { Table, Spin, Modal } from 'antd';
import SmartTableButtons from './SmartTableButtons';
import config from '../config';
import { isEmpty } from 'lodash';
import ColumnRender from './ColumnRender';
import ResizeableColumn from './ResizeableColumn';

import './SmartTable.css';

let imageModal, imageModalTitle;

class TableRender extends Component {
  components = {
    header: { cell: ResizeableColumn }
  };

  render() {
    const {
      dataSource,
      onSelectMultipleRows,
      onSelectAllRows,
      onSelectMultipleRows2,
      handleResize,
      handleOpenFile,
      handleOpenImage,
      tableTotalWidth,
      onSelectRow,
      handleChangeDrawerVisibility,
      handleTableChange,
      handleCloseImage,
      isLoading,
      props
    } = this.props;
    const {
      params,
      columnsConfig,
      pagination,
      settings,
      combos,
      selectedRowKeys,
      selectedRow,
      intl,
      visible,
      imageIsLoading,
      isM2M,
      m2mSelectedRowKeys,
      layoutType
    } = props;

    const { rowKey, componentId, isMainTable } = params;
    const { size, bordered, multiple } = settings;
    let rowSelection = {
      selectedRowKeys: isM2M ? m2mSelectedRowKeys || [] : selectedRowKeys || [],
      onChange: onSelectMultipleRows,
      onSelectAll: onSelectAllRows,
      onSelect: onSelectMultipleRows2
    };

    const selectAndOpen = async record => {
      handleChangeDrawerVisibility(true);
      onSelectRow(record);
    };

    const rowClassName = (record, index) => {
      if ((isMainTable === undefined || isMainTable) && !isM2M) {
        if (selectedRow[rowKey] === record[rowKey]) return 'row-selected';
        else if (config.COMPONENT.TABLE.STRIPED) return 'row-striped';
        else return '';
      } else if (isM2M) {
        return 'row-no-hover m2m-table';
      } else {
        if (config.COMPONENT.TABLE.STRIPED) {
          return 'row-striped row-no-hover';
        } else return 'row-no-hover';
      }
    };
    const NotData = () => {
      if (dataSource.totalElements === 0) {
        return 'smartTableNotData';
      } else if (dataSource.totalElements !== 0) {
        return 'smartTableBody';
      }
    };
    const columnsFormatted = isEmpty(columnsConfig)
      ? []
      : columnsConfig.map((column, index) => ({
          ...column,
          onHeaderCell: c => ({
            width: c.width,
            onResize: handleResize(index)
          }),
          render: (value, record) =>
            ColumnRender(
              column,
              value,
              record,
              combos,
              componentId,
              handleOpenFile,
              handleOpenImage
            )
        }));

    const paginationData = dataSource && {
      current: dataSource.number + 1,
      // defaultPageSize: pagination.defaultPageSize, // initial pageSize
      pageSize: dataSource.size,
      pageSizeOptions: pagination.pageSizeOptions,
      showQuickJumper: pagination.showQuickJumper,
      showSizeChanger: pagination.showSizeChanger,
      showTotal: () =>
        pagination.showTotal &&
        `${dataSource.totalElements} ${intl.formatMessage({ id: 'records' })}`,
      size: pagination.size,
      total: dataSource.totalElements
    };
    return (
      <div className="smartTable">
        <SmartTableButtons
          parentProps={this.props}
          dataSource={dataSource}
          columns={columnsFormatted}
          loading={isLoading}
        ></SmartTableButtons>
        {/* <ReactDragListView.DragColumn
        onDragEnd={this.onDragEnd}
        nodeSelector="div"
      > */}
        <Table
          rowSelection={multiple ? rowSelection : null}
          columns={columnsFormatted.filter(c => c.visible)}
          dataSource={dataSource.content}
          pagination={paginationData}
          loading={isLoading}
          size={size}
          bordered={bordered}
          scroll={{
            y: 'calc(100vh - 264px)',
            x: tableTotalWidth(columnsFormatted)
          }}
          rowKey={rowKey}
          components={this.components}
          onRow={record => ({
            onClick: () => {
              (isMainTable === undefined || isMainTable) && onSelectRow(record);
            },
            onDoubleClick: () => {
              isMainTable && layoutType !== 2
                ? isEmpty(selectedRow)
                  ? selectAndOpen(record)
                  : handleChangeDrawerVisibility(true)
                : onSelectRow(record);
            }
          })}
          className={NotData()}
          onChange={handleTableChange}
          rowClassName={(record, index) => rowClassName(record, index)}
        />
        {/* </ReactDragListView.DragColumn> */}
        <Modal
          visible={visible}
          autoFocusButton="ok"
          onCancel={handleCloseImage}
          closable
          wrapClassName={'modal'}
          centered
          footer={[null]}
        >
          {imageIsLoading ? (
            <div className="modal__img">
              <Spin tip={intl.formatMessage({ id: 'image.loading' })} />
            </div>
          ) : (
            <img src={imageModal} alt={imageModalTitle} />
          )}
        </Modal>
      </div>
    );
  }
}

export default TableRender;
