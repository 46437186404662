import React, { useEffect } from 'react';
import Fields from './Fields';
import { Col } from 'antd';
import { isEmpty } from 'lodash';
import config from '../config'
import moment from 'moment';

export default function BehaviourField(props) {
  const { field, hidden, parentProps, setData } = props;
  const {
    handleChildBehaviours,
    handleChangeField,
    updateChildCombo,
    setParentValue
  } = parentProps;
  const {
    fields,
    form,
    values,
    formHasChanged,
    params,
    queryParams,
    selectedRow
  } = parentProps.props;
  const { componentId } = params;
  const { q } = queryParams;

  const { forceDisabled, forceVisible, isParentDisabled } = field;
  let paramsDisabled;

  if (!isEmpty(q)) {
    q.split(config.QUERY.AND).forEach(element => {
      const key = element.split(':')[0];
      const value = element.split(':')[1];
      if (value && key === field.key) {
        paramsDisabled = true;
      }
    });
  }

  const checkOperands = (fieldValue, behaviourValue, operand) => {
    // Verificamos que no sea undefined ni null por un problema con el comportamiento
    // de determinados componentes (TimePicker, DatePicker)
    // TODO: Corregir la función handleChange de estos componentes para que no haya
    // dispatch innecesarios

    if (operand === 'empty') {
      if (fieldValue === undefined) return true;
    } else {
      if (fieldValue === undefined || fieldValue === null) return false;
      switch (operand) {
        case 'like':
          return fieldValue.toString() === behaviourValue.toString();
        case 'greater':
          return fieldValue.toString() > behaviourValue.toString();
        case 'less':
          return fieldValue.toString() < behaviourValue.toString();
        case 'greater|equal':
          return fieldValue.toString() >= behaviourValue.toString();
        case 'less|equal':
          return fieldValue.toString() <= behaviourValue.toString();
        default:
          return true;
      }
    }
  };

  /**
   * This function sets the behaviour of a combo child field
   * @param {Object} field - Combo field
   * @param {Boolean} condition - true = combo parent has values
   */
  const setComboState = (field, condition) => {
    let behavioursComponent = {};
    if (condition) {
      if (field.hasOwnProperty('initialVisibility'))
        behavioursComponent['visible'] = field.initialVisibility;
      if (field.hasOwnProperty('initialMandatory'))
        behavioursComponent['mandatory'] = field.initialMandatory;
      if (field.hasOwnProperty('initialDisabled'))
        behavioursComponent['disabled'] = field.initialDisabled;
    } else {
      if (field.hasOwnProperty('initialVisibility'))
        behavioursComponent['visible'] = !field.initialVisibility;
      if (field.hasOwnProperty('initialMandatory'))
        behavioursComponent['mandatory'] = !field.initialMandatory;
      if (field.hasOwnProperty('initialDisabled'))
        behavioursComponent['disabled'] = !field.initialDisabled;
    }
    return behavioursComponent;
  };

  const checkFormValues = ({ values, fields }) => {
    let checkedValues = false;
    if (selectedRow === undefined) return checkedValues;
    else if (isEmpty(selectedRow))
      fields.forEach(field => {
        for (let key in values)
          if (field.key === key) {
            if (!field.initialValue || values[key] !== field.initialValues)
              checkedValues = true;
            // else if (!field)
            //   checkedValues = true;
            // else if()
            //   checkedValues = true;
            //TODO check appParams
            //TODO check UserParams
          }
      });
    else
      for (let key in selectedRow) {
        for (let valuesKey in values) {
          if (valuesKey === key) {
            //NO TOCAR la comparacion !=
            if (values[key] != selectedRow[key]) checkedValues = true;
          }
        }
      }
    return checkedValues;
  };

  /**
   * This function checks all the behaviours of a field and returns an Object that contain which Behaviours are displayed or not.
   * @param {Object} field - Behaviour field
   * @param {Object} values - Form values
   * @param {Object} behavioursComponent - Object which will store the diferent field behaviours
   */
  const checkBehaviours = (field, values, form) => {
    let isGroupVisible = false;
    let isGroupDisabled = false;
    let isGroupMandatory = false;
    let behavioursComponent;

    field.behaviours.forEach(behaviourGroup => {
      behavioursComponent = {};
      behaviourGroup.forEach(async behaviour => {
        const { type, operand, value } = behaviour;
        let fieldValue = values[behaviour.key];

        if (type === 'combo') {
          if (
            fieldValue !== undefined &&
            fieldValue !== null &&
            fieldValue !== ''
          ) {
            if (field.parentValue === '' && values[field.key]) {
              //Caso: Se selecciona una Row --> por defecto parentValue = '' --> se edita con el valor de la Row
              behavioursComponent = setComboState(field, true);
              setParentValue(field.key, fieldValue, form);
              updateChildCombo(field.comboId, fieldValue, field.key);
            } else if (values[behaviour.key] === field.parentValue) {
              behavioursComponent = setComboState(field, true);
            } else if (fieldValue !== field.parentValue) {
              behavioursComponent = setComboState(field, true);
              if (behaviour.params) {
                updateChildCombo(field.comboId, fieldValue, field.key);
                const formChange =
                  parentProps.props.params.type === 'edit'
                    ? checkFormValues({ values, fields })
                    : formHasChanged;
                if (formChange) {
                  delete values[field.key];
                  setData({ componentId, values });
                }
                form.resetFields();
              } else updateChildCombo(field.comboId, fieldValue, field.key);
              setParentValue(field.key, fieldValue, form);
            }
          } else {
            behavioursComponent = setComboState(field, false);

            if (values[field.key] !== undefined) {
              delete values[field.key];
              setData({ componentId, values });
              form.resetFields();
            }
            if (field.parentValue !== '') setParentValue(field.key, '', form);
          }
        } else {
          if (!behavioursComponent.hasOwnProperty(type))
            behavioursComponent = {
              ...behavioursComponent,
              [type]: true
            };

          let behaviourValue = value;

          //Date & Time formatting
          if (fieldValue && moment.isMoment(fieldValue)) {
            let format;
            fields.forEach(f => {
              if (f.key === behaviour.key) {
                format = f.format;
              }
            });
            fieldValue = moment(fieldValue).format(format);
            if (format === 'HH:mm:ss') {
              fieldValue = new Date('01/01/2011 '.concat(fieldValue));
              behaviourValue = new Date('01/01/2011 '.concat(behaviourValue));
            } else {
              fieldValue = new Date(fieldValue);
              behaviourValue = new Date(behaviourValue);
            }
          }

          let check = checkOperands(fieldValue, behaviourValue, operand);

          if (!check) {
            behavioursComponent[type] = false;
          }
        }
      });
      if (behavioursComponent['visible']) isGroupVisible = true;
      if (behavioursComponent['disabled']) isGroupDisabled = true;
      if (behavioursComponent['mandatory']) isGroupMandatory = true;
    });

    behavioursComponent['visible'] = isGroupVisible ? true : false;
    behavioursComponent['disabled'] = isGroupDisabled ? true : false;
    behavioursComponent['mandatory'] = isGroupMandatory ? true : false;

    return behavioursComponent;
  };

  useEffect(() => {
    const {
      visible,
      disabled,
      mandatory,
      initialVisibility,
      initialDisabled,
      initialMandatory
    } = field;
    let behavioursDisplayed = {};

    const behavioursComponent = checkBehaviours(
      field,
      values,
      form,
      forceVisible,
      forceDisabled
    );

    for (let key in behavioursComponent) {
      switch (key) {
        case 'visible':
          if (forceVisible === null || forceVisible === undefined) {
            if (initialVisibility) {
              if (visible && behavioursComponent[key]) {
                handleChangeField({
                  id: field.key,
                  value: '',
                  type: field.type
                });

                behavioursDisplayed = {
                  ...behavioursDisplayed,
                  visible: false
                };
              }
              if (!visible && !behavioursComponent[key])
                behavioursDisplayed = {
                  ...behavioursDisplayed,
                  visible: true
                };
            } else if (initialVisibility === false) {
              if (!visible && behavioursComponent[key]) {
                behavioursDisplayed = {
                  ...behavioursDisplayed,
                  visible: true
                };
              }

              if (visible && !behavioursComponent[key]) {
                behavioursDisplayed = {
                  ...behavioursDisplayed,
                  visible: false
                };
                handleChangeField({
                  id: field.key,
                  value: '',
                  type: field.type
                });
              }
            }
          }
          break;
        case 'disabled':
          if (initialDisabled) {
            if (disabled && behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                disabled: false
              };

            if (!disabled && !behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                disabled: true
              };
          } else if (initialDisabled === false) {
            if (!disabled && behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                disabled: true
              };
            if (disabled && !behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                disabled: false
              };
          }

          break;
        case 'mandatory':
          if (initialMandatory) {
            if (mandatory && behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                mandatory: false
              };
            if (!mandatory && !behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                mandatory: true
              };
          } else if (initialMandatory === false) {
            if (!mandatory && behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                mandatory: true
              };
            if (mandatory && !behavioursComponent[key])
              behavioursDisplayed = {
                ...behavioursDisplayed,
                mandatory: false
              };
          }
          break;
        default:
          break;
      }
    }

    if (!isEmpty(behavioursDisplayed)) {
      handleChildBehaviours(
        field.key,
        behavioursDisplayed,
        forceVisible,
        isParentDisabled
      );
    }
  });

  field.paramsDisabled = paramsDisabled;
  if (forceVisible) field.visible = forceVisible;

  if (hidden || forceVisible === false) {
    let width = field.width;
    if (field.noWidth) width = 0;
    return <Col md={width} key={field.key} />;
  }

  return Fields({ ...parentProps, field });
}
