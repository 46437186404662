import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tabs, Drawer, PageHeader, Modal, Button } from 'antd';
import { isEmpty } from 'lodash';

import SearchForm from '../forms/search/SearchForm';
import { resetSearchComponent } from '../forms/search/searchActions';
import SmartTable from '../tables/SmartTable';
import {
  resetTableComponent,
  getTableData,
  setFormStateFlag,
  setSelectedRow,
  setChildSelectedRow,
} from '../tables/tableActions';
import {
  setInitialState,
  setSelectedTab,
  setDrawerVisibility,
  resetDashboardSelectedTab,
} from './dashboardActions';
import { setSelectedM2MDashboard } from '../m2m/m2mActions';
import {
  getComboData,
  addCustomCombos,
  initializeComponentCombos,
} from '../combos/comboActions';

import { setSelectedTabEdit } from '../forms/edit/editActions';
import EditForm from '../forms/edit/EditForm';
import { resetEditComponent } from '../forms/edit/editActions';
import combosCustom from '../combosCustom';
import { feedbackController } from '../utils/feedback';
import apiPaths from '../apiPaths';
import { editNatigationState } from '../app/queryActions';
import config from '../config';
import components from '../components';
import './DashboardComposer.css';
import customActions from '../extensions/actions/customActions';

class DashboardComposer extends Component {
  constructor(props) {
    super(props);
    const {
      components,
      componentId,
      combos,
      setInitialState,
      getComboData,
      addCustomCombos,
      feedback,
      intl,
      history,
      initializeComponentCombos,
      selectedTab,
      selectedComponent,
      setSelectedM2MDashboard,
    } = props;

    const layoutType = this.getDashboardLayoutType(components);

    let initialDashboardProps;

    initialDashboardProps =
      history.location.search !== ''
        ? {
            componentId,
            layoutType,
            drawerVisible: this.props.drawerVisible,
          }
        : {
            componentId,
            layoutType,
            drawerVisible: false,
          };
    if (selectedTab && selectedComponent) {
      initialDashboardProps.selectedTab = selectedTab;
      initialDashboardProps.selectedComponent = selectedComponent;
    }
    setInitialState({ ...initialDashboardProps });

    components.forEach((component) => {
      if (
        component.params.type === 'table' &&
        component.params.componentId === selectedComponent
      ) {
        if (component.hasOwnProperty('m2m')) {
          setSelectedM2MDashboard({
            activeDashboard: component.m2m.componentId,
          });
        }
      }
    });

    this.loadCombos(
      components,
      combos,
      combosCustom,
      getComboData,
      addCustomCombos,
      feedback,
      intl
    );
  }

  componentWillUnmount() {
    const {
      resetEditComponent,
      resetSearchComponent,
      resetTableComponent,
      components,
      resetDashboardSelectedTab,
      componentId,
    } = this.props;
    if (config.COMPONENT.FORM.RESET_ON_UNMOUNT) {
      components.forEach((component) => {
        const { type, componentId, isMainTable } = component.params;
        if (type === 'edit') resetEditComponent({ componentId });
        if (type === 'search') resetSearchComponent({ componentId });
        if (type === 'table' && isMainTable !== false)
          resetTableComponent({ componentId });
      });
      resetDashboardSelectedTab({ dashboardId: componentId });
    }
  }

  getDashboardLayoutType = (components) => {
    let layoutType = undefined;
    let searchExist = false;
    let editExist = false;
    components.forEach((component) => {
      if (component.params.type === 'search') searchExist = true;
      if (component.params.type === 'edit') editExist = true;
    });

    if (!searchExist) layoutType = 1;
    else if (!editExist) layoutType = 2;
    else if (!searchExist && !editExist) layoutType = 3;
    else layoutType = 0;

    return layoutType;
  };

  formatCombosCustom = (combos) => {
    let formattedCombos = {};
    for (let comboId in combos) {
      formattedCombos = {
        ...formattedCombos,
        [comboId]: {
          isLoading: false,
          data: combos[comboId],
        },
      };
    }
    return formattedCombos;
  };

  loadCombos(components, combos, combosCustom, getComboData, addCustomCombos) {
    if (!isEmpty(combosCustom))
      addCustomCombos(this.formatCombosCustom(combosCustom));
    components.forEach((c) => {
      const type = c.params.type;
      const componentId = c.params.componentId;
      if (
        (c.params.mustRender === undefined || c.params.mustRender) &&
        (type === 'search' || type === 'edit' || type === 'table')
      ) {
        if (!combos[componentId])
          this.props.initializeComponentCombos({ componentId });
        c.fields.forEach((f) => {
          if (
            (f.mustRender === undefined || f.mustRender) &&
            (f.type === 'combo' ||
              f.type === 'radio' ||
              f.type === 'advancedSearch' ||
              f.render === 'combo') &&
            (f.restartCombo || !this.isExistingStaticCombo(combos, c.params, f))
          ) {
            if (f.comboId && !f.hasOwnProperty('parentValue')) {
              let key = type === 'table' ? f.dataIndex : f.key;
              //check que ese combo no existe ya en redux
              // if (
              //   !(
              //     combos[componentId] &&
              //     combos[componentId][key] &&
              //     combos[componentId][key][f.comboId].data
              //   )
              // )
              getComboData({
                dataPath: apiPaths.COMBO,
                componentId,
                fieldKey: key,
                comboId: f.comboId,
                queryParams: {
                  id: f.comboId,
                },
              });
            }
          }
        });
      }
    });
  }

  isExistingStaticCombo = (combos, params, f) => {
    const key = params.type === 'table' ? f.dataIndex : f.key;
    return (
      combos[params.componentId] &&
      combos[params.componentId][key] &&
      combos[params.componentId][key][f.comboId] &&
      (('key' in f && !f.key.includes('.')) ||
        ('dataIndex' in f && !f.dataIndex.includes('.')))
    );
  };

  isTabDisabled = (table) =>
    !(
      this.props.tables[table.params.componentId] &&
      this.props.tables[table.params.componentId].parentSelectedRow &&
      !isEmpty(this.props.tables[table.params.componentId].parentSelectedRow)
    );

  pageHeaderTitle = (breadcrumbs) =>
    isEmpty(breadcrumbs[breadcrumbs.length - 1].child) ? (
      <span className="pageHeader__title--italic">Nou Registre</span>
    ) : (
      <span className="pageHeader__title">
        {breadcrumbs[breadcrumbs.length - 1].child.name}
      </span>
    );

  pageHeaderSubTitle = (breadcrumbs) => (
    <span className="pageHeader__subTitle">
      {this.props.breadcrumbs[breadcrumbs.length - 1].name}{' '}
    </span>
  );

  handleChangeDrawerVisibility = (tableComponentId, editComponentId) => {
    const handleCloseModal = ({ tableComponentId, editComponentId }) => {
      const {
        componentId,
        setDrawerVisibility,
        setFormStateFlag,
        setSelectedRow,
        tables,
        setSelectedTabEdit,
      } = this.props;

      setSelectedRow({
        componentId: tableComponentId,
        selectedRow: tables[tableComponentId].selectedRow,
      });
      setDrawerVisibility({ dashboardId: componentId, visible: false });
      setFormStateFlag({
        componentId: tableComponentId,
        formHasChanged: false,
      });
      setSelectedTabEdit({
        componentId: editComponentId,
        selectedTab: '0',
      });
    };

    const formHasChanged = this.props.tables[tableComponentId].formHasChanged;
    if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
      Modal.confirm({
        title: this.props.intl.formatMessage({ id: 'pop.title.select' }),
        content: this.props.intl.formatMessage({ id: 'pop.title.select.warn' }),
        okText: this.props.intl.formatMessage({ id: 'pop.accept' }),
        cancelText: this.props.intl.formatMessage({ id: 'pop.cancel' }),
        // icon: 'warning',
        maskClosable: true,
        onOk() {
          handleCloseModal({ tableComponentId, editComponentId });
        },
        onCancel() {},
      });
    } else {
      handleCloseModal({ tableComponentId, editComponentId });
    }
  };

  dashboardLayout = (dashboardComponents) => {
    const { selectedTab, layoutType, history, selectedComponent } = this.props;
    // comprobar la propiedad forceVisible y forceDisable
    let searchComponent = undefined;
    let editComponent = undefined;
    let primaryTableComponent = undefined;
    let secondaryTableComponents = [];
    dashboardComponents.forEach((component) => {
      if (component.params.type === 'search') searchComponent = component;
      if (component.params.type === 'table') {
        if (component.params.isMainTable === true)
          primaryTableComponent = component;
        // evaluar mustReder y tabindex
        // .map((element, i) => {
        //   element.params.tabIndex = i;
        //   return element;
        // });
        else secondaryTableComponents.push(component);
      }
      if (component.params.type === 'edit') editComponent = component;
    });

    let activeK;
    if (layoutType === 0 || layoutType === 1) {
      activeK = selectedTab
        ? selectedTab
        : editComponent.params.tabIndex.toString();
    }
    const parentFormHasChanged =
      primaryTableComponent !== undefined
        ? this.props.tables[primaryTableComponent.params.componentId]
            .formHasChanged
        : undefined;

    const tableProps =
      this.props.customDashboard &&
      customActions[this.props.customDashboard] &&
      customActions[this.props.customDashboard].table
        ? { ...customActions[this.props.customDashboard].table(this.props) }
        : { ...this.props };

    let editProps = { setSelectedRow: this.props.setSelectedRow };
    editProps =
      this.props.customDashboard &&
      customActions[this.props.customDashboard] &&
      customActions[this.props.customDashboard].edit
        ? { ...customActions[this.props.customDashboard].edit(editProps) }
        : { ...editProps };

    const handleSelectRow = async ({
      data,
      next,
      tableId,
      actualRowPosition,
      size,
      number,
      rowKey,
    }) => {
      let newRow;
      const {
        setSelectedRow,
        getTableData,
        setFormStateFlag,
        setChildSelectedRow,
      } = tableProps;

      const formHasChanged = this.props.tables[tableId].formHasChanged;
      const targetsId = components[tableId].targetsId;
      const prettierKey = this.props.tables[tableId].prettierKey;
      if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
        Modal.confirm({
          title: this.props.intl.formatMessage({ id: 'pop.title.select' }),
          content: this.props.intl.formatMessage({
            id: 'pop.title.select.warn',
          }),
          okText: this.props.intl.formatMessage({ id: 'pop.accept' }),
          cancelText: this.props.intl.formatMessage({ id: 'pop.cancel' }),
          // icon: 'warning',
          maskClosable: true,
          async onOk() {
            if (data && data.content) {
              if (!next && actualRowPosition === 0 && number > 0) {
                const response = await getTableData({
                  dataPath: components[tableId].path,
                  componentId: tableId,
                  queryParams: { page: number - 1 },
                });
                newRow =
                  response.data.content[response.data.content.length - 1];
              } else if (next && actualRowPosition === size - 1) {
                const response = await getTableData({
                  dataPath: components[tableId].path,
                  componentId: tableId,
                  queryParams: { page: number + 1 },
                });
                newRow = response.data.content[0];
              } else
                newRow = next
                  ? data.content[actualRowPosition + 1]
                  : data.content[actualRowPosition - 1];
              setSelectedRow({ componentId: tableId, selectedRow: newRow });
              setChildSelectedRow({
                targetsId,
                record: newRow,
                prettierKey,
                rowKey,
              });

              targetsId.forEach(async (target) => {
                if (target.id === selectedComponent) {
                  //TODO REPETIDO EN LINEA 720 -> UNIFICAR (handleChangeTab)
                  let queryParams = { q: '' };
                  let currentComponent;
                  dashboardComponents.forEach((comp) => {
                    if (comp.params.componentId === selectedComponent)
                      currentComponent = comp;
                  });

                  currentComponent.fields.forEach((col) => {
                    if (
                      col.hasOwnProperty('defaultSortOrder') &&
                      !isEmpty(col.defaultSortOrder)
                    ) {
                      queryParams = {
                        ...queryParams,
                        sort:
                          col.defaultSortOrder === 'descend' ? 'desc' : 'asc',
                        field: col.dataIndex,
                      };
                    }
                  });

                  if (currentComponent.params.navigationExtensions) {
                    currentComponent.params.navigationExtensions.forEach(
                      (element) => {
                        if (element.hasOwnProperty('value'))
                          queryParams.q += `${element.key}:${element.value},`;
                        else
                          queryParams.q += `${element.key}:${
                            newRow[element.key]
                          },`;
                      }
                    );
                  }

                  queryParams.q += `${target.path}:${newRow[rowKey]}`;

                  await getTableData({
                    dataPath:
                      components[currentComponent.params.componentId].path,
                    componentId: currentComponent.params.componentId,
                    queryParams,
                    foreignFilters: 'status:true',
                  });
                }
              });
            }
            setFormStateFlag({
              componentId: tableId,
              formHasChanged: false,
            });
          },
          onCancel() {},
        });
      } else {
        if (data && data.content) {
          if (!next && actualRowPosition === 0 && number > 0) {
            const response = await getTableData({
              dataPath: components[tableId].path,
              componentId: tableId,
              queryParams: { page: number - 1 },
            });
            newRow = response.data.content[response.data.content.length - 1];
          } else if (next && actualRowPosition === size - 1) {
            const response = await getTableData({
              dataPath: components[tableId].path,
              componentId: tableId,
              queryParams: { page: number + 1 },
            });
            newRow = response.data.content[0];
          } else
            newRow = next
              ? data.content[actualRowPosition + 1]
              : data.content[actualRowPosition - 1];
          setSelectedRow({ componentId: tableId, selectedRow: newRow });
          setChildSelectedRow({
            targetsId,
            record: newRow,
            prettierKey,
            rowKey,
          });
          targetsId.forEach(async (target) => {
            if (target.id === selectedComponent) {
              //TODO REPETIDO EN LINEA 720 -> UNIFICAR (handleChangeTab)
              let queryParams = { q: '' };
              let currentComponent;
              dashboardComponents.forEach((comp) => {
                if (comp.params.componentId === selectedComponent)
                  currentComponent = comp;
              });

              currentComponent.fields.forEach((col) => {
                if (
                  col.hasOwnProperty('defaultSortOrder') &&
                  !isEmpty(col.defaultSortOrder)
                ) {
                  queryParams = {
                    ...queryParams,
                    sort: col.defaultSortOrder === 'descend' ? 'desc' : 'asc',
                    field: col.dataIndex,
                  };
                }
              });

              if (currentComponent.params.navigationExtensions) {
                currentComponent.params.navigationExtensions.forEach(
                  (element) => {
                    if (element.hasOwnProperty('value'))
                      queryParams.q += `${element.key}:${element.value},`;
                    else
                      queryParams.q += `${element.key}:${newRow[element.key]},`;
                  }
                );
              }

              queryParams.q += `${target.path}:${newRow[rowKey]}`;

              await getTableData({
                dataPath: components[currentComponent.params.componentId].path,
                componentId: currentComponent.params.componentId,
                queryParams,
                foreignFilters: 'status:true',
              });
            }
          });
        }
        setFormStateFlag({
          componentId: tableId,
          formHasChanged: false,
        });
      }
    };

    const getRowPosition = ({ data, rowKey, selectedRow }) => {
      let rowPosition = 0;
      if (data && data.content)
        data.content.forEach((record, i) => {
          if (record[rowKey] === selectedRow[rowKey]) rowPosition = i;
        });
      return rowPosition;
    };

    const rowNavigationButtons = ({ tableId, rowKey }) => {
      const { selectedRow, data } = this.props.tables[tableId];
      let isLoading = false;
      if (selectedComponent && this.props.tables[selectedComponent])
        isLoading = this.props.tables[selectedComponent].isLoading;

      const { totalElements, number, size } = data;
      const actualRowPosition = getRowPosition({
        data,
        rowKey,
        selectedRow,
      });

      const buttonDisabled =
        !selectedComponent || components[selectedComponent].type === 'edit'
          ? false
          : isLoading;
      if (!isEmpty(selectedRow))
        return (
          <div>
            <span className="counter">
              {size * number + actualRowPosition + 1} / {totalElements}
            </span>
            <Button.Group>
              <Button
                icon="left"
                size="large"
                disabled={
                  (actualRowPosition === 0 && number === 0) || buttonDisabled
                }
                onClick={() =>
                  handleSelectRow({
                    data,
                    next: false,
                    tableId,
                    actualRowPosition,
                    size,
                    number,
                    rowKey,
                  })
                }
              />
              <Button
                icon="right"
                size="large"
                disabled={
                  size * number + actualRowPosition + 1 === totalElements ||
                  isLoading
                }
                onClick={() =>
                  handleSelectRow({
                    next: true,
                    data,
                    tableId,
                    actualRowPosition,
                    number,
                    size,
                    rowKey,
                  })
                }
              />
            </Button.Group>
          </div>
        );
    };

    return (
      <div>
        <Row
          style={{ height: '100%' }}
          type="flex"
          justify="space-around"
          align="middle"
        >
          <Col span={24}>
            <Row key={'search'}>
              <SearchForm
                {...{
                  ...searchComponent,
                  ...this.props,
                  dashboardId: this.props.componentId,
                }}
                options={searchComponent.settings}
              />
            </Row>
            <Row key={'table'}>
              <SmartTable
                {...{
                  ...primaryTableComponent,
                  ...tableProps,
                  dashboardId: this.props.componentId,
                }}
              />
              {(layoutType === 0 || layoutType === 1) && (
                <Drawer
                  width={
                    window.innerWidth < config.BREAKPOINTS.MD ? '100%' : '88%'
                  }
                  onClose={() =>
                    this.handleChangeDrawerVisibility(
                      primaryTableComponent.params.componentId,
                      editComponent.params.componentId
                    )
                  }
                  destroyOnClose
                  visible={this.props.drawerVisible}
                  closable={false} // poner a true cuando se solucione el problema de la "X" del close
                >
                  <PageHeader
                    className="pageHeader"
                    onBack={() =>
                      this.handleChangeDrawerVisibility(
                        primaryTableComponent.params.componentId,
                        editComponent.params.componentId
                      )
                    }
                    subTitle={this.pageHeaderTitle(this.props.breadcrumbs)}
                    title={this.pageHeaderSubTitle(this.props.breadcrumbs)}
                    extra={rowNavigationButtons({
                      tableId: primaryTableComponent.params.componentId,
                      rowKey: primaryTableComponent.params.rowKey,
                    })}
                  ></PageHeader>
                  <Tabs
                    type="card"
                    activeKey={activeK}
                    onChange={(activeKey) =>
                      this.handleChangeTab(
                        activeKey,
                        [editComponent, ...secondaryTableComponents],
                        primaryTableComponent.params.componentId
                      )
                    }
                  >
                    <Tabs.TabPane
                      tab={editComponent.params.panelHeader}
                      key={editComponent.params.tabIndex}
                      className="tabContainer"
                    >
                      <EditForm
                        {...{
                          ...editProps,
                          customDashboard: this.props.customDashboard,
                          dashboardId: this.props.componentId,
                          params: editComponent.params,
                          groups: editComponent.groups,
                          fields: editComponent.fields,
                          options: editComponent.settings,
                          history: history,
                        }}
                      />
                    </Tabs.TabPane>
                    {secondaryTableComponents.map((table) => (
                      <Tabs.TabPane
                        tab={table.params.panelHeader}
                        key={table.params.tabIndex}
                        disabled={this.isTabDisabled(table)}
                        className="tabContainer"
                      >
                        <SmartTable
                          {...{
                            ...table,
                            ...this.props,
                            dashboardId: this.props.componentId,
                            parentFormHasChanged: parentFormHasChanged,
                          }}
                        />
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </Drawer>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  //TODO check aqui todos los elementos (Fields)  --> Grupos y Subgrupos en CONSTRUCTOR
  checkComponentPermissions = (components) => {
    let componentsArray = components.slice();
    const { userPermissions } = this.props;
    componentsArray.forEach((component) => {
      const { permissions } = component.params;
      if (permissions) {
        permissions.forEach((permission) => {
          if (userPermissions.includes(permission.name)) {
            for (let key in permission) {
              if (key !== 'name') {
                component.params[key] = permission[key];
                component.params[
                  'force'.concat(key.charAt(0).toUpperCase() + key.slice(1))
                ] = permission[key];
              }
            }
          }
        });
      }
    });
    return componentsArray;
  };

  handleChangeTab = async (activeKey, tabs, mainTableId) => {
    const {
      setSelectedM2MDashboard,
      setSelectedTab,
      componentId,
      tables,
      getTableData,
    } = this.props;
    if (tabs[activeKey].hasOwnProperty('m2m'))
      setSelectedM2MDashboard({
        activeDashboard: tabs[activeKey].m2m.componentId,
      });

    if (tabs[activeKey].params.type === 'table') {
      const selectedId = tabs[activeKey].params.componentId;
      const parentPkRecord =
        tables[selectedId].parentSelectedRow[tables[selectedId].parentRowKey];
      // los query params no deberian coger el navigationId... deberia haber parametro en el dashboard que indique
      // que params ha de coger, ya que estos pueden ser diferentes (como la navegación de Clientes -> Direcciones - Contactos)
      const componentId = tabs[activeKey].params.componentId;
      let path = '';
      components[mainTableId].targetsId.forEach((id) => {
        if (id.id === componentId) {
          path = id.path;
        }
      });

      let queryParams = { q: '' };
      tabs[activeKey].fields.forEach((col) => {
        if (
          col.hasOwnProperty('defaultSortOrder') &&
          !isEmpty(col.defaultSortOrder)
        ) {
          queryParams = {
            ...queryParams,
            sort: col.defaultSortOrder === 'descend' ? 'desc' : 'asc',
            field: col.dataIndex,
          };
        }
      });

      // let queryParams = { q: '' };
      if (tabs[activeKey].params.navigationExtensions) {
        tabs[activeKey].params.navigationExtensions.forEach((element) => {
          if (element.hasOwnProperty('value'))
            queryParams.q += `${element.key}:${element.value}${config.QUERY.AND}`;
          else
            queryParams.q += `${element.key}:${
              tables[mainTableId].selectedRow[element.key]
            }${config.QUERY.AND}`;
        });
      }

      queryParams.q += `${path}:${parentPkRecord}`;

      await getTableData({
        dataPath: components[selectedId].path,
        componentId: selectedId,
        queryParams,
        // foreignFilters: 'status:true' --> si se mantiene un modelo de borrado lógico esto deberia mantenerse
      });
    }

    setSelectedTab({
      dashboardId: componentId,
      tabIndex: tabs[activeKey].params.tabIndex.toString(),
      componentId: tabs[activeKey].params.componentId,
    });
  };

  render() {
    const { components, position } = this.props;
    const checkedComponents = this.checkComponentPermissions(components);
    return this.dashboardLayout(checkedComponents, position);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    editFormNavigation: state.query.editFormNavigation,
    searchFormNavigation: state.query.searchFormNavigation,
    layoutType: state.dashboards[ownProps.componentId].layoutType,
    drawerVisible: state.dashboards[ownProps.componentId].drawerVisible,
    selectedTab: state.dashboards[ownProps.componentId].selectedTab,
    selectedComponent: state.dashboards[ownProps.componentId].selectedComponent,
    combos: state.combos,
    tables: state.tables,
    breadcrumbs: state.query.breadcrumbs,
    userPermissions: state.app.permissions,
  };
};

export default connect(mapStateToProps, {
  setInitialState,
  getComboData,
  addCustomCombos,
  setDrawerVisibility,
  feedback: feedbackController,
  editNatigationState,
  setSelectedM2MDashboard,
  initializeComponentCombos,
  resetSearchComponent,
  resetEditComponent,
  resetTableComponent,
  setSelectedTab,
  setFormStateFlag,
  setSelectedRow,
  setSelectedTabEdit,
  getTableData,
  resetDashboardSelectedTab,
  setChildSelectedRow,
})(DashboardComposer);
