import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Row, Select, Spin, Title, Col, Typography, Upload, Icon } from 'antd';
import { isEmpty } from 'lodash';
import * as apiBack from '../api';
import { loadState, saveState } from '../utils/localStorage';
import config from '../config'
import {feedback} from '../utils/feedback'

export default function modalRubricDefault(props) {
  const [areas, setAreas] = useState([]);
  const [idArea, setIdArea] = useState(undefined);
  const [nomArea, setNomArea] = useState(undefined);
  const [nom, setNom] = useState(undefined);
  const [id, setId] = useState(-1);
  const accessToken = loadState().auth.accessToken;
  const auth = {
    headers: {
      authorization:
        accessToken.indexOf('Bearer') < 0
          ? `Bearer ${accessToken}`
          : accessToken,
    },
  };
  useEffect(() =>{
    async function fetchAreas() {
      const response = await apiBack.getDataCall({
        dataPath: 'combodata?id=area',
      })
      setAreas(response.data);
    }
    fetchAreas();
    if(props.edit){
      setId(props.selectedRow.id);
      setIdArea(props.selectedRow.fkArea.id);
      setNomArea(props.selectedRow.fkArea.nombre);
      setNom(props.selectedRow.nombre);
    }    
  },[]);

  function onChangeArea(value){
    setIdArea(value);
    if(value){
      areas.forEach((area)=>{
        if(area.id == value){
          setNomArea(area.nombre);
        }
      })
    }else{
      setNomArea(undefined);
    }    
  }

  function onChangeName(e){
    if(e.target.value){
      setNom(e.target.value);
    }else{
      setNom(undefined);
    }
  }

  const options = !isEmpty(areas) && areas.map(({description, value},i)=>(
    <Select.Option value={value} key={i}>
      {description}
    </Select.Option>
  ))

  return(
    <>
    {isEmpty(areas) ? 
    <div className="modal__img">
      <Spin />
    </div>
    :
    <div>
      <Row gutter={10} style={{paddingBottom: 30}}>
        <Col>
    <Typography.Title style={{fontSize: 20}}>{props.edit ? 'Edició rúbriques model': 'Creació rúbriques model'}</Typography.Title>
        </Col>
      </Row>  
      <Row gutter={10} style={{paddingBottom: 30}}>
        <Col span={12}>
          <Typography.Title style={{fontSize: 15}}>Nom del fitxer</Typography.Title>
          <Input 
            onChange={onChangeName}
            allowClear={true}
            value={nom}
          />
        </Col>
        <Col span={12}>
          <Typography.Title style={{fontSize: 15}}>Area de la rúbrica</Typography.Title>
          <Select
            onChange={onChangeArea}
            allowClear={true}
            defaultValue={nomArea}
            placeholder="Area"
          >
            {options}
          </Select>
        </Col>
      </Row>
      <Row gutter={10} style={{paddingBottom: 30}}>
        <Col span={8}></Col>
        <Col span={8}>
          <Typography.Title style={{fontSize: 15}}>Selecciona el pdf</Typography.Title>
          <Upload
            name='file'
            action={`${config.API.ROOT_URL}/archivorubrica/upload?id=${id}&areaId=${idArea}&nombre=${nom}`}
            {...auth}
            disabled={idArea ? nom ? false : true : true}
            showUploadList={false}
            onChange={(info) => {
              if(info.file.status === 'done'){
                props.setCreateVisible(false);                
                window.location.reload();
                feedback({
                  type: 'notification',
                  method: 'info',
                  message: 'Se ha subido el archivo correctamente'
                });
              }
            }}
          >
            <Button 
              className="uploadButton" 
              size="small"
              disabled={idArea ? nom ? false : true : true}
            >
              <Icon type="upload" />{' '}
              Selecciona el pdf
            </Button>
          </Upload>
        </Col>
        <Col span={8}></Col>  
      </Row>      
    </div>      
    }
    </>
  )
}