import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import Routes from './Routes';
import Context from './Context';

class App extends Component {
  render() {
    return (
      <DragDropContextProvider backend={HTML5Backend}>
        <Context>
          <Router>
            <Routes />
          </Router>
        </Context>
      </DragDropContextProvider>
    );
  }
}

export default App;
