import * as types from './dashboardTypes';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_DASHBOARD_INITIAL_STATE:
      return {
        ...state,
        [action.payload.componentId]: {}
      };
    case types.DASHBOARD_INITIAL_STATE:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          layoutType: action.payload.layoutType,
          drawerVisible: action.payload.drawerVisible
        }
      };
    case types.DASHBOARD_SET_SELECTED_TAB:
      return {
        ...state,
        [action.payload.dashboardId]: {
          ...state[action.payload.dashboardId],
          selectedTab: action.payload.tabIndex,
          selectedComponent: action.payload.componentId
        }
      };
    case types.DASHBOARD_SET_DRAWER:
      return {
        ...state,
        [action.payload.dashboardId]: {
          ...state[action.payload.dashboardId],
          drawerVisible: action.payload.visible
        }
      };
    case types.DASHBOARD_RESET_SELECTED_TAB:
      const { selectedTab, selectedComponent, ...restProps } = state[
        action.payload.dashboardId
      ];
      return {
        ...state,
        [action.payload.dashboardId]: {
          ...restProps
        }
      };
    default:
      return state;
  }
}
