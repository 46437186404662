import React, { Component } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import { ConnectedRouter } from 'connected-react-router';

import PrivateRoute from '../auth/PrivateRoute';
import MainWrapper from './MainWrapper';
import Login from '../auth/Login';
import NotFound from './NotFound';
import UpdatePassword from '../auth/UpdatePassword';
import { setInitialBreadcrumb } from './queryActions';

import { login, logout } from '../auth/authActions';
import { access } from './appActions';

import config from '../config';
import { history } from '../store';

import './NotFound.css';
import configDev from '../configDev';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.authenticate();
  }

  async authenticate() {
    const {
      isAuthorized,
      userPermissions,
      menu,
      access,
      login,
      logout
    } = this.props;
    if (config.SECURITY === 0 && !isAuthorized) login();
    if (isAuthorized && (menu === null || userPermissions === null)) {
      try {
        const response = await access();

        if (!response) {
          logout();
        }
      } catch (e) {
        console.error(e);
        logout();
      }
    }
  }

  componentDidUpdate() {
    this.authenticate();
  }

  renderMenuRoutes = menu =>
    menu.map(({ id, route }) => {
      return (
        <PrivateRoute
          key={id}
          path={route}
          render={() => <MainWrapper menu={menu} />}
        />
      );
    });

  render() {
    const {
      isAuthorized,
      menu,
      setInitialBreadcrumb,
      userPermissions,
      appParams
    } = this.props;
    if (!configDev.SAVE_CONFIG) {
      localStorage.removeItem(
        `${config.LOCAL_STORAGE.CONFIG}_${config.APP.NAME}`
      );
    }
    let routes;

    if (history.location.pathname === '/password/update') {
      this.props.logout();
      routes = (
        <Switch>
          <Route replace path="/password/update" component={UpdatePassword} />
        </Switch>
      );
    } else if (!isAuthorized)
      routes = (
        <Switch>
          <Route replace path="/login" component={Login} />
          <Route replace path="/password/update" component={UpdatePassword} />
          <Route path="*" render={() => <Redirect to="/login" />} />
        </Switch>
      );
    else if (isAuthorized && (menu === null || userPermissions === null))
      return <Spin className="notFound" size="large" />;
    else if (
      isAuthorized &&
      isEmpty(menu) &&
      userPermissions !== null &&
      appParams !== null
    )
      this.props.logout();
    else {
      const { route, nameOfMenu, iconOfMenu } = isEmpty(menu[0].subMenu)
        ? menu[0]
        : menu[0].subMenu[0];

      const initialRoute = config.WELCOME.SHOW
        ? '/Home'
        : isEmpty(menu[0].subMenu)
        ? menu[0].route
        : menu[0].subMenu[0].route;

      setInitialBreadcrumb({
        path: route,
        name: nameOfMenu,
        icon: iconOfMenu,
        absolute: true
      });

      routes = (
        <Switch>
          <Route
            exact
            replace
            path="/"
            render={() => <Redirect to={initialRoute} />}
          />
          <Route
            replace
            path="/login"
            render={() => <Redirect to={initialRoute} />}
          />
          <PrivateRoute
            path="*"
            render={() => (
              <MainWrapper menu={menu}>
                <NotFound />
              </MainWrapper>
            )}
          />
          {this.renderMenuRoutes(menu)}
        </Switch>
      );
    }

    return <ConnectedRouter history={history}>{routes}</ConnectedRouter>;
  }
}

const mapStateToProps = state => ({
  isAuthorized: state.auth.isAuthorized,
  menu: state.app.menu,
  userPermissions: state.app.permissions,
  appParams: state.app.appParams
});

export default connect(mapStateToProps, {
  access,
  login,
  logout,
  setInitialBreadcrumb
})(Routes);
