import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Checkbox } from 'antd';
import { FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';

import config from '../config';

/**
 * This function returns column formated by render type
 * @param {Object} column
 */
const ColumnRender = (
  column,
  value,
  record,
  combos,
  componentId,
  handleOpenFile,
  handleOpenImage
) => {
  if (column.render) {
    switch (column.render) {
      case 'check':
        return <Checkbox checked={value} disabled={true} />;
      case 'time':
        return <FormattedTime timeZone="utc" value={value || false} />;
      case 'date':
        if (!value) return;
        return (
          <FormattedDate
            value={value}
            day="2-digit"
            month="2-digit"
            year="numeric"
          />
        );
      case 'currency':
        if (!value) return;
        return (
          <FormattedNumber value={value} style={column.render} currency="EUR" />
        );
      case 'percent':
        if (!value) return;
        return (
          <FormattedNumber
            value={value / 100}
            minimumFractionDigits={2}
            style={column.render}
          />
        );
      case 'document':
        return (
          value && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{value}</span>
              <Button
                icon="picture"
                className="static-button"
                onClick={event => {
                  event.stopPropagation();
                  const fkFolder =
                    column.folder.indexOf('.') < 0
                      ? record[column.folder]
                      : record[
                          column.folder.slice(0, column.folder.indexOf('.'))
                        ][column.folder.slice(column.folder.indexOf('.') + 1)];
                  handleOpenFile({
                    fkFolder,
                    docName: record[column.dataIndex]
                  });
                }}
                disabled={isEmpty(value)}
              />
            </div>
          )
        );
      case 'image':
        return (
          value && (
            <div>
              <Button
                icon="picture"
                className="static-button"
                onClick={event => {
                  event.stopPropagation();
                  handleOpenImage(value, column.title);
                }}
                disabled={isEmpty(value)}
                title={value}
              />
            </div>
          )
        );
      case 'comboCustom':
        if (value === null || value === undefined || value < 0) return;
        let newValue = '';
        if (
          combos[column.comboId].data &&
          !isEmpty(combos[column.comboId].data)
        )
          combos[column.comboId].data.map(o => {
            if (o.value === value.toString()) {
              newValue = o.description;
            }
            return o;
          });
        return <div>{newValue}</div>;
      case 'combo':
        if (value === null || value === undefined || value < 0) return;
        let newValue2 = '';
        if (
          combos[componentId] &&
          combos[componentId][column.dataIndex] &&
          combos[componentId][column.dataIndex][column.comboId].data
        )
          combos[componentId][column.dataIndex][column.comboId].data.map(o => {
            if (o.value === value.toString()) {
              newValue2 = o.description;
            }
            return o;
          });
        return <div>{newValue2}</div>;
      case 'link':
        return (
          <a
            href={`${config.API.STATIC_URL}/documents/${
              record[column.linkKey]
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
        );
      case 'bool':
        return(
          value ? 
          <div>Sí</div>:<div>No</div>
        )
      default:
        return value;
    }
  } else return value;
};

export default ColumnRender;
