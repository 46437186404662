import React from 'react';
import { Form } from 'antd';
import customDashboards from '../../extensions/dashboards/customDashboards';

import formRender from '../formRender';
import { buttonsContainerBottom, buttonsContainerTop } from './editFormButtons';

export default function EditFormRender(parentProps) {
  const componentProps =
    parentProps.props.customDashboard &&
    customDashboards[parentProps.props.customDashboard] &&
    customDashboards[parentProps.props.customDashboard].edit
      ? customDashboards[parentProps.props.customDashboard].edit(parentProps)
      : { ...parentProps };

  return (
    <Form className="form" onSubmit={componentProps.handleCreateRecord}>
      <div
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: 'calc(100vh - 163px)'
        }}
      >
        {buttonsContainerTop(componentProps)}
        {formRender(componentProps)}
      </div>
      {buttonsContainerBottom(componentProps)}
    </Form>
  );
}
