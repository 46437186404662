import * as types from './authTypes';

const initialState = {
  isAuthorized: false,
  isLoading: false,
  visible: false,
  refreshToken: '',
  accessToken: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.AUTH_USER:
      return {
        ...state,
        isAuthorized: true
      };
    case types.UNAUTH_USER:
      return {
        ...state,
        isAuthorized: false
      };
    case types.SET_TOKENS:
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
        accessToken: action.payload.accessToken
      };
    case types.REMOVE_TOKEN:
      return {
        ...initialState
      };
    case types.ENABLE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case types.DISABLE_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case types.SUCCESS_MODAL:
      return {
        ...state,
        visible: action.payload.visible
      };
    default:
      return state;
  }
}
