import React from 'react';
import fieldRender from './fieldRender';
import BehaviourField from '../fields/BehaviourField';
import { checkElementBehaviours } from './behaviourUtils';
import { Tabs, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import './FormStyles.css';

const TabPane = Tabs.TabPane;

/**
 *
 * @param {*} parentProps Inherited props from EditForm
 * @returns Edit form
 * This function maps 'fieldConfig' and builds the Edit Form. Groups, subgroups and fields will be displayed according 'fieldsConfig' structure (build on the Edit Constructor).
 */
export default function formRender(parentProps) {
  const { props } = parentProps;
  const { values, fieldsConfig } = props;
  const checkedFieldsConfig = checkElementBehaviours(fieldsConfig, values);
  return (
    <>
      {fieldsConfig.length > 1
        ? tabRender(checkedFieldsConfig, parentProps)
        : RowRender(
            checkedFieldsConfig[0].subgroupRows,
            parentProps,
            null,
            checkedFieldsConfig[0].disabled
          )}
    </>
  );
}

const checkDisabledTabs = (groups, index) => {
  if (!isEmpty(groups)) {
    if (groups[index].disabled) {
      if (groups[index].mustRender !== false || groups[index].visible !== false)
        index += 1;
      if (index + 1 > groups.length) return 0;
      return checkDisabledTabs(groups, index);
    } else return `${index}`;
  }
  return 0;
};

const tabRender = (groups, parentProps) => {
  const { handleChangeTab, props } = parentProps;
  const { selectedTab } = props;
  const index = checkDisabledTabs(groups, 0).toString();
  const invisibleGroups = [];
  return (
    <>
      <Tabs
        defaultActiveKey={index}
        activeKey={selectedTab}
        animated={false}
        onChange={handleChangeTab}
      >
        {groups.map(group => {
          if (group.hasOwnProperty('forceDisabled'))
            group.disabled = group.forceDisabled;
          if (
            group.mustRender !== false &&
            group.visible !== false &&
            group.forceVisible !== false
          )
            return (
              <TabPane
                disabled={group.disabled}
                tab={group.title}
                key={group.index}
                forceRender
                className="tabContainer"
              >
                {RowRender(group.subgroupRows, parentProps)}
              </TabPane>
            );
          else if (group.visible === false) invisibleGroups.push(group);
          return null;
        })}
      </Tabs>
      {!isEmpty(invisibleGroups)
        ? invisibleGroups.map(g => {
            return (
              <Col className="noDisplay" key={g.index}>
                {RowRender(g.subgroupRows, parentProps)}
              </Col>
            );
          })
        : null}
    </>
  );
};

const RowRender = (rows, parentProps, isFieldsRow, isParentDisabled) => {
  const { options, values, params } = parentProps.props;
  return (
    <>
      {rows.map((row, i) => {
        const checkedRow = isFieldsRow
          ? row
          : checkElementBehaviours(row, values);
        if (
          checkedRow.mustRender !== false &&
          (checkedRow.forceVisible ||
            (checkedRow.visible !== false &&
              checkedRow.forceVisible === undefined))
        ) {
          return (
            <Row gutter={options.fieldGutter} key={i}>
              {checkedRow.map(subElement => {
                if (!subElement.type) {
                  if (isParentDisabled !== undefined)
                    subElement.disabled = isParentDisabled;
                  return subgroupRender(subElement, parentProps);
                } else {
                  if (isParentDisabled !== undefined) {
                    subElement.isParentDisabled = isParentDisabled;
                  }
                  if (
                    subElement.hasOwnProperty('initialVisibility') &&
                    !subElement.visible
                  )
                    return BehaviourField({
                      parentProps,
                      field: subElement,
                      hidden: true,
                      setData:
                        params.type === 'edit'
                          ? parentProps.props.setEditData
                          : parentProps.props.setSearchValues
                    });
                  else if (
                    subElement.forceVisible === false ||
                    (subElement.visible === false && !subElement.forceVisible)
                  ) {
                    let width = subElement.noWidth ? 0 : subElement.width;
                    return <Col md={width} key={subElement.key} />;
                  }
                  return (
                    subElement.mustRender !== false &&
                    fieldRender(subElement, parentProps)
                  );
                }
              })}
            </Row>
          );
        } else if (
          checkedRow.forceVisible === false ||
          (checkedRow.visible === false && !checkedRow.forceVisible)
        )
          return (
            <Col className="noDisplay" key={checkedRow.index}>
              {RowRender(checkedRow.subgroupRows, parentProps)}
            </Col>
          );
        return null;
      })}
    </>
  );
};

const subgroupRender = (fieldsSubgroup, parentProps) => {
  if (fieldsSubgroup.mustRender === false) return;

  if (fieldsSubgroup.visible === false && !fieldsSubgroup.forceVisible)
    return (
      <Col className="noDisplay" key={fieldsSubgroup.index}>
        {RowRender(
          fieldsSubgroup.fieldRow,
          parentProps,
          true,
          fieldsSubgroup.disabled
        )}
      </Col>
    );

  let isSubgroupEmpty = true;
  fieldsSubgroup.fieldRow.forEach(fieldRow => {
    fieldRow.forEach(field => {
      if (
        field.forceVisible !== false ||
        (field.visible !== false && field.forceVisible === undefined)
      ) {
        isSubgroupEmpty = false;
      }
    });
  });

  if (fieldsSubgroup.title) {
    let subgroupClassname = 'subgroups';
    let boxClassname = 'subgroups--box';
    if (isSubgroupEmpty) {
      subgroupClassname = 'subgroups--empty';
      boxClassname = '';
    }

    return (
      <Col md={fieldsSubgroup.width} key={fieldsSubgroup.index}>
        {isSubgroupEmpty ? null : (
          <div className={subgroupClassname}>{fieldsSubgroup.title} </div>
        )}
        <div className={boxClassname}>
          {RowRender(
            fieldsSubgroup.fieldRow,
            parentProps,
            true,
            fieldsSubgroup.disabled
          )}
        </div>
      </Col>
    );
  } else {
    return (
      <Col md={fieldsSubgroup.width} key={fieldsSubgroup.index}>
        {RowRender(
          fieldsSubgroup.fieldRow,
          parentProps,
          true,
          fieldsSubgroup.disabled
        )}
      </Col>
    );
  }
};
