import * as types from './m2mTypes';

const initialState = {
  visible: false,
  queryParams: {},
  data: {},
  selectedRecordsId: [],
  isLoading: false,
  activeDashboard: '',
  getSelected: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.M2M_SET_INITIAL_STATE:
      return {
        ...state,
        searchFields: action.payload.searchFields,
        tableFields: action.payload.tableFields,
        queryParams: action.payload.queryParams,
        mainKey: action.payload.mainKey,
        mainKeyValue: action.payload.mainKeyValue
      };
    case types.M2M_RESET:
      return {
        ...state,
        queryParams: {},
        data: {},
        selectedRecordsId: [],
        getSelected: false
      };
    case types.M2M_SET_TABLE_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...action.payload
        }
      };
    case types.M2M_SET_ACTIVE_DASHBOARD:
      return {
        ...state,
        activeDashboard: action.payload.activeDashboard
      };
    case types.M2M_GET_SELECTED_ROWS:
      return {
        ...state,
        getSelected: action.payload.getSelected
      };
    case types.M2M_FETCHING_RECORDS:
      return {
        ...state,
        queryParams: action.payload.queryParams,
        isLoading: action.payload.isLoading
      };
    case types.M2M_FETCHED_RECORDS:
      return {
        ...state,
        ...action.payload
      };
    case types.M2M_ADDING_RECORD:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case types.M2M_ADDED_RECORD:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case types.M2M_REMOVING_RECORD:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case types.M2M_REMOVED_RECORD:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case types.M2M_CHANGE_SELECTED_RECORDS:
      return {
        ...state,
        selectedRecordsId: action.payload.selectedRowKeys
      };
    case types.M2M_SET_SEARCH_VALUES:
      return {
        ...state,
        values: action.payload.values
      };
    case types.M2M_SET_MODAL_VISIBILITY:
      return {
        ...state,
        visible: action.payload.visible
      };
    default:
      return state;
  }
}
