import React, { Component } from 'react';
import { Button, Dropdown, Menu, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './SmartTable.css';

class SmartColumns extends Component {
  state = {
    visible: false
  };

  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };

  render() {
    const { columnsConfig, intl } = this.props;

    const menuOptions = columnsConfig.map(c => {
      return (
        <Menu.Item key={c.dataIndex}>
          <Checkbox
            type="check"
            checked={c.visible}
            style={{ paddingRight: '10px' }}
          />
          <span>{c.title}</span>
        </Menu.Item>
      );
    });
    const menu = (
      <Menu
        onClick={e => {
          this.props.onClick(e);
        }}
      >
        {menuOptions}
      </Menu>
    );
    return (
      <Dropdown
        overlay={menu}
        //className="columns-settings"
        trigger={['click']}
        onVisibleChange={this.handleVisibleChange}
        visible={this.state.visible}
      >
        <Button
          icon="bars"
          title={intl.formatMessage({ id: 'visibility' })}
          className="columns-settings"
        />
      </Dropdown>
    );
  }
}

SmartColumns.propTypes = {
  columnsConfig: PropTypes.array
};

export default injectIntl(SmartColumns);
