import React, { useState } from 'react';
import { Upload, Icon, Button, notification, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import config from '../config';
import DownloadButton from './DownloadButton';

export default function DocumentUploader(props) {
  const sizeLimit = 10000000;
  const validFormats =
    props.field.type === 'documentUpload'
      ? ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'csv']
      : props.field.type === 'imageUpload'
      ? ['image/png', 'image/jpg', 'image/jpeg']
      : [];

  const [fileList, setFileList] = useState();
  const [value, setValue] = useState('');
  const {
    intl,
    disabled,
    entity,
    values,
    field,
    token,
    handleChangeField,
    selectedRow,
    form,
    componentId,
    setFormStateFlag,
    setEditData,
    targetId
  } = props;

  if (!isEmpty(selectedRow)) {
    if (!values[field.key] && (value !== '' || !isEmpty(fileList))) {
      setValue('');
      setFileList([]);
      form.resetFields();
    } else if (
      (isEmpty(fileList) && values[field.key]) ||
      (values[field.key] && value !== values[field.key])
    ) {
      setValue(values[field.key]);
      setFileList([
        {
          uid: '1',
          name: values[field.key],
          status: 'done',
          config: { id: values[field.id], name: values[field.key] }
        }
      ]);
      form.resetFields();
    }
  }

  if (isEmpty(selectedRow) && !isEmpty(fileList) && !values[field.key]) {
    setFileList([]);
    form.resetFields();
  }

  const validateFile = file => {
    const { type, size, name } = file;
    if (size > sizeLimit)
      return {
        message: 'Error al seleccionar archivo',
        description: 'El archivo es demasiado grande'
      };
    else if (type === '') {
      const format =
        name.indexOf('.') > 0 ? name.slice(name.lastIndexOf('.') + 1) : '';
      if (!validFormats.includes(format))
        return {
          message: 'Error al seleccionar archivo',
          description: 'Formato no válido'
        };
    } else if (!validFormats.includes(type))
      return {
        message: 'Error al seleccionar archivo',
        description: 'Formato no válido'
      };
    return false;
  };

  const handleRemove = async () => {
    setValue();
    setFileList();
    await handleChangeField({ id: field.id, value: null });
    handleChangeField({ id: field.key, value: null });
  };

  const handleCheckFile = file => {
    const error = validateFile(file);
    if (error)
      notification.error({
        message: error.message || 'Ha habido un problema',
        description: error.description
      });
    return error ? false : true;
  };

  const handleSuccess = async response => {
    const { id, name } = response;
    setEditData({
      componentId,
      values: { ...values, [field.id]: id, [field.key]: name }
    });
    setFormStateFlag({
      componentId: targetId,
      formHasChanged: true
    });
    setFileList([
      {
        uid: '1',
        name,
        status: 'done',
        config: { id, name }
      }
    ]);
  };

  return (
    <Row>
      <Col md={isEmpty(fileList) ? 24 : 20}>
        <Upload
          // accept=".pdf, .png, .jpg, .jpeg"
          name="file"
          action={`${config.API.ROOT_URL}/docs?entity=${entity}&field=${field.pkFolder}`}
          headers={{ Authorization: token }}
          disabled={disabled}
          fileList={fileList}
          beforeUpload={handleCheckFile}
          onRemove={handleRemove}
          onSuccess={handleSuccess}
        >
          <Button className="uploadButton" size="small">
            <Icon type="upload" />{' '}
            {intl.formatMessage({ id: 'upload.buttonTitle' })}
          </Button>
        </Upload>
      </Col>
      {isEmpty(fileList)
        ? null
        : DownloadButton({
            disabled,
            folder: fileList[0].config.id,
            docName: fileList[0].config.name
          })}
    </Row>
  );
}
