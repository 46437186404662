import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import config from '../../config';
import { Form, Button, notification, Modal, List } from 'antd';
import {
  deleteTableData,
  getTableData,
  setFormStateFlag,
  setChildSelectedRow,
} from '../../tables/tableActions';
import { feedback } from '../../utils/feedback';
import {
  setInitialState,
  setEditData,
  updateEditData,
  createEditData,
  fetchForeignData,
  setFieldsConfig,
  setSelectedTabEdit,
  resetEditForm,
  setResetFormTrigger,
  fetchParentData,
} from './editActions';
import {
  editNatigationState,
  navigateAfterCreate,
} from '../../app/queryActions';
import { setDrawerVisibility } from '../../dashboards/dashboardActions';
import EditFormRender from './EditFormRender';
import { formConstructor } from '../formConstructor';
import { getComboData } from '../../combos/comboActions';
import moment from 'moment';
import components from '../../components.js';
import { logout } from '../../auth/authActions';
import apiPaths from '../../apiPaths';
import { loadConfig, saveConfig } from '../../utils/localStorage';
import configDev from '../../configDev';
import '../FormStyles.css';

const error = notification.error;
const flatten = require('flat');
const isTableTarget = (targetId) =>
  targetId !== '' && components[targetId].type === 'table';

class EditForm extends Component {
  constructor(props) {
    super(props);
    const {
      params,
      setInitialState,
      fieldsConfig,
      groups,
      fields,
      userPermissions,
    } = props;
    const { componentId } = params;
    let userConfig = loadConfig();
    let sortedFieldsConfig = [];

    if (isEmpty(fieldsConfig)) {
      if (
        configDev.SAVE_CONFIG &&
        userConfig !== undefined &&
        userConfig[config.USER.USERID] &&
        userConfig[config.USER.USERID][componentId] &&
        userConfig[config.USER.USERID][componentId]['editFormConfig']
      ) {
        sortedFieldsConfig =
          userConfig[config.USER.USERID][componentId]['editFormConfig'];
      } else {
        sortedFieldsConfig = formConstructor(
          groups,
          fields,
          userPermissions,
          params
        );

        if (configDev.SAVE_CONFIG) {
          if (userConfig === undefined) {
            userConfig = {};
          }
          const newConfig = {
            ...userConfig,
            [config.USER.USERID]: {
              ...userConfig[config.USER.USERID],
              [componentId]: {
                ...userConfig[config.USER.USERID][componentId],
                editFormConfig: sortedFieldsConfig,
              },
            },
          };
          saveConfig(newConfig);
        }
      }

      setInitialState({
        componentId,
        targetId: components[componentId].targetId,
        fieldsConfig: sortedFieldsConfig,
        values: {},
        selectedTab: '0',
        resetFormTrigger: false,
      });
    }
  }

  async componentDidMount() {
    const {
      params,
      setEditData,
      fields,
      selectedRow,
      userPermissions,
      groups,
    } = this.props;
    const { componentId } = params;

    let values = isEmpty(selectedRow)
      ? await this.setFormInitialValues(fields, userPermissions)
      : flatten(selectedRow);
    if (!isEmpty(values)) setEditData({ componentId, values });

    const firstKey = await this.getFormFirstKey(
      formConstructor(groups, fields, userPermissions, params)
    );

    if (firstKey) {
      const element = document.getElementById(`${firstKey}`);
      if (element.nodeName === 'INPUT') element.focus();
      else if (element.nodeName === 'SPAN')
        element.childNodes[0].childNodes[0].focus();
      else if (element.nodeName === 'DIV') element.childNodes[0].focus();
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      targetId,
      params,
      selectedRow,
      setEditData,
      form,
      fields,
      queryParams,
      values,
      hasNavigated,
      editNatigationState,
      fetchForeignData,
      setFieldsConfig,
      fieldsConfig,
      router,
      setResetFormTrigger,
      resetFormTrigger,
      userPermissions,
    } = this.props;
    const { componentId } = params;
    const { q } = queryParams;
    let control = false;
    let query;
    let initialValues = {};

    //TODO - Esto podria eleminarse......
    if (!isEmpty(q)) {
      q.split(config.QUERY.AND).forEach((element) => {
        const key = element.split(':')[0];
        const value = element.split(':')[1];
        fields.forEach(async (field) => {
          if (field.key === key && value)
            initialValues = { ...initialValues, [key]: value.toString() };
        });
        //END TODO -----

        if (value) {
          query = { ...query, [key]: value };
        }
      });
    }

    if (isTableTarget(targetId)) {
      if (hasNavigated && !isEmpty(query)) {
        //Este block es un setInitialValues .....
        fields.forEach(async (field) => {
          if (
            field.hasOwnProperty('initialValue') &&
            initialValues[field.key] !== undefined
          )
            initialValues = {
              ...initialValues,
              [field.key]: field.initialValue,
            };
          if (
            field.mustRender !== false &&
            field.path !== undefined &&
            initialValues[field.key] !== undefined
          ) {
            const response = await fetchForeignData({
              dataPath: field.path,
              componentId,
              foreignKey: field.key.split('.')[0],
              foreignValue: initialValues[field.key],
            });
            initialValues = { ...initialValues, ...response.data };
          }
        });
        setEditData({
          componentId,
          values: initialValues,
        });

        //END TODO BLOCK --- set initial Values

        if (config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
          const fieldsConfigBehaviourSelector = (field) => {
            for (let key in query) {
              if (field.key === key && !field.disabled) {
                field.disabled = true;
                control = true;
              }
            }
            return field;
          };

          const newFieldsConfig = this.changeFieldsConfig(
            fieldsConfig,
            fieldsConfigBehaviourSelector
          );
          if (control) setFieldsConfig(componentId, newFieldsConfig);
        }

        editNatigationState({ component: 'editFormNavigation' });
      } else if (!isEmpty(query)) {
        if (config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
          const fieldsConfigBehaviourSelector = (field) => {
            for (let key in query) {
              if (
                field.key === key &&
                !field.disabled &&
                !field.hasOwnProperty('parentValue')
              ) {
                field.disabled = true;
                control = true;
              }
            }
            return field;
          };

          const newFieldsConfig = this.changeFieldsConfig(
            fieldsConfig,
            fieldsConfigBehaviourSelector
          );
          if (control) setFieldsConfig(componentId, newFieldsConfig);
        }
      } else if (
        (!router && !isEmpty(values)) ||
        (router && !isEmpty(values) && isEmpty(q))
      ) {
        const fieldsConfigBehaviourSelector = (field) => {
          if (
            field.forceDisabled === undefined &&
            field.parentValue === undefined &&
            field.hasOwnProperty('disabled') &&
            field.type === 'combo' &&
            field.disabled !== field.initialDisabled &&
            field.disabled !== field.behaviourDisabled
          ) {
            field.disabled = field.initialDisabled;
            control = true;
          }
          return field;
        };

        const newFieldsConfig = this.changeFieldsConfig(
          fieldsConfig,
          fieldsConfigBehaviourSelector
        );
        if (control) setFieldsConfig(componentId, newFieldsConfig);
      }
    }

    if (prevProps.selectedRow && selectedRow !== prevProps.selectedRow) {
      if (isEmpty(selectedRow)) {
        const fieldsConfigBehaviourSelector = (field) =>
          this.setBehaviourInitialState(field);

        const newFieldsConfig = this.changeFieldsConfig(
          fieldsConfig,
          fieldsConfigBehaviourSelector
        );
        await setFieldsConfig(componentId, newFieldsConfig);
      }

      let values = isEmpty(selectedRow)
        ? await this.setFormInitialValues(fields, userPermissions)
        : flatten(selectedRow);
      if (config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
        fields.forEach((field) => {
          for (let key in query) {
            if (field.key === key)
              values = { ...values, [key]: query[key].toString() };
          }
        });
      }
      await setEditData({ componentId, values });
      form.resetFields();
    }
    if (resetFormTrigger) {
      await setResetFormTrigger({ componentId });
      form.resetFields();
    }
  }

  getFormFirstKey = (fieldsConfig) => {
    let firstKey;
    fieldsConfig.forEach((field) => {
      !firstKey &&
        field.subgroupRows.forEach((subgroupRow) => {
          !firstKey &&
            subgroupRow.forEach((subgroup) => {
              !firstKey &&
                subgroup.fieldRow.forEach((fieldRow) => {
                  !firstKey &&
                    fieldRow.forEach((field) => {
                      if (
                        !firstKey &&
                        !field.disabled &&
                        field.alwaysDisabled !== true &&
                        field.visible !== false
                      )
                        firstKey = field.key;
                    });
                });
            });
        });
    });
    return firstKey;
  };

  setBehaviourInitialState = (field) => {
    if (field.hasOwnProperty('initialVisibility')) {
      field.visible = field.initialVisibility;
    }
    if (field.hasOwnProperty('initialDisabled')) {
      field.disabled = field.initialDisabled;
    }
    if (field.hasOwnProperty('initialMandatory')) {
      field.mandatory = field.initialMandatory;
    }
    return field;
  };

  /**This function sets the initial values for the Edit Form.
   * If its type is a boolean sets `false` by default, if has InitialValue sets its `initialValue`
   * @param {object} fields - EditForm fields
   * @return {object} Initial component values
   */
  async setFormInitialValues(fields, userPermissions) {
    const {
      appParams,
      userParams,
      fetchForeignData,
      params,
      queryParams,
      fetchParentData,
      parentData,
    } = this.props;
    const { componentId } = params;
    const { q } = queryParams;
    let values = {};
    let value;
    let key;
    let path;
    let pathField;

    if (!isEmpty(q)) {
      q.split(config.QUERY.AND).forEach((element) => {
        value = element.split(':')[1];
        key = element.split(':')[0];
        values = { ...values, [key]: value.toString() };
      });
    }

    await fields.forEach(async (field) => {
      if (
        (field.mustRender === undefined || field.mustRender) &&
        (field.type === 'check' || field.type === 'checkSelect')
      ) {
        values[field.key] = false;
      }
      //1. check permissions field with user permissions
      const { permissions } = field;
      let control;
      if (permissions) {
        permissions.forEach((permission) => {
          if (
            userPermissions.includes(permission.name) &&
            permission.value !== undefined
          ) {
            control = permission.value;
          }
        });
      }

      if (value) {
        if (field.path !== undefined) {
          path = field.path;
          pathField = field;
        }
      }

      if (control) {
        values = { ...values, [field.key]: control };
      } else if (field.initialValue !== undefined) {
        if (field.type === 'date' && field.initialValue === 'now')
          values = { ...values, [field.key]: moment(Date.now()).utc() };
        else values = { ...values, [field.key]: field.initialValue };
      } else if (field.initialAppParam !== undefined) {
        if (field.initialAppParam in appParams)
          values = { ...values, [field.key]: appParams[field.initialAppParam] };
      } else if (field.initialUserParam !== undefined) {
        if (field.initialUserParam in userParams)
          values = {
            ...values,
            [field.key]: userParams[field.initialUserParam],
          };
      }
    });

    if (path !== undefined && pathField.key === key) {
      let data;
      if (isEmpty(parentData)) {
        const foreignKey =
          pathField.key.indexOf('.') > 0
            ? pathField.key.slice(0, pathField.key.lastIndexOf('.'))
            : pathField.key;
        const response = await fetchForeignData({
          dataPath: pathField.path,
          componentId,
          foreignKey,
          foreignValue: value.toString(),
        });
        data = response.data;
        fetchParentData({ componentId, data });
      } else data = { ...parentData };

      values = { ...values, ...data };
    }
    return values;
  }

  /**This functions manages changes in Form fields and checks if Form fields have changed
   * @param id - Field key
   * @param value - Field's value
   * @param path - Call path
   * @param type - Field's type, used to delete or set field to false, where parent field changes field visibility
   */
  handleChangeField = async ({ id, value, path, type }) => {
    const {
      params,
      values,
      targetId,
      setFormStateFlag,
      fetchForeignData,
      setEditData,
      selectedRow,
      formHasChanged,
    } = this.props;
    const { componentId } = params;
    let formChange = false;

    if (values[id] && value === values[id]) return;

    if (
      value === '' &&
      (values[id] === undefined || values[id] === null || values[id] === '')
    )
      return;

    if (path) {
      let newValues = { ...values };
      if (value > 0) {
        if (id.includes('.')) {
          const foreignKey = id.slice(0, id.lastIndexOf('.'));
          const response = await fetchForeignData({
            dataPath: path,
            componentId,
            foreignKey,
            foreignValue: value,
          });
          newValues = { ...newValues, ...response.data, [id]: value };
        }
      } else {
        Object.keys(values).forEach((val) => {
          if (id.split('.')[0] === val.split('.')[0]) {
            delete newValues[val];
          }
        });
      }
      setEditData({
        componentId,
        values: newValues,
      });
    } else {
      if (values[id] !== value || (!values[id] && value == null)) {
        if ((value === '' || value === null) && values[id] !== undefined) {
          if (type === 'check' || type === 'checkSelect') values[id] = false;
          else delete values[id];
          setEditData({
            componentId,
            values: {
              ...values,
            },
          });
        } else {
          setEditData({
            componentId,
            values: {
              ...values,
              [id]: value,
            },
          });
        }
      }
    }

    //FORM HAS CHANGE VERIFICATION

    if (!isEmpty(selectedRow)) {
      if (!formHasChanged) {
        const control =
          type === 'number' ||
          type === 'currency' ||
          type === 'percent' ||
          type === 'combo'
            ? value.toString() !== values[id] && values[id].toString()
            : value !== values[id];

        setFormStateFlag({
          componentId: targetId,
          formHasChanged: control,
        });
      }
    } else if (isEmpty(selectedRow)) {
      for (let key in values) {
        if (
          values[key] !== '' &&
          values[key] !== null &&
          values[key] !== false &&
          values[key] !== undefined &&
          typeof selectedRow[key] !== Object &&
          value !== values[id]
        ) {
          formChange = true;
        }
      }
      setFormStateFlag({
        componentId: targetId,
        formHasChanged: formChange,
      });
    }
  };

  transformMomentToUTC = (values) => {
    Object.keys(values).forEach((key) => {
      if (moment.isMoment(values[key])) {
        const month = values[key].format('MM');
        const day = values[key].format('DD');
        const year = values[key].format('YYYY');
        const hour = values[key].format('HH');
        const minute = values[key].format('mm');
        const second = values[key].format('ss');
        values[key] = new Date(
          Date.UTC(year, month - 1, day, hour, minute, second)
        );
      }
    });
    return values;
  };

  handleUpdateRecord = (e, customButton, drawerOptions) => {
    if (!customButton) e.preventDefault();
    const {
      targetId,
      params,
      form,
      updateEditData,
      getTableData,
      formHasChanged,
      setFormStateFlag,
      setSelectedRow,
    } = this.props;
    let { values } = this.props;
    const { componentId, primaryKey } = params;

    form.validateFields(async (err, formValues) => {
      if (!err) {
        values = this.transformMomentToUTC(values);
        let result;
        result = await updateEditData({
          dataPath: components[componentId].path,
          componentId,
          values,
        });
        if (result.status === 200 && isTableTarget(targetId)) {
          const newRecords = await getTableData({
            dataPath: components[targetId].path,
            componentId: targetId,
          });

          let newSelectedRow;
          const { content } = newRecords.data;
          content.forEach((record) => {
            if (record[primaryKey] === values[primaryKey])
              newSelectedRow = record;
          });

          setSelectedRow({
            componentId: targetId,
            selectedRow: newSelectedRow,
          });

          if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
            setFormStateFlag({
              componentId: targetId,
              formHasChanged: false,
            });
          }
          config.FEEDBACK.REVERT.SHOW &&
            formHasChanged &&
            this.openNotification();
          if (drawerOptions)
            drawerOptions.setDrawerVisibility({
              dashboardId: drawerOptions.dashboardId,
              visible: drawerOptions.visible,
            });
        }
      } else this.handleGroupMandatory();
    });
  };

  handleCreateRecord = (e, customButton, select, drawerOptions) => {
    if (!customButton) e.preventDefault();
    const {
      targetId,
      params,
      selectedRow,
      form,
      createEditData,
      data,
      formHasChanged,
      setFormStateFlag,
      setSelectedRow,
      getTableData,
      queryParams,
      prettierKey,
      setChildSelectedRow,
    } = this.props;
    let { values } = this.props;
    const { componentId, primaryKey } = params;
    let qParams = {};

    form.validateFields(async (err, formValues) => {
      if (!err) {
        values = this.transformMomentToUTC(values);
        if (!isEmpty(selectedRow)) delete values[primaryKey];
        feedback({
          type: 'message',
          method: 'loading',
          message: 'generic.loading',
        });
        let result;
        result = await createEditData({
          dataPath: components[componentId].path,
          componentId,
          values,
        });
        const selectRow = select !== undefined ? true : false;
        if (result.status === 200 && isTableTarget(targetId)) {
          if (selectRow || (customButton && customButton.createAndSelect)) {
            const requestData = await getTableData({
              dataPath: `${components[targetId].path}/Page/${result.data[primaryKey]}`,
              componentId: targetId,
              queryParams,
            });

            requestData.data.content.forEach((element) => {
              if (element[primaryKey] === result.data[primaryKey]) {
                setSelectedRow({ componentId: targetId, selectedRow: element });
                const targetsId = components[targetId].targetsId;
                setChildSelectedRow({
                  targetsId,
                  record: element,
                  prettierKey,
                  rowKey: primaryKey,
                });
              }
            });
          } else {
            const page =
              data.totalElements % data.size === 0
                ? data.totalPages
                : data.totalPages - 1;
            qParams = {
              page: page,
              sort: null,
              field: null,
            };
            await getTableData({
              dataPath: components[targetId].path,
              componentId: targetId,
              queryParams: qParams,
            });
            this.handleResetForm();
          }

          if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
            setFormStateFlag({
              componentId: targetId,
              formHasChanged: false,
            });
          }
          if (drawerOptions)
            drawerOptions.setDrawerVisibility({
              dashboardId: drawerOptions.dashboardId,
              visible: drawerOptions.visible,
            });
        } else form.resetFields();
      } else this.handleGroupMandatory();
    });
  };

  /**This function set active tab key in redux (selectedTab)
   * @param activeKey - Next tab key to set
   */
  handleChangeTab = (activeKey) => {
    const { setSelectedTabEdit, params } = this.props;
    const { componentId } = params;
    setSelectedTabEdit({
      componentId,
      selectedTab: activeKey,
    });
  };

  handleGroupMandatory = () => {
    const { groups, fields, intl, selectedTab, values } = this.props;
    if (groups.length > 1) {
      const mandatoryField = [];
      fields.forEach((field) => {
        let groupTab;
        if (field.mandatory && values[field.key] === undefined) {
          groups.forEach((groupsRow) => {
            groupsRow.subgroups.forEach((subgroup) => {
              subgroup.fields.forEach((f) => {
                if (f.key === field.key) {
                  groupTab = groupsRow.index;
                }
              });
            });
          });
          // //in case to be inside the same group error tab.
          if (
            groupTab === undefined ||
            groupTab.toString() === selectedTab.toString()
          )
            return null;

          if (!mandatoryField.includes(field.mandatory, field.key)) {
            mandatoryField.push(field.mandatory);
            const dataError = [];
            fields.forEach((field) => {
              let groupTab;
              let groupTitle;
              if (field.mandatory && values[field.key] === undefined) {
                groups.forEach((groupsRow) => {
                  groupsRow.subgroups.forEach((subgroup) => {
                    if (subgroup.mustRender)
                      subgroup.fields.forEach((f) => {
                        if (f.key === field.key && f.mustRender !== false) {
                          groupTitle = groupsRow.title;
                          groupTab = groupsRow.index;
                        }
                      });
                  });
                });
                if (
                  groupTab === undefined ||
                  groupTab.toString() === selectedTab.toString()
                )
                  return null;
                return groupTitle && field.title !== null
                  ? dataError.push(groupTitle.concat(' - ' + field.title))
                  : null;
              }
            });
            // in case the multiple error show one message with all mandatory empty.
            error({
              message: intl.formatMessage({
                id: 'alert.title.required',
              }),
              duration: 0,
              description: (
                <List
                  size="small"
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                  dataSource={dataError}
                />
              ),
            });
          }
        }
      });
    }
  };

  hanldeResetDrawerForm = async () => {
    const {
      targetId,
      setSelectedRow,
      setFormStateFlag,
      resetEditForm,
      fields,
      params,
      fieldsConfig,
      userPermissions,
      queryParams,
    } = this.props;

    const {
      changeFieldsConfig,
      setBehaviourInitialState,
      setFormInitialValues,
    } = this;
    const { componentId } = params;
    const { q } = queryParams;

    setSelectedRow({
      componentId: targetId,
      selectedRow: {},
    });
    setFormStateFlag({
      componentId: targetId,
      formHasChanged: false,
    });
    const fieldsConfigBehaviourSelector = (field) =>
      setBehaviourInitialState(field);

    const newFieldsConfig = changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector
    );

    let vals = await setFormInitialValues(fields, userPermissions);

    if (!isEmpty(q) && config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
      q.split(config.QUERY.AND).forEach((element) => {
        const key = element.split(':')[0];
        const value = element.split(':')[1];
        fields.forEach((field) => {
          if (field.key === key && value)
            vals = { ...vals, [key]: value.toString() };
        });
      });
    }

    await resetEditForm({
      componentId,
      fieldsConfig: newFieldsConfig,
      values: vals,
    });
  };

  handleAddData = async () => {
    const { targetId, formHasChanged, intl } = this.props;

    if (isTableTarget(targetId)) {
      if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
        Modal.confirm({
          title: intl.formatMessage({ id: 'pop.title.select' }),
          content: intl.formatMessage({ id: 'pop.title.select.warn' }),
          okText: intl.formatMessage({ id: 'pop.accept' }),
          cancelText: intl.formatMessage({ id: 'pop.cancel' }),
          // icon: 'warning',
          maskClosable: true,
          async onOk() {
            this.hanldeResetDrawerForm();
          },
          onCancel() {},
        });
      } else {
        this.hanldeResetDrawerForm();
      }
    }
  };

  handleDeleteData = async () => {
    const {
      targetId,
      params,
      selectedRow,
      deleteTableData,
      setDrawerVisibility,
      values,
      dashboardId,
    } = this.props;
    const { primaryKey } = params;
    if (isTableTarget(targetId)) {
      feedback({
        type: 'message',
        method: 'loading',
        message: 'generic.loading',
      });
      const result = await deleteTableData({
        dataPath: components[targetId].path,
        componentId: targetId,
        selectedRowKeys: [selectedRow[primaryKey]],
        values,
        primaryKey,
      });
      if (result.status === 200)
        setDrawerVisibility({ dashboardId, visible: false });
    }
  };

  handleResetForm = async () => {
    const {
      params,
      targetId,
      selectedRow,
      formHasChanged,
      setFormStateFlag,
      fields,
      fieldsConfig,
      resetEditForm,
      queryParams,
      userPermissions,
    } = this.props;
    const { componentId } = params;
    const { q } = queryParams;

    let values = isEmpty(selectedRow)
      ? await this.setFormInitialValues(fields, userPermissions)
      : flatten(selectedRow);

    //PLEASE DON'T TOUCH THIS OR BEHAVIOURS WILL BREAK !
    setFormStateFlag({
      componentId: targetId,
      formHasChanged: false,
    });

    const fieldsConfigBehaviourSelector = (field) => {
      if (field.hasOwnProperty('behaviours')) {
        let control = true;
        field.behaviours.forEach((behaviour) => {
          if (values[behaviour.key] === undefined) {
            control = false;
          }
        });
        if (!control) {
          return this.setBehaviourInitialState(field);
        } else {
          if (field.hasOwnProperty('initialVisibility')) {
            field.visible = !field.initialVisibility;
          }
          if (field.hasOwnProperty('initialDisabled')) {
            field.disabled = !field.initialDisabled;
          }
          if (field.hasOwnProperty('initialMandatory')) {
            field.mandatory = !field.initialMandatory;
          }
        }
      } else {
        return this.setBehaviourInitialState(field);
      }
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector
    );

    if (!isEmpty(q) && config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
      q.split(config.QUERY.AND).forEach((element) => {
        const key = element.split(':')[0];
        const value = element.split(':')[1];
        fields.forEach((field) => {
          if (field.key === key && value)
            values = { ...values, [key]: value.toString() };
        });
      });
    }

    if (formHasChanged) {
      await resetEditForm({
        componentId,
        fieldsConfig: newFieldsConfig,
        values,
      });
    }
  };

  closeDrawer = () => {
    const {
      params,
      targetId,
      dashboardId,
      setDrawerVisibility,
      setFormStateFlag,
      setSelectedRow,
      selectedRow,
      setSelectedTabEdit,
    } = this.props;
    const { componentId } = params;
    setSelectedRow({
      componentId: targetId,
      selectedRow: selectedRow,
    });
    setDrawerVisibility({ dashboardId, visible: false });
    setFormStateFlag({
      componentId: targetId,
      formHasChanged: false,
    });
    setSelectedTabEdit({
      componentId,
      selectedTab: '0',
    });
  };

  handleCloseForm = async () => {
    const {
      params,
      targetId,
      dashboardId,
      formHasChanged,
      setDrawerVisibility,
      setFormStateFlag,
      setSelectedRow,
      selectedRow,
      setSelectedTabEdit,
    } = this.props;
    const { componentId } = params;
    if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
      Modal.confirm({
        title: this.props.intl.formatMessage({ id: 'pop.title.select' }),
        content: this.props.intl.formatMessage({ id: 'pop.title.select.warn' }),
        okText: this.props.intl.formatMessage({ id: 'pop.accept' }),
        cancelText: this.props.intl.formatMessage({ id: 'pop.cancel' }),
        // icon: 'warning',
        maskClosable: true,
        onOk() {
          setSelectedRow({
            componentId: targetId,
            selectedRow: selectedRow,
          });
          setDrawerVisibility({ dashboardId, visible: false });
          setFormStateFlag({
            componentId: targetId,
            formHasChanged: false,
          });
          setSelectedTabEdit({
            componentId,
            selectedTab: '0',
          });
        },
        onCancel() {},
      });
    } else {
      setSelectedRow({
        componentId: targetId,
        selectedRow: selectedRow,
      });
      setDrawerVisibility({ dashboardId, visible: false });
      setFormStateFlag({
        componentId: targetId,
        formHasChanged: false,
      });
      setSelectedTabEdit({
        componentId,
        selectedTab: '0',
      });
    }
  };

  handleRevertRecord = async (e) => {
    e.preventDefault();
    const {
      targetId,
      params,
      selectedRow,
      form,
      updateEditData,
      getTableData,
      setEditData,
      formHasChanged,
      setFormStateFlag,
    } = this.props;
    const { componentId } = params;

    feedback({
      type: 'message',
      method: 'loading',
      message: 'generic.loading',
    });

    try {
      await updateEditData({
        dataPath: components[componentId].path,
        componentId,
        values: flatten(selectedRow),
      });

      if (isTableTarget(targetId)) {
        getTableData({
          dataPath: components[targetId].path,
          componentId: targetId,
        });
        setEditData({
          componentId,
          values: flatten(selectedRow),
        });
        form.resetFields();

        if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
          setFormStateFlag({
            componentId: targetId,
            formHasChanged: false,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  openNotification = () => {
    const { intl } = this.props;
    const key = 'updatable';
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={(e) => {
          this.handleRevertRecord(e);
          notification.destroy();
        }}
      >
        {intl.formatMessage({ id: 'checkbox.true' })}
      </Button>
    );

    notification.open({
      key,
      maxCount: 1,
      placement: 'bottomLeft',
      duration: config.FEEDBACK.REVERT.DURATION,
      message: intl.formatMessage({ id: 'revert.confirmation' }),
      btn,
    });
  };

  /**
   * This function is used to set new parents value on a combo child.
   * @param {String} key Field key from the combo child.
   * @param {String} value New combo parent value
   */
  setParentValue = (key, value) => {
    const { setFieldsConfig, params, fieldsConfig } = this.props;
    const { componentId } = params;

    const fieldsConfigBehaviourSelector = (field) => {
      if (field.key === key) field.parentValue = value;
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector
    );
    setFieldsConfig(componentId, newFieldsConfig);
  };

  handleChildBehaviours = (
    targetKey,
    behavioursDisplayed,
    forceVisible,
    forceDisabled,
    queryForceDisabled
  ) => {
    const { setFieldsConfig, params, fieldsConfig } = this.props;
    const { componentId } = params;

    const fieldsConfigBehaviourSelector = (field) => {
      if (field.key === targetKey) {
        if (forceVisible !== null && forceVisible !== undefined)
          field.visible = forceVisible;
        else if (behavioursDisplayed.hasOwnProperty('visible'))
          field.visible = behavioursDisplayed['visible'];

        if (field.type === 'combo' && field.hasOwnProperty('parentValue')) {
          if (behavioursDisplayed.hasOwnProperty('disabled')) {
            field.disabled = behavioursDisplayed['disabled'];
          }
        } else {
          if (forceDisabled !== null && forceDisabled !== undefined)
            field.disabled = forceDisabled;
          else if (queryForceDisabled !== undefined) field.disabled = true;
          else if (behavioursDisplayed.hasOwnProperty('disabled'))
            field.disabled = behavioursDisplayed['disabled'];
          field.behaviourDisabled = behavioursDisplayed['disabled'];
        }

        if (behavioursDisplayed.hasOwnProperty('mandatory'))
          field.mandatory = behavioursDisplayed['mandatory'];
      }
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector
    );

    setFieldsConfig(componentId, newFieldsConfig);
  };

  handleResourceError = () =>
    feedback({
      type: 'notification',
      method: 'error',
      message: 'image.notfound',
    });

  /**
   * Updates the options of a child combo depending on parent selected item.
   * @param target {Object} - object with the information of the child.
   * @param id {int} - the identifier of the selected item in parent combo.
   */
  updateChildCombo = (comboId, id, key) => {
    const { getComboData, params, form } = this.props;
    const { componentId } = params;
    let queryParams = { id: comboId };
    if (id) queryParams = { ...queryParams, param: id };
    getComboData({
      dataPath: apiPaths.COMBO,
      comboId,
      fieldKey: key,
      componentId,
      queryParams,
    });
    form.resetFields();
  };

  changeFieldsConfig = (fieldsConfig, fieldsConfigBehaviourSelector) => {
    fieldsConfig.forEach((field) => {
      field.subgroupRows.forEach((subgroupRow) => {
        subgroupRow.forEach((subgroup) => {
          subgroup.fieldRow.forEach((fieldRow) => {
            fieldRow.forEach((field) => {
              if (fieldsConfigBehaviourSelector(field))
                field = fieldsConfigBehaviourSelector(field);
            });
          });
        });
      });
    });
    return fieldsConfig;
  };

  render() {
    const { fieldsConfig } = this.props;
    if (!isEmpty(fieldsConfig)) {
      return <EditFormRender {...this} />;
    }
    return null;
  }
}

EditForm.propTypes = {
  params: PropTypes.object,
  groups: PropTypes.array,
  options: PropTypes.object,
  targetId: PropTypes.string,
  fieldsConfig: PropTypes.array,
  selectedRow: PropTypes.object,
  values: PropTypes.object,
  combos: PropTypes.object,
  isLoading: PropTypes.bool,
  selectedTab: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    targetId: state.edits[ownProps.params.componentId].targetId,
    fieldsConfig: state.edits[ownProps.params.componentId].fieldsConfig,
    hasNavigated: state.query.editFormNavigation,
    resetFormTrigger: state.edits[ownProps.params.componentId].resetFormTrigger,
    selectedRow:
      state.tables[components[ownProps.params.componentId].targetId]
        .selectedRow,
    values: state.edits[ownProps.params.componentId].values,
    parentData: state.edits[ownProps.params.componentId].parentData,
    combos: state.combos,
    formHasChanged:
      state.tables[components[ownProps.params.componentId].targetId]
        .formHasChanged,
    isLoading: state.edits[ownProps.params.componentId].isLoading,
    data: state.tables[components[ownProps.params.componentId].targetId].data,
    prettierKey:
      state.tables[components[ownProps.params.componentId].targetId]
        .prettierKey,
    selectedTab: state.edits[ownProps.params.componentId].selectedTab,
    queryParams: state.query.params,
    accessToken: state.auth.accessToken,
    router: state.router.location.state,
    currentPath: state.router.location.pathname,
    userPermissions: state.app.permissions,
    appParams: state.app.appParams,
    userParams: state.app.user,
  };
};

EditForm = injectIntl(EditForm);
const WrappedEditForm = Form.create()(EditForm);

export default connect(mapStateToProps, {
  setInitialState,
  deleteTableData,
  getTableData,
  getComboData,
  setEditData,
  updateEditData,
  createEditData,
  fetchForeignData,
  setFieldsConfig,
  setFormStateFlag,
  setSelectedTabEdit,
  logout,
  editNatigationState,
  resetEditForm,
  setResetFormTrigger,
  navigateAfterCreate,
  setDrawerVisibility,
  setChildSelectedRow,
  fetchParentData,
})(WrappedEditForm);
