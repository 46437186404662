const checkOperands = (fieldValue, behaviourValue, operand) => {
  if (fieldValue === undefined || fieldValue === null) return false;
  switch (operand) {
    case 'like':
      if (fieldValue !== behaviourValue) return false;
      break;
    case 'greater':
      if (fieldValue <= behaviourValue) return false;
      break;
    case 'less':
      if (fieldValue >= behaviourValue) return false;
      break;
    case 'greater|equal':
      if (fieldValue > behaviourValue) return false;
      break;
    case 'less|equal':
      if (fieldValue < behaviourValue) return false;
      break;
    default:
      break;
  }
  return true;
};

export const checkBehaviour = (behaviour, values) => {
  if (!values) return false;
  else if (!values.hasOwnProperty(`${behaviour.key}`)) {
    if (!behaviour.value && behaviour.value !== '') return true; //    if (!behaviour.value) return true;
    return false;
  } else
    return checkOperands(
      values[behaviour.key].toString(),
      behaviour.value,
      behaviour.operand
    );
};

export const checkElementBehaviours = (elements, values) => {
  elements.forEach(element => {
    let throwBehaviours = {};
    let isGroupVisible;
    let isGroupDisabled;
    if (element.hasOwnProperty('behaviours')) {
      element.behaviours.forEach(behaviourGroups => {
        let behavioursComponent = { visible: true, disabled: true };
        behaviourGroups.forEach(behaviour => {
          if (behaviour.type === 'visible') {
            if (!checkBehaviour(behaviour, values))
              behavioursComponent.visible = false;
          }
          if (behaviour.type === 'disabled') {
            if (!checkBehaviour(behaviour, values))
              behavioursComponent.disabled = false;
          }
        });
        if (behavioursComponent['visible']) isGroupVisible = true;
        if (behavioursComponent['disabled']) isGroupDisabled = true;
      });
      throwBehaviours.visible = isGroupVisible ? true : false;
      throwBehaviours.disabled = isGroupDisabled ? true : false;
    }
    for (let key in throwBehaviours) {
      if (key === 'visible' && element.hasOwnProperty('initialVisibility')) {
        if (throwBehaviours[key]) element.visible = !element.initialVisibility;
        else element.visible = element.initialVisibility;
      } else if (
        key === 'disabled' &&
        element.hasOwnProperty('initialDisabled')
      ) {
        if (throwBehaviours[key]) element.disabled = !element.initialDisabled;
        else element.disabled = element.initialDisabled;
      }
    }
  });
  return elements;
};
