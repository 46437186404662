import React from 'react';
import { Button, Form, Row, Icon, Popconfirm } from 'antd';
import { isEmpty } from 'lodash';

import { checkBehaviour } from '../behaviourUtils';
import config from '../../config';
import * as customButtonsFile from '../../extensions/buttons/customButtons';

import '../FormStyles.css';

const ButtonGroup = Button.Group;

const checkButtonPermissions = (button, userPermissions) => {
  let disabled;
  userPermissions.forEach(permission => {
    button.permissions.forEach(perm => {
      if (permission === perm.name) {
        disabled = perm.disabled;
      }
    });
  });
  return disabled;
};

const checkButtonBehaviours = (button, values) => {
  let disabled;
  button.behaviours.forEach(behaviour => {
    if (checkBehaviour(behaviour, values)) disabled = !button.defaultDisabled;
  });
  if (disabled === undefined) return button.defaultDisabled;
  else return disabled;
};

const checkDisabledButton = (button, values, userPermissions) => {
  let disabled;

  if (typeof button === 'object') {
    button.permissions
      ? (disabled = checkButtonPermissions(button, userPermissions))
      : (disabled = checkButtonBehaviours(button, values));
    if (disabled === undefined) disabled = button.defaultDisabled;
  } else disabled = !button;

  return disabled;
};

export const buttonsContainerTop = parentProps => {
  const { props, handleDeleteData } = parentProps;
  const { selectedRow, intl, options, values, userPermissions, params } = props;
  const { canDelete } = options;
  const { customButtons } = params;

  let deleteDisable = checkDisabledButton(canDelete, values, userPermissions);

  const deleteButton = config.FEEDBACK.CONFIRM.DELETE_RECORD ? (
    <Popconfirm
      disabled={isEmpty(values) || isEmpty(selectedRow) || deleteDisable}
      title={intl.formatMessage({ id: 'pop.title.delete' })}
      icon={<Icon type="exclamation-circle" style={{ color: 'red' }} />}
      onConfirm={handleDeleteData}
      okText={intl.formatMessage({ id: 'pop.accept' })}
      cancelText={intl.formatMessage({ id: 'pop.cancel' })}
    >
      <Button
        type="danger"
        icon="delete"
        disabled={isEmpty(values) || isEmpty(selectedRow) || deleteDisable}
        title={intl.formatMessage({ id: 'form.delete' })}
      />
    </Popconfirm>
  ) : (
    <Button
      type="danger"
      icon="delete"
      disabled={isEmpty(values) || isEmpty(selectedRow) || deleteDisable}
      title={intl.formatMessage({ id: 'form.delete' })}
      onClick={handleDeleteData}
    />
  );

  return (
    <>
      <Row>
        <ButtonGroup className="form__upperButtons">
          {canDelete !== undefined && deleteButton}
        </ButtonGroup>
        {customButtons
          ? customButtons.map(customButton =>
              customButtonsFile[customButton](parentProps)
            )
          : null}
      </Row>
    </>
  );
};

export const buttonsContainerBottom = parentProps => {
  const {
    props,
    handleUpdateRecord,
    handleResetForm,
    handleCloseForm,
    handleCreateRecord
  } = parentProps;
  const {
    selectedRow,
    isLoading,
    intl,
    options,
    values,
    userPermissions,
    setDrawerVisibility,
    dashboardId
  } = props;
  const {
    canDuplicate,
    createAndSelect,
    canCreateClose,
    canCreate,
    canEdit,
    canEditClose
  } = options;

  const createDisabled = checkDisabledButton(
    canCreate,
    values,
    userPermissions
  );
  const editDisabled = checkDisabledButton(canEdit, values, userPermissions);
  const editCloseDisabled = checkDisabledButton(
    canEditClose,
    values,
    userPermissions
  );
  const duplicateDisabled = checkDisabledButton(
    canDuplicate,
    values,
    userPermissions
  );
  const createAndSelectDisabled = checkDisabledButton(
    createAndSelect,
    values,
    userPermissions
  );
  const createCloseDisabled = checkDisabledButton(
    canCreateClose,
    values,
    userPermissions
  );

  const createSelectButton = createAndSelect && (
    <Form.Item className="form__bottomButtons--floatRight">
      <Button
        icon="save"
        onClick={e => handleCreateRecord(e, false, true)}
        title={intl.formatMessage({ id: 'form.createSelect' })}
        disabled={isLoading || isEmpty(values) || createAndSelectDisabled}
        loading={isLoading}
      >
        {intl.formatMessage({ id: 'form.createSelect' })}
      </Button>
    </Form.Item>
  );
  const createCloseButton = canCreateClose && (
    <Form.Item className="form__bottomButtons--floatRight">
      <Button
        icon="save"
        title={intl.formatMessage({ id: 'form.createClose' })}
        disabled={isLoading || isEmpty(values) || createCloseDisabled}
        loading={isLoading}
        onClick={e => createAndClose(e)}
      >
        {intl.formatMessage({ id: 'form.createClose' })}
      </Button>
    </Form.Item>
  );

  const createCleanButton = canCreate && (
    <Form.Item className="form__bottomButtons--floatRight">
      <Button
        icon="save"
        htmlType="submit"
        title="Crear y Limpiar"
        disabled={isLoading || isEmpty(values) || createDisabled}
        loading={isLoading}
      >
        {intl.formatMessage({ id: 'form.create' })}
      </Button>
    </Form.Item>
  );

  const saveAndClose = async e => {
    const drawerOptions = { setDrawerVisibility, dashboardId, visible: false };
    await handleUpdateRecord(e, null, drawerOptions);
  };

  const createAndClose = async e => {
    const drawerOptions = { setDrawerVisibility, dashboardId, visible: false };
    await handleCreateRecord(e, false, true, drawerOptions);
  };

  return (
    <Row className="form__bottomButtons">
      {isEmpty(selectedRow) ? (
        <>
          {createCleanButton !== undefined ? createCleanButton : null}
          {createAndSelect !== undefined ? createSelectButton : null}
          {canCreateClose !== undefined ? createCloseButton : null}
        </>
      ) : (
        <React.Fragment>
          {canDuplicate !== undefined && (
            <Form.Item className="form__bottomButtons--floatRight">
              <Button
                icon="copy"
                htmlType="submit"
                title={intl.formatMessage({ id: 'form.duplicate.reg' })}
                disabled={isLoading || isEmpty(values) || duplicateDisabled}
                loading={isLoading}
              >
                {intl.formatMessage({ id: 'form.duplicate' })}
              </Button>
            </Form.Item>
          )}
          {canEdit !== undefined && (
            <Form.Item className="form__bottomButtons--floatRight">
              <Button
                icon="save"
                title={intl.formatMessage({ id: 'form.save.reg' })}
                onClick={handleUpdateRecord}
                disabled={isLoading || isEmpty(values) || editDisabled}
                loading={isLoading}
              >
                {intl.formatMessage({ id: 'form.save' })}
              </Button>
            </Form.Item>
          )}
          {canEditClose !== undefined && (
            <Form.Item className="form__bottomButtons--floatRight">
              <Button
                icon="save"
                title={intl.formatMessage({ id: 'form.save.reg.close' })}
                onClick={e => saveAndClose(e)}
                disabled={isLoading || isEmpty(values) || editCloseDisabled}
                loading={isLoading}
              >
                {intl.formatMessage({ id: 'form.save.close' })}
              </Button>
            </Form.Item>
          )}
        </React.Fragment>
      )}
      <Form.Item className="form__bottomButtons--floatRight">
        <Button
          icon="reload"
          onClick={handleResetForm}
          title={intl.formatMessage({ id: 'form.reset' })}
        >
          {intl.formatMessage({ id: 'pop.restore' })}
        </Button>
      </Form.Item>
      <Form.Item className="form__bottomButtons--floatRight">
        <Button
          icon="close-square-o"
          onClick={handleCloseForm}
          title={intl.formatMessage({ id: 'form.edit.cancel' })}
        >
          {intl.formatMessage({ id: 'form.edit.cancel' })}
        </Button>
      </Form.Item>
    </Row>
  );
};
