import { isEmpty } from 'lodash';
import * as types from './appTypes';
import configDev from '../configDev.js';
import { getDataCall, accessCall } from '../api';
import mockData from '../mockData.js';
import apiPaths from '../apiPaths';
import { feedback } from '../utils/feedback';

const formattedMenu = (menuItems, permissions) => {
  let formattedMenuItems = [];
  console.log('menuItems: ', menuItems);
  menuItems.forEach((menuItem) => {
    let menu = {
      ...menuItem,
      nameOfMenu: menuItem.nameOfMenu.includes('.')
        ? menuItem.nameOfMenu
            .split('.')
            .slice(-1)
            .toString()
        : menuItem.nameOfMenu,
      route: `/${menuItem.route.split('/')[1]}`,
      welcome: true, //todo remove hardcoded
      disabled: false, //todo remove hardcoded
      mustRender: true, //todo remove hardcoded
      permission: null, //todo remove hardcoded
    };

    if (!isEmpty(menuItem.subMenu)) {
      menu.welcome = false;
      menu.subMenu = [];
      menuItem.subMenu.forEach((sub) => {
        menu.subMenu.push({
          ...sub,
          nameOfMenu: sub.nameOfMenu.includes('.')
            ? sub.nameOfMenu
                .split('.')
                .slice(-1)
                .toString()
            : sub.nameOfMenu,
          route: `/${sub.route.split('/')[1]}`,
          welcome: true, //todo remove hardcoded
          disabled: false, //todo remove hardcoded
          mustRender: true, //todo remove hardcoded
          permission: null, //todo remove hardcoded
        });
      });
    }

    formattedMenuItems.push(menu);
  });
  return formattedMenuItems;
};
const securedMenu = (menuItems, permissions) => {
  let newMenu = [];
  let noSubMenu = false;
  menuItems.forEach((menuItem) => {
    let newSubMenu = [];
    noSubMenu = false;
    if (!isEmpty(menuItem.subMenu)) {
      menuItem.subMenu.forEach((subMenuItem) => {
        newSubMenu.push(subMenuItem);
      });
    } else {
      noSubMenu = true;
    }
    if (!isEmpty(newSubMenu) || (isEmpty(newSubMenu) && noSubMenu)) {
      const newMenuItem = {
        ...menuItem,
        subMenu: newSubMenu,
      };
      newMenu.push(newMenuItem);
    }
  });
  return newMenu;
};

export const access = () => async (dispatch, getState) => {
  try {
    const menu = getState().app.menu;
    let menuItems = {};
    let permissionsItems = [];
    let paramsItems = {};

    const user = getState().app.user;
    let userItems = {};

    // if (isEmpty(user)) {
    //   if (configDev.LOAD_MOCK_USER) {
    //     userItems = mockData.USER;
    //   } else {
    //     const response = await getDataCall({
    //       dataPath: apiPaths.AUTH.USER,
    //     });
    //     userItems = response.data;
    //   }
    // }

    if (menu === null) {
      if (configDev.LOAD_MOCK_MENU) {
        menuItems = mockData.MENU;
      } else {
        const response = await accessCall({
          dataPath: apiPaths.ACCESS,
          user,
        });
        menuItems = securedMenu(response.data.menuItems, permissionsItems);

        if (menuItems === [])
          feedback({
            type: 'notification',
            method: 'warning',
            message:
              'No tienes permsisos para acceder a ninguna pantalla. Contacta con el Administrador.',
            duration: 10,
          });
      }
    }

    dispatch({
      type: types.APP_ACCESS_DATA,
      payload: {
        menu: menuItems,
        permissions: permissionsItems,
        appParams: paramsItems,
        user: userItems,
      },
    });
    return true;
  } catch (err) {
    if (!err.response) return { action: 'fetch', status: {} };
    const status = { action: 'fetch', status: err.response.status };
    return status;
  }
};

export const setLanguage = (lang) => (dispatch, getState) => {
  if (lang === 'es' || lang === 'es-ES') lang = 'es-ES';
  else if (lang === 'ca' || lang === 'ca-ES') lang = 'ca-ES';
  else if (lang === 'en' || lang === 'en-US') lang = 'en-US';
  else lang = 'en-US';
  dispatch({ type: types.APP_SET_LANGUAGE, payload: lang });
};

export const setMenuState = ({ menuState }) => async (dispatch) => {
  dispatch({
    type: types.APP_MENU_STATE,
    payload: { menuState },
  });
};
