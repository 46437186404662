import * as types from './editTypes';
import * as api from '../../api';
import apiPaths from '../../apiPaths';

const flatten = require('flat');
const unflatten = require('flat').unflatten;

export const setEditInitialState = ({ componentId }) => dispatch => {
  dispatch({
    type: types.SET_INITIAL_EDIT_STATE,
    payload: { componentId }
  });
};

export const setInitialState = ({
  componentId,
  targetId,
  fieldsConfig = [],
  values = {},
  selectedTab,
  resetFormTrigger
}) => async dispatch => {
  dispatch({
    type: types.EDIT_INITIAL_STATE,
    payload: {
      componentId,
      targetId,
      fieldsConfig,
      values,
      selectedTab,
      resetFormTrigger
    }
  });
};

export const resetEditComponent = ({ componentId }) => dispatch => {
  dispatch({ type: types.EDIT_RESET_COMPONENT, payload: { componentId } });
};

export const setEditData = ({ componentId, values = {} }) => async dispatch => {
  dispatch({
    type: types.EDIT_SET_VALUES,
    payload: { componentId, values }
  });
};

export const fetchParentData = ({ componentId, data }) => dispatch => {
  dispatch({
    type: types.EDIT_FETCH_PARENT_DATA,
    payload: { componentId, data }
  });
};

export const fetchForeignData = ({
  dataPath,
  componentId,
  foreignKey,
  foreignValue
}) => async (dispatch, getState) => {
  dispatch({
    type: types.EDIT_FETCHING_DATA,
    payload: { componentId, isLoading: true }
  });
  try {
    const currentValues = getState().edits[componentId].values;
    const response = await api.getDataCallById({
      dataPath,
      registerId: foreignValue
    });
    Object.keys(currentValues).forEach(val => {
      if (foreignKey === val.split('.')[0]) {
        delete currentValues[val];
      }
    });
    dispatch({
      type: types.EDIT_FETCHED_DATA,
      payload: { componentId, isLoading: false }
    });
    const newValues = flatten({ [foreignKey]: response.data });

    const status = {
      action: 'fetch',
      status: response.status,
      data: newValues
    };
    return status;
  } catch (err) {
    if (!err.response) return { action: 'fetch', status: {} };
    const status = { action: 'fetch', status: err.response.status };
    return status;
  }
};

export const updateEditData = ({ dataPath, componentId, values }) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.EDIT_SENDING_DATA,
    payload: { componentId, isLoading: true }
  });
  try {
    const response = await api.putDataCall({
      dataPath,
      data: unflatten(values)
    });
    dispatch({
      type: types.EDIT_SENT_DATA,
      payload: { componentId, isLoading: false }
    });

    const status = {
      action: 'update',
      status: response.status,
      data: response.data
    };
    return status;
  } catch (err) {
    dispatch({
      type: types.EDIT_SENDING_DATA_ERROR,
      payload: { componentId, isLoading: false }
    });
    if (!err.response) return { action: 'update', status: {} };
    const status = {
      action: 'update',
      status: err.response.status,
      message: err.response.data.message
    };
    return status;
  }
};

const verifiedFormCheckFields = (formFields, values) => {
  let checkValues = {};
  formFields.forEach(f => {
    if (
      (f.type === 'checkSelect' && !values[f.key]) ||
      (f.type === 'switch' && !values[f.key])
    ) {
      checkValues[f.key] = false;
    }
  });
  return checkValues;
};

export const createEditData = ({
  dataPath,
  componentId,
  values = {}
}) => async (dispatch, getState) => {
  dispatch({
    type: types.EDIT_CREATING_DATA,
    payload: { componentId, isLoading: true }
  });
  try {
    const formFields = getState().edits[componentId].fieldsConfig;
    const valuesVerified = {
      ...values,
      ...verifiedFormCheckFields(formFields, values)
    };
    const response = await api.postDataCall({
      dataPath,
      data: unflatten(valuesVerified)
    });
    dispatch({
      type: types.EDIT_CREATED_DATA,
      payload: { componentId, isLoading: false }
    });

    const status = {
      action: 'create',
      status: response.status,
      data: response.data
    };
    return status;
  } catch (err) {
    dispatch({
      type: types.EDIT_SENDING_DATA_ERROR,
      payload: { componentId, isLoading: false }
    });

    if (!err.response) return { action: 'create', status: {} };
    const status = {
      action: 'create',
      status: err.response.status,
      message: err.response.data.message
    };
    return status;
  }
};

export const setSelectedTabEdit = ({
  componentId,
  selectedTab
}) => dispatch => {
  dispatch({
    type: types.EDIT_CHANGE_TAB,
    payload: { componentId, selectedTab }
  });
};

export const setFieldsConfig = (componentId, newFieldsConfig) => dispatch => {
  dispatch({
    type: types.EDIT_SET_FIELDSCONFIG,
    payload: { componentId, newFieldsConfig, resetFormTrigger: true }
  });
};

export const setResetFormTrigger = ({ componentId }) => dispatch => {
  dispatch({
    type: types.EDIT_CLEAN_RESET_FORM_TRIGGER,
    payload: { componentId, resetFormTrigger: false }
  });
};

export const resetEditForm = ({
  componentId,
  fieldsConfig,
  values
}) => dispatch => {
  dispatch({
    type: types.EDIT_RESET_FORM,
    payload: { componentId, values, fieldsConfig, resetFormTrigger: true }
  });
};

/**
 * This is an asyncronous call that allows you to add a new element inside the database and adding it to redux as well
 *
 * @param {string} dataPath the path of the element we want to add/modify
 * @param {string} componentId the name of the element inside of the components params, with this we just can identify the object
 * @param {Object} values the new values of the object
 *
 * @throws {Error} if the call is unsuccessfull
 * @returns {Object} the result of the asyncronous call to the server
 */
export const createTableData = ({
  dataPath,
  componentId,
  values = {}
}) => async dispatch => {
  dispatch({
    type: types.EDIT_CREATING_DATA,
    payload: { componentId, isLoading: true }
  });
  try {
    const response = await api.postDataCall({
      dataPath,
      data: unflatten(values)
    });
    dispatch({
      type: types.EDIT_CREATED_DATA,
      payload: { componentId, isLoading: false }
    });
    const status = {
      action: 'create',
      status: response.status,
      data: response.data
    };
    return status;
  } catch (err) {
    dispatch({
      type: types.EDIT_SENDING_DATA_ERROR,
      payload: { componentId, isLoading: false }
    });
    if (!err.response) return { action: 'create', status: {} };
    const status = {
      action: 'create',
      status: err.response.status,
      message: err.response.data.message
    };
    return status;
  }
};
