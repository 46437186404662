import React, { useState } from 'react';
import { Select } from 'antd';
import { qInputComposer } from '../utils/apiUtils';
import * as api from '../api';
import config from '../config';
import { isEmpty } from 'lodash';

const Option = Select.Option;

/**
 *Validates field inserted characters
 *Actually only accepts Alphanumeric values
 *Improvements: allow @ character in mail fields
 *@value {String} input value
 *@return True or False
 */
const validateFormat = value => {
  const regex = /^[a-zA-Z0-9_]*$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export default function Autocomplete(parentProps) {
  const [dataSource, setDataSource] = useState([]);
  const [filters, setFilters] = useState({
    size: config.AUTOCOMPLETE.FILTER_SIZE
  });
  const [content, setContent] = useState([]);
  const [optionSelected, setOptionSelected] = useState(true);
  const [optionsSize, setOptionsSize] = useState(
    config.AUTOCOMPLETE.OPTIONS_SIZE
  );
  const [moreContent, setMoreContent] = useState(false);

  //1. Deberá tener un state datasource
  //2. Tener cuidado en como se almacena el "VALUE" de this.state, puede que no simplemente sea una String...
  const {
    field,
    handleChangeField,
    importAutocompleteValues,
    props
  } = parentProps;
  const {
    values,
    options,
    params,
    combos,
    setEditData,
    setFormStateFlag,
    form,
    targetId
  } = props;
  const { autocompleteConfig, comboId, key } = field;
  const {
    primaryForeignKey,
    filterField,
    filterFieldDescription
  } = autocompleteConfig;
  const { componentId } = params;

  const handleSelect = event => {
    setOptionSelected(event.key);
    if (importAutocompleteValues === undefined) {
      content.forEach(record => {
        if (record[primaryForeignKey].toString() === event.key.toString()) {
          let newValues = {
            ...values,
            [field.key]: record[primaryForeignKey].toString()
          };

          setEditData({ componentId, values: newValues });
          setFormStateFlag({ componentId: targetId, formHasChanged: true });
          form.resetFields();
        }
      });
    } else
      importAutocompleteValues({
        content,
        event,
        primaryForeignKey,
        values,
        field
      });
  };

  const handleFocus = () => {
    setOptionSelected(false);
  };

  const handleBlur = () => {
    setOptionSelected(true);
  };

  const optionRender = options => {
    if (optionSelected) {
      const newOptions = [];
      if (
        combos[componentId] &&
        combos[componentId][key] &&
        combos[componentId][key][comboId] &&
        combos[componentId][key][comboId].data
      )
        combos[componentId][key][comboId].data.forEach(option => {
          if (
            values[key] &&
            option.value.toString() === values[key].toString()
          ) {
            newOptions.push(
              <Option
                key={option.value.toString()}
                value={option.value.toString()}
              >
                {option.description.toString()}
              </Option>
            );
          }
        });
      return newOptions;
    } else {
      if (options.length) {
        const newOptions = [];
        options.forEach(row => {
          let opt = '';
          let content = '';
          row.forEach((element, i) => {
            if (element.value) {
              content = element.value.toString();
            } else {
              content = 'no value';
            }
            if (i === row.length - 1) {
              opt += element.col.toString() + ': ' + content;
            } else {
              opt += element.col.toString() + ': ' + content + '  -  ';
            }
          });

          newOptions.push(
            <Option
              key={row[0].key}
              value={row[0].value.toString().concat('.', row[0].key.toString())}
            >
              {opt}
            </Option>
          );
        });
        return newOptions;
      } else return null;
    }
  };

  /**
   * @param {String} dataPath Call path - taken from Dashboard object props
   * @param {Object} queryParams
   * @param {Object} filters
   * This is an async function that composes queryParams and filters into a query.
   * It will launch this query to the API and wait untill backend send a response
   * The function will update state(dataSource) with the recieved values
   */
  const getInputData = async ({
    dataPath,
    queryParams = {},
    filters,
    value,
    fieldTargets
  }) => {
    const { primaryForeignKey, fieldsDescription } = field.autocompleteConfig;
    let nextParams = {};
    if (queryParams.q) {
      if (queryParams.size) {
        nextParams = queryParams;
      } else {
        nextParams = {
          q: queryParams.q,
          size: filters.size
        };
      }
    } else {
      nextParams = {
        ...filters,
        ...queryParams
      };
    }

    if (filterField !== undefined)
      nextParams.q +=
        config.QUERY.AND + `${filterFieldDescription}:${values[filterField]}`;

    try {
      const callConfig = {
        params: nextParams
      };

      const response = await api.getDataCall({
        dataPath,
        callConfig
      });

      const newData = [];
      let counter = 0;
      setContent(response.data.content);
      if (response.data.content.length >= 1) {
        response.data.content.forEach((row, i) => {
          fieldTargets.forEach(target => {
            if (
              row[target] !== null &&
              counter < optionsSize &&
              row[target]
                .toString()
                .toLowerCase()
                .indexOf(value.toString().toLowerCase()) >= 0
            ) {
              const targetValues = [];
              if (
                isEmpty(newData) ||
                newData[newData.length - 1][0].key !== row[primaryForeignKey]
              ) {
                fieldTargets.forEach((f, index) => {
                  targetValues.push({
                    key: row[primaryForeignKey],
                    value: row[f],
                    col: fieldsDescription[index]
                  });
                });
                newData.push(targetValues);
                counter++;
              }
            }
          });
        });

        if (newData.length >= optionsSize) {
          setOptionsSize(optionsSize + config.AUTOCOMPLETE.INCREMENT_SIZE);
          if (optionsSize >= newData.length) {
            const newFilters = {
              ...filters,
              size: filters.size + config.AUTOCOMPLETE.INCREMENT_SIZE
            };
            setDataSource(newData);
            setMoreContent(true);
            setFilters(newFilters);
          } else {
            setDataSource(newData);
            setMoreContent(true);
          }
        } else {
          setDataSource(newData);
          setMoreContent(false);
        }
        return response.data;
      } else {
        if (dataSource !== []) {
          setOptionsSize(config.AUTOCOMPLETE.OPTIONS_SIZE);
          setDataSource([]);
        }
        return [];
      }
    } catch (err) {
      if (!err.response) return { action: 'fetch', status: {} };
      const status = {
        action: 'fetch',
        status: err.response.status,
        message: err.response.data.message
      };
      return status;
    }
  };

  /**
   * This functions resets timeout. Recieve 3 params
   * @callback function to apply
   * @wait time until callback is launch
   * @immediate False - allows timeout reset
   */
  const debounce = (callback, wait, immediate = false) => {
    let timeout = null;

    return function() {
      const callNow = immediate && !timeout;
      const next = () => callback.apply(this, arguments);

      clearTimeout(timeout);
      timeout = setTimeout(next, wait);

      if (callNow) {
        next();
      }
    };
  };

  /**
   *This function allows, on Scroll Down & reach the bottom of the opctions, to launch
   *a new backend call and get extra entries and update state (dataSource and size)
   *If the user Scrolls Up or Scrolls down and he is not in bottom position works as a normal scroll
   */
  const handleScroll = debounce(
    async e => {
      const value = values[field.key];
      const { path } = autocompleteConfig;

      if (dataSource.length <= 0) return;

      const scroll = document.getElementsByClassName(
        'ant-select-dropdown-menu'
      )[0];

      if (
        scroll &&
        scroll.offsetHeight + scroll.scrollTop + 3 > scroll.scrollHeight &&
        moreContent
      ) {
        const q = qInputComposer(autocompleteConfig, value);

        await getInputData({
          dataPath: path,
          queryParams: { q },
          filters,
          value,
          fieldTargets: autocompleteConfig.fieldTargets
        });
      }
    },
    150,
    false
  );

  const handleSearch = debounce(
    async (value, parentProps) => {
      if (optionSelected !== undefined) {
        setOptionSelected(undefined);
      }
      const { path } = autocompleteConfig;
      const validate = validateFormat(value);
      if (validate) {
        if (value) {
          const q = qInputComposer(autocompleteConfig, value);
          await getInputData({
            dataPath: path,
            queryParams: { q },
            filters,
            value,
            fieldTargets: autocompleteConfig.fieldTargets
          });
          handleChangeField({ type: field.type, id: field.key, value });
        } else {
          setDataSource([]);
          setFilters({ size: config.AUTOCOMPLETE.FILTER_SIZE });
          setOptionsSize(config.AUTOCOMPLETE.OPTIONS_SIZE);
        }
      }
    },
    200,
    false
  );

  let notFound = '';
  if (values[field.key] === '') {
    notFound = 'Introducir valor';
  } else if (isEmpty(dataSource)) {
    notFound = 'No encontrado';
  }

  return (
    <Select
      {...this}
      allowClear
      type={field.type}
      onFocus={handleFocus}
      onBlur={handleBlur}
      placeholder={field.title}
      title={field.title}
      size={options.fieldSize}
      disabled={field.disabled}
      notFoundContent={notFound}
      showSearch
      showArrow={false}
      onPopupScroll={event => handleScroll(event)}
      onSelect={(key, event) => handleSelect(event)}
      onSearch={value => handleSearch(value, parentProps)}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {optionRender(dataSource)}
    </Select>
  );
}
