import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Col, Card, Row, Icon } from 'antd';
import { logout } from '../auth/authActions';
import { navigate } from '../app/queryActions';
import IconMenu from '../iconMenu';
import config from '../config';

import './Welcome.css';

export class Welcome extends Component {
  renderMenu = menu => {
    const menuItems = [];
    menu.forEach(
      ({
        id,
        route,
        nameOfMenu,
        iconOfMenu,
        subMenu,
        welcome,
        disabled,
        mustRender
      }) => {
        if (mustRender && !disabled && welcome)
          menuItems.push(this.renderCard(id, route, nameOfMenu, iconOfMenu));
        if (!isEmpty(subMenu)) menuItems.push(this.renderMenu(subMenu));
      }
    );
    return menuItems;
  };

  renderCard = (id, route, nameOfMenu, iconOfMenu) => {
    return (
      <Col key={id}>
        <div
          onClick={() =>
            this.props.navigate({
              path: route,
              name: nameOfMenu,
              icon: iconOfMenu,
              absolute: true
            })
          }
        >
          <Card key={id} className="menuWrapper__card">
            <div className="icon">{IconMenu({ id: iconOfMenu })}</div>
          </Card>
        </div>

        <p className="menuWrapper__text">
          {nameOfMenu}
          {/* {this.props.intl.formatMessage({ id: 'welcome.menuName.' + id })} */}
        </p>
      </Col>
    );
  };

  render() {
    const { logout, menu, intl } = this.props;
    return (
      <Row className="welcome">
        <Col md={8} xs={0} className="welcome__leftContent">
          <Row type="flex" justify="space-around" align="middle">
            <Col span={4}></Col>
            <Col span={16} className="welcome__leftContent">
              <Row type="flex" align="middle" style={{ height: '90vh' }}>
                <Row className="text--center">
                  {intl.formatMessage({ id: 'home.welcome_msg' })}
                  <div className="logo" />
                  <div className="enviroment text--white">
                    {config.APP.ENVIROMENT}
                  </div>
                </Row>
              </Row>
              <Row className="logout" style={{ height: '10vh' }}>
                <Icon className="icon" onClick={logout} type="logout" />
                <label
                  id="logout--text"
                  onClick={logout}
                  className="logout--text"
                >
                  {intl.formatMessage({ id: 'logout.title' })}
                </label>
              </Row>
            </Col>
            <Col span={4}></Col>
          </Row>
        </Col>
        <Col md={16} xs={24} className="welcome__rightContent">
          <Row type="flex" justify="space-around" className="menuWrapper">
            {!isEmpty(menu) ? this.renderMenu(menu) : false}
          </Row>
        </Col>
      </Row>
    );
  }
}

Welcome.propTypes = {
  logout: PropTypes.func,
  menu: PropTypes.array,
  intl: PropTypes.object
};

const mapStateToProps = state => ({
  menu: state.app.menu
});

export default connect(mapStateToProps, { logout, navigate })(Welcome);
