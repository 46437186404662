import * as types from './searchTypes';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_INITIAL_SEARCH_STATE:
      return {
        ...state,
        [action.payload.componentId]: {}
      };
    case types.SEARCH_INITIAL_STATE:
      return {
        ...state,
        [action.payload.componentId]: {
          targetId: action.payload.targetId,
          fieldsConfig: action.payload.fieldsConfig,
          globalSearchField: action.payload.globalSearchField,
          values: action.payload.values,
          formHasChanged: action.payload.formHasChanged,
          getDataTrigger: false,
          filterVisible: false,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case types.SEARCH_RESET_COMPONENT:
      return {
        ...state,
        [action.payload.componentId]: {}
      };
    case types.SEARCH_SET_VALUES:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          values: action.payload.values
        }
      };
    case types.SEARCH_SET_INITIAL_DATA:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          getDataTrigger: action.payload.value
        }
      };
    case types.SEARCH_SET_FIELDS_CONFIG:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          fieldsConfig: action.payload.newFieldsConfig,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case types.SEARCH_RESET_FORM:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          values: action.payload.values,
          fieldsConfig: action.payload.fieldsConfig,
          formHasChanged: false,
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case types.SEARCH_SET_FORM_HAS_CHANGED:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          formHasChanged: action.payload.formHasChanged
        }
      };
    case types.SEARCH_CHANGE_TAB:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          selectedTab: action.payload.selectedTab
        }
      };
    case types.SEARCH_CLEAN_RESET_COMPONENT:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          resetFormTrigger: action.payload.resetFormTrigger
        }
      };
    case types.SEARCH_FILTER_VISIBLE:
      return {
        ...state,
        [action.payload.componentId]: {
          ...state[action.payload.componentId],
          filterVisible: action.payload.filterVisible
        }
      };
    default:
      return state;
  }
}
