import * as types from './dashboardTypes';

export const setInitialDashboardState = ({ componentId }) => dispatch => {
  dispatch({
    type: types.SET_DASHBOARD_INITIAL_STATE,
    payload: { componentId }
  });
};

export const setInitialState = ({
  componentId,
  layoutType,
  drawerVisible = false
}) => dispatch => {
  dispatch({
    type: types.DASHBOARD_INITIAL_STATE,
    payload: {
      componentId,
      layoutType,
      drawerVisible
    }
  });
};

export const resetDashboardSelectedTab = ({ dashboardId }) => dispatch => {
  dispatch({
    type: types.DASHBOARD_RESET_SELECTED_TAB,
    payload: { dashboardId }
  });
};

export const setSelectedTab = ({
  dashboardId,
  componentId,
  tabIndex
}) => dispatch => {
  dispatch({
    type: types.DASHBOARD_SET_SELECTED_TAB,
    payload: { dashboardId, tabIndex, componentId }
  });
  return true;
};

export const setDrawerVisibility = ({ dashboardId, visible }) => dispatch => {
  dispatch({
    type: types.DASHBOARD_SET_DRAWER,
    payload: { dashboardId, visible }
  });
};
