const {
  REACT_APP_BASE_URL,
  REACT_APP_FRONT_URL,
  REACT_APP_CLIENT_PORT,
  REACT_APP_AUTH_SERVER_USERNAME,
  REACT_APP_AUTH_SERVER_PASSWORD,
  REACT_APP_ENVIROMENT,
} = process.env;

const ROOT_URL = `${REACT_APP_BASE_URL}`;
const STATIC_URL = `${REACT_APP_BASE_URL}:${REACT_APP_CLIENT_PORT}/static`;

const config = {
  APP: {
    NAME: 'Demo2',
    ENVIROMENT: REACT_APP_ENVIROMENT,
    FRONT_URL: `${REACT_APP_FRONT_URL}`,
  },
  API: {
    ROOT_URL,
    STATIC_URL, //DEPRECATED --> eliminar
  },
  AUTOCOMPLETE: {
    FILTER_SIZE: 9,
    OPTIONS_SIZE: 9,
    INCREMENT_SIZE: 2,
  },
  QUERY: {
    AND: ',AND,',
    OR: ',OR,',
  },
  LOCAL_STORAGE: {
    SESSION: 'gtis_session',
    TOKEN: 'gtis_token',
    BREADCRUMBS: 'gtis_breadcrumbs',
    CONFIG: 'gtis_config',
    FOREIGN_FK: 'nav_fk',
  },
  CLIENT: {
    USERNAME: REACT_APP_AUTH_SERVER_USERNAME,
    PASSWORD: REACT_APP_AUTH_SERVER_PASSWORD,
  },
  SECURITY: 2, // 0 -> NO | 1 -> DEV | 2 -> TOKEN
  BREAKPOINTS: {
    XS: 480,
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
    XXL: 1600,
  },
  MENU: {
    LOGO_SHOW: true,
    THEME: 'dark', // options: 'dark' || 'light'
  },
  LOGIN: {
    LOGO_SHOW: true,
    FORGOT_PASSWORD: true,
    REMEMBER_ME: true,
  },
  USER: {
    USERID: 'admin',
  },
  PERMISSIONS: {
    LOAD_PERMISSIONS: true,
  },
  HEADER: {
    AVATAR_SHOW: true,
  },
  WELCOME: {
    SHOW: true,
  },
  FEEDBACK: {
    REVERT: {
      SHOW: false,
      DURATION: 3, // default is 3
    },
    MESSAGE: {
      SHOW: true,
      TOP_POSITION: 10,
      TIMEOUT_SUCCESS: 0.8,
      TIMEOUT_ERROR: 2,
      TIMEOUT_INFO: 1,
      DURATION: 1, // default is 3
    },
    CONFIRM: {
      RECORD_CHANGED: true,
      CREATE_RECORD: false,
      DUPLICATE_RECORD: true,
      DELETE_RECORD: true,
    },
  },
  COMPONENT: {
    FORM: {
      FIELD_SIZE: 'small',
      FIELD_MARGIN: 12,
      AVATAR_SIZE: 128,
      DROPDOWN_SIZE: 1,
      ADVANCED_SEARCH_SIZE: 1,
      RESET_ON_UNMOUNT: true,
    },
    TABLE: {
      SIZE: 'small',
      BORDERED: true,
      STRIPED: true,
      SHOW_QUICK_JUMPER: true,
      SHOW_SIZE_CHANGER: true,
      SHOW_TOTAL: true,
      AUTOCOLLAPSE: true,
      COLLAPSE_PERSISTANCE: false,
    },
    BREADCRUM: {
      SHOW: true,
    },
    EDIT: {
      DISABLE_QUERY_PARAMS_FIELD: true,
    },
  },
  EXPORT: {
    COLUMN_DELIMITER: ';',
    LINE_DELIMITER: '\n',
  },
  UPLOAD: {
    IMAGE_SIZE: 2,
    DOCUMENT_SIZE: 2,
  },
  LOCALE: {
    DEFAULT: 'ca-ES',
    FIXED: true,
  },
};

export default config;
