import React, { useState } from 'react';
import { Button, Modal, Input, Form, Row } from 'antd';
import { isEmpty } from 'lodash';
import ModalRubricDefault from '../modalCreateEditRubricDefault';


export default function createRubricDefault(parentProps) {
  const [createVisible, setCreateVisible] = useState(false);

  const createModal = (
    <Modal 
      visible={createVisible}
      onCancel={()=> setCreateVisible(false)}
      closable
      centered
      footer={null}
      width={800}
    >
      <ModalRubricDefault 
        setCreateVisible={setCreateVisible}
      />
    </Modal>
  );

  return (
    <div>
      <Button
        icon="file-add"
        type="primary"
        style={{ float: 'right', marginTop: '-40px' }}
        onClick={() => setCreateVisible(true)}
      >
        Crear rúbrica
      </Button>
      {createModal}
    </div>
  )

}
