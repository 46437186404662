import React, { useState } from 'react';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { Button, Modal } from 'antd';
import advancedSearchComponents from '../extensions/advancedSearch/advancedSearchComponents';

export default function advancedSearch({ parentProps, field }) {
  const [showModal, setShowModal] = useState(false);
  const { props, importValues } = parentProps;
  const { dashboardId, params, values, fields, targetId, history } = props;
  const { componentId } = params;

  const handleShowModal = visible => {
    setShowModal(visible);
    return true;
  };
  const advancedSearchConfig = {
    field,
    dashboardId,
    componentId,
    values,
    fields,
    handleShowModal,
    mainTableId: targetId,
    importValues
  };
  //EOLO CUSTOM
  const { filterFieldDescription, filterField } = field.autocompleteConfig;
  if (filterField) {
    advancedSearchConfig.filter = {
      [filterFieldDescription]: values[filterField]
    };
  }
  //END CUSTOM

  const searchModal = (
    <Modal
      visible={showModal}
      className="searchModal"
      onCancel={() => handleShowModal(false)}
      style={{ paddingTop: '43px' }}
      closable
      destroyOnClose
      centered
      footer={null}
      width={'75%'}
    >
      <DashboardInitializer
        // customDashboard={field.advancedSearchComponentId}
        componentId={field.advancedSearchComponentId}
        components={advancedSearchComponents[field.advancedSearch]}
        isAdvancedSearch={advancedSearchConfig}
        history={history}
        isModalDashboard={true}
      />
    </Modal>
  );

  return (
    <>
      <Button
        className="advancedSearchButton"
        type="primary"
        icon="search"
        size="small"
        onClick={() => handleShowModal(true)}
      />
      {searchModal}
    </>
  );
}
