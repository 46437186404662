import config from '../config';
import configDev from '../configDev';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(
      `${config.LOCAL_STORAGE.SESSION}_${config.APP.NAME}`
    );
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem(
    `${config.LOCAL_STORAGE.SESSION}_${config.APP.NAME}`,
    serializedState
  );
};

export const loadConfig = () => {
  if (configDev.SAVE_CONFIG) {
    try {
      const serializedConfig = localStorage.getItem(
        `${config.LOCAL_STORAGE.CONFIG}_${config.APP.NAME}`
      );
      return serializedConfig ? JSON.parse(serializedConfig) : undefined;
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
};

export const saveConfig = userConfig => {
  try {
    const serializedConfig = JSON.stringify(userConfig);
    localStorage.setItem(
      `${config.LOCAL_STORAGE.CONFIG}_${config.APP.NAME}`,
      serializedConfig
    );
  } catch (err) {}
};
