import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import config from '../config';
import SideMenu from './SideMenu';
import DashboardRouter from '../dashboardsConfig/DashboardRouter';
import SmartBreadCrumb from '../breadcrumb/SmartBreadcrumb';
import { setMenuState } from './appActions';
import HeaderContent from '../header/HeaderContent';
import {
  navigate,
  setInitialQueryParams,
  getQueryParams,
  setBreadcrumbs,
  resetBreadcrumbs
} from './queryActions';
import './MainWrapper.css';

const { Header, Sider, Content } = Layout;

class MainWrapper extends Component {
  constructor(props) {
    super(props);
    const { router, query, setInitialQueryParams, getQueryParams } = this.props;
    const { location } = router;
    if (!query.queryParamsLoaded) {
      getQueryParams();
      if (!isEmpty(location.search))
        setInitialQueryParams({ initialParams: location.search });
    }
  }

  isInHomePage = () => {
    const { pathname } = this.props;
    // if (pathname === '/Home') {
    //   setMenuState({
    //     menuState: 0
    //   });
    // }
    return pathname === '/Home';
  };

  mustRenderLayout = () => {
    const { pathname } = this.props;
    return pathname !== '/Home' && pathname !== '/';
  };

  switchMenuState = (pathname, menuState) => {
    if (pathname === '/Home') {
      return 0;
    } else {
      switch (menuState) {
        case 0:
          return 1;
        case 1:
          return window.innerWidth < config.BREAKPOINTS.MD ? 0 : 2;
        case 2:
          return 1;
        default:
          return 1;
      }
    }
  };

  handleChangeMenuState = (menuState, broken) => {
    const { setMenuState, pathname } = this.props;
    // Change menu size action (when menu button is pressed)

    if (!menuState && menuState !== 0) return;

    if (typeof broken === 'undefined') {
      setMenuState({
        menuState: this.switchMenuState(pathname, menuState)
      });
      // Swith between collapsed menu and full-collapsed menu
    } else if (typeof broken !== 'undefined') {
      if (menuState === 1 || (menuState === 2 && broken)) {
        setMenuState({
          menuState: 0
        });
      }
    }
  };

  siderClassName = menuState => {
    switch (menuState) {
      case 0:
        return '';
      case 1:
        return 'mainWrapper__sider--collapsed';
      case 2:
        return 'mainWrapper__sider';
      default:
        return '';
    }
  };

  render() {
    const {
      pathname,
      menu,
      menuState,
      setBreadcrumbs,
      router,
      breadcrumbs,
      resetBreadcrumbs
    } = this.props;
    const mainStyle = cn(
      'main',
      menuState !== 2 || menuState === '/Home'
        ? {
            collapsed: true
          }
        : { normal: true }
    );

    const logoWrapper = (
      <div
        className={menuState === 0 ? 'logoWrapperResponsive' : 'logoWrapper'}
      >
        <div
          className={
            menuState === 1 || menuState === 0
              ? 'logoWrapper__logoCollapsed'
              : 'logoWrapper__logoExtended'
          }
        />
      </div>
    );

    const showLogo =
      config.MENU.LOGO_SHOW && config.WELCOME.SHOW ? (
        <Link to={'/Home'}>{logoWrapper}</Link>
      ) : (
        logoWrapper
      );

    const header = this.mustRenderLayout() && (
      <Header>
        <HeaderContent menuState={menuState} />
      </Header>
    );
    const breadcrumb = config.COMPONENT.BREADCRUM.SHOW &&
      this.mustRenderLayout() && (
        <SmartBreadCrumb menu={menu} pathname={pathname} />
      );

    const contentClassName = () =>
      this.isInHomePage() ? 'main__content--home' : 'main__content';

    if (pathname !== '/Home') {
      if (
        !isEmpty(breadcrumbs) &&
        breadcrumbs[breadcrumbs.length - 1].absolutePath !== pathname
      ) {
        const index = breadcrumbs.findIndex(bc => bc.absolutePath === pathname);
        index === -1
          ? resetBreadcrumbs({ path: pathname, search: router.location.search })
          : setBreadcrumbs({ breadcrumbs, index });
      }
    }

    return (
      <Layout className="mainWrapper">
        {!this.isInHomePage() && (
          <Sider
            width={220}
            collapsedWidth={menuState === 0 ? 0 : 60}
            breakpoint={'md'}
            onBreakpoint={broken =>
              this.handleChangeMenuState(menuState, broken)
            }
            onCollapse={(collapsed, type) =>
              this.handleChangeMenuState(menuState)
            }
            collapsible
            collapsed={menuState === 1 || menuState === 0}
            className={this.siderClassName(menuState)}
            reverseArrow={
              menuState === 1 && window.innerWidth < config.BREAKPOINTS.MD
            }
          >
            {showLogo}
            <SideMenu
              menu={menu}
              pathname={pathname}
              navigate={this.props.navigate}
            />
          </Sider>
        )}

        <Layout className={mainStyle}>
          {header}
          <Content className={contentClassName()}>
            {breadcrumb}
            <DashboardRouter {...this.props} />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  menuState: state.app.menuState,
  pathname: state.router.location.pathname,
  query: state.query,
  router: state.router,
  breadcrumbs: state.query.breadcrumbs
});

MainWrapper = injectIntl(MainWrapper);
export default connect(
  mapStateToProps,
  {
    setMenuState,
    navigate,
    setInitialQueryParams,
    getQueryParams,
    setBreadcrumbs,
    resetBreadcrumbs
  }
)(MainWrapper);
