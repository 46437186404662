const mockData = {
  MENU: [
    {
      iconOfMenu: 'book',
      nameOfMenu: 'Books',
      route: '/Bookview',
      id: 8018,
      welcome: true,
      subMenu: []
    },
    {
      iconOfMenu: 'switcher',
      nameOfMenu: 'Categories',
      route: '/Categoryview',
      id: 8017,
      welcome: true,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'Test Content',
      route: '/Test',
      id: 8004,
      welcome: false,
      subMenu: [
        {
          iconOfMenu: 'profile',
          nameOfMenu: 'Test 1',
          route: '/Test1view',
          id: 8008,
          welcome: true,
          subMenu: []
        },
        {
          iconOfMenu: 'folder',
          nameOfMenu: 'Test',
          route: '/Test2view',
          id: 8009,
          welcome: false,
          subMenu: [
            {
              iconOfMenu: 'folder',
              nameOfMenu: 'Test 2',
              route: '/Test2view',
              id: 8009,
              welcome: true,
              subMenu: []
            }
          ]
        }
      ]
    },
    {
      iconOfMenu: 'bank',
      nameOfMenu: 'Libraries',
      route: '/Libraryview',
      id: 8020,
      welcome: true,
      subMenu: []
    },
    {
      iconOfMenu: 'user',
      nameOfMenu: 'Users',
      route: '/Usersview',
      id: 8013,
      welcome: true,
      subMenu: []
    }
  ],

  MENU1: [
    {
      iconOfMenu: 'book',
      nameOfMenu: 'Books',
      route: '/Bookview',
      id: 8018,
      welcome: true,
      subMenu: []
    },

    {
      iconOfMenu: 'switcher',
      nameOfMenu: 'Categories',
      route: '/Categoryview',
      id: 8017,
      welcome: true,
      subMenu: [
        {
          iconOfMenu: 'profile',
          nameOfMenu: 'Breadcrumb 1',
          route: '/Breadcrumb1view',
          id: 16,
          welcome: true,
          subMenu: [
            {
              iconOfMenu: 'profile',
              nameOfMenu: 'subBreadcrumb 1',
              route: '/subBreadcrumb1view',
              id: 26,
              welcome: true,
              subMenu: []
            }
          ]
        },
        {
          iconOfMenu: 'folder',
          nameOfMenu: 'Breadcrumb 2',
          route: '/Breadcrumb2view',
          id: 18,
          welcome: true,
          subMenu: []
        }
      ]
    },
    {
      iconOfMenu: 'profile',
      nameOfMenu: 'Test 1',
      route: '/Test1view',
      id: 8008,
      welcome: true,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'Test 2',
      route: '/Test2view',
      id: 8009,
      welcome: true,
      subMenu: []
    },
    {
      iconOfMenu: 'bank',
      nameOfMenu: 'Libraries',
      route: '/Libraryview',
      id: 8020,
      welcome: true,
      subMenu: []
    }
  ],
  LOGIN_VALUES: { user: 'admin', password: 'admin' },
  PERMISSIONS: [],
  USER: {}
  /*
  // Menu array from /access request
  MENU1: [
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.availability',
      route: 'Home/Availabilityview',
      id: 5983,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.person',
      route: 'Home/Personview',
      id: 5982,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.author',
      route: 'Home/Authorview',
      id: 5980,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.authorship',
      route: 'Home/Authorshipview',
      id: 5977,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.book',
      route: 'Home/Bookview',
      id: 5988,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.register',
      route: 'Home/Registerview',
      id: 5984,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.publisher',
      route: 'Home/Publisherview',
      id: 5981,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.borrow',
      route: 'Home/Borrowview',
      id: 5986,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.contract',
      route: 'Home/Contractview',
      id: 5976,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.category',
      route: 'Home/Categoryview',
      id: 5985,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.library',
      route: 'Home/Libraryview',
      id: 5987,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.test2',
      route: 'Home/Test2view',
      id: 5978,
      subMenu: []
    },
    {
      iconOfMenu: 'folder',
      nameOfMenu: 'demo2.test1',
      route: 'Home/Test1view',
      id: 5979,
      subMenu: [],
    }
  ]
  */
};

export default mockData;
